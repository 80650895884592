// src/Admin/section/AdminLayout.js
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext } from "../../theme";
import Header from "./Header";
import Sidebar from "./Sidebar";
import AdminRoutes from "../../Routes/adminRoutes";

const AdminLayout = ({ mode, setMode, sideBar, setSideBar }) => {
  return (
    <ColorModeContext.Provider value={setMode}>
      <ThemeProvider theme={mode}>
        <CssBaseline />
        <div className="app">
          <Sidebar sideBar={sideBar} />
          <main className="content">
            <Header setSideBar={setSideBar} />
            <AdminRoutes />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AdminLayout;
