// src/Accountant/section/AccountantLayout.js
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext } from "../../theme";
import FoHeader from "./Header"; // Fo-specific header
import FoSidebar from "./Sidebar"; // Fo-specific sidebar
import FoRoutes from "../../Routes/foRoutes";
const FoLayout = ({ mode, setMode }) => {
  return (
    <ColorModeContext.Provider value={setMode}>
      <ThemeProvider theme={mode}>
        <CssBaseline />
        <div className="app">
          <FoSidebar />
          <main className="content">
            <FoHeader />
            <FoRoutes />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default FoLayout;
