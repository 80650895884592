import React from 'react';

function Connect() {
  return (
    <div className="connect" id="connect">
      <div className="connect-header">
        <h1>Get in Touch With Us!</h1>
        <p>Send us your email to stay updated on the latest news and offers!</p>
      </div>

      {/* Contact Form and Company Details Container */}
      <div className="contact-company-container">
        
        {/* Contact Form */}
        <div className="contact-form">
          <div className="form-container">
            <h2>Contact Form</h2>
            <form>
              <div className="input-group">
                <input 
                  type="text" 
                  placeholder="Your Name" 
                  required 
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <input 
                  type="email" 
                  placeholder="Your Email" 
                  required 
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <textarea 
                  placeholder="Your Message" 
                  required 
                  className="input-field" 
                  rows="4"
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">
                Send Message
              </button>
            </form>
          </div>
        </div>
        
        {/* Company Details */}
        <div className="company-details">
          <h3>Our Company</h3>
          <p><strong>Address:</strong>570 M.g. Road, Unit No G 28, City Center, Indore Uchchanyayalay, Indore, Indore, Madhya Pradesh, India, 452001.

</p>
          <p><strong>Phone:</strong> +91 88713 36877

</p>
          <p><strong>Email:</strong> connect@digiFlyer.in

</p>
          <div className="social">
            <a href="#" className="social-icon">
              <i className='bx bxl-facebook'></i>
            </a>
            <a href="#" className="social-icon">
              <i className='bx bxl-twitter'></i>
            </a>
            <a href="#" className="social-icon">
              <i className='bx bxl-instagram'></i>
            </a>
            <a href="#" className="social-icon">
              <i className='bx bxl-linkedin'></i>
            </a>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Connect;
