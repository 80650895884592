import React, { useState } from 'react';
import Navbar from './Navbar';
import Home from './Home';
import Services from './Services';
import Team from './Team';
import Clients from './Clients';
import Connect from './Connect';
import Footer from './Footer';
import './style.css';
import Pricing from './Pricing';

function LandingPage() {


  
  return (
    <div className="LandingPage">
      <Navbar />
      <Home />
      <Services />
      <Pricing/>
      {/* <Team /> 
      <Clients /> */}
      <Connect />
      <Footer />
    </div>
  );
}



export default LandingPage;
