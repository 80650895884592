import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Schedule = () => {
  const [openContentModal, setOpenContentModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [contentType, setContentType] = useState("Image");
  const [contentTitle, setContentTitle] = useState("");
  const [selectedScreen, setSelectedScreen] = useState("");
  const [selectedContent, setSelectedContent] = useState("");
  const [events, setEvents] = useState([
    {
      id: 1,
      screen: "Screen 1",
      dateTime: new Date("2025-01-20T10:00:00"),
      contentType: "Image",
      title: "Example Image 1",
      content: "https://placehold.co/150",
    },
    {
      id: 2,
      screen: "Screen 2",
      dateTime: new Date("2025-01-20T12:00:00"),
      contentType: "Video",
      title: "Sample Video 1",
      content: "https://www.w3schools.com/html/movie.mp4",
    },
  ]);
  const [editEventId, setEditEventId] = useState(null);

  const screens = ["Screen 1", "Screen 2", "Screen 3"];
  const contentOptions = [
    { type: "Image", url: "https://placehold.co/150" },
    { type: "Video", url: "https://www.w3schools.com/html/movie.mp4" },
  ];

  const handleOpenContentModal = () => {
    setOpenContentModal(true);
  };

  const handleCloseContentModal = () => {
    setOpenContentModal(false);
  };

  const handleSelectContent = (content) => {
    setSelectedContent(content.url);
    setContentType(content.type);
    handleCloseContentModal();
  };

  const handleAddEvent = () => {
    if (!contentTitle || !selectedDate || !selectedTime || !selectedScreen || !selectedContent) {
      alert("Please fill out all fields before adding an event.");
      return;
    }

    const eventDateTime = new Date(`${selectedDate}T${selectedTime}`);
    const newEvent = {
      id: editEventId || Date.now(), // Use existing ID for edit or generate new one
      screen: selectedScreen,
      dateTime: eventDateTime,
      contentType,
      title: contentTitle,
      content: selectedContent,
    };

    if (editEventId) {
      // Update existing event
      setEvents((prevEvents) =>
        prevEvents.map((event) => (event.id === editEventId ? newEvent : event))
      );
    } else {
      // Add new event
      setEvents((prevEvents) => [...prevEvents, newEvent]);
    }

    // Reset fields
    setContentTitle("");
    setSelectedDate("");
    setSelectedTime("");
    setSelectedScreen("");
    setSelectedContent("");
    setContentType("Image");
  };

  const handleEditEvent = (event) => {
    setEditEventId(event.id);
    setContentTitle(event.title);
    setContentType(event.contentType);
    setSelectedDate(event.dateTime.toISOString().split("T")[0]);
    setSelectedTime(event.dateTime.toTimeString().split(" ")[0]);
    setSelectedScreen(event.screen);
    setSelectedContent(event.content);
  };

  const handleDeleteEvent = (eventId) => {
    setEvents((prevEvents) => prevEvents.filter((event) => event.id !== eventId));
  };

  const renderContent = (contentType, content) => {
    switch (contentType) {
      case "Image":
        return <img src={content} alt="Scheduled Content" style={{ width: "100%" }} />;
      case "Video":
        return (
          <video width="100%" controls>
            <source src={content} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      default:
        return <Typography variant="body2">No content available</Typography>;
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Digital Signage Schedule
      </Typography>

      {/* Input Fields on Screen */}
      <Box sx={{ marginBottom: 4 }}>
        <TextField
          fullWidth
          label="Content Title"
          variant="outlined"
          value={contentTitle}
          onChange={(e) => setContentTitle(e.target.value)}
          sx={{ marginBottom: 2 }}
        />

        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel>Select Screen</InputLabel>
          <Select
            value={selectedScreen}
            onChange={(e) => setSelectedScreen(e.target.value)}
            label="Select Screen"
          >
            {screens.map((screen) => (
              <MenuItem key={screen} value={screen}>
                {screen}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: 2 }}
        />

        <TextField
          fullWidth
          label="Select Time"
          type="time"
          value={selectedTime}
          onChange={(e) => setSelectedTime(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: 2 }}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenContentModal}
          sx={{ marginBottom: 2 }}
        >
          Select Content
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddEvent}
          sx={{ marginLeft: 2 }}
        >
          {editEventId ? "Update Event" : "Add Event"}
        </Button>
      </Box>

      {/* Event List */}
      <Grid container spacing={2}>
        {events.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" color="textSecondary">
              No events scheduled yet.
            </Typography>
          </Grid>
        ) : (
          events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Card
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <CardContent>
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {event.screen}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(event.dateTime).toLocaleString()}
                  </Typography>
                  <Typography variant="body1" sx={{ marginTop: 1 }}>
                    {event.title}
                  </Typography>
                  {renderContent(event.contentType, event.content)}
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => handleEditEvent(event)}>
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteEvent(event.id)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>

      {/* Modal for Content Selection */}
      <Dialog open={openContentModal} onClose={handleCloseContentModal}>
        <DialogTitle>Select Content</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {contentOptions.map((content, index) => (
              <Grid item xs={6} key={index}>
                <Box
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: 1,
                    padding: 2,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                  onClick={() => handleSelectContent(content)}
                >
                  {content.type === "Image" ? (
                    <img src={content.url} alt="Content" style={{ width: "100%" }} />
                  ) : (
                    <video src={content.url} style={{ width: "100%" }} controls />
                  )}
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {content.type}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContentModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Schedule;