import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useWebSocketContext } from '../../../WebSocketContext';
import { getRequest } from './components/api';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 350,
  height: 280,
  borderRadius: 12,
  boxShadow: theme.shadows[3],
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  background: theme.palette.primary.main,
  '&:hover': {
    boxShadow: theme.shadows[15],
    transform: 'translateY(-10px)',
  },
}));

const CardMediaContainer = styled(CardMedia)(({ theme }) => ({
  height: 150,
  backgroundSize: 'contain',
  backgroundColor: theme.palette.grey[300],
  borderRadius: '12px 12px 0 0',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: 12,
  boxShadow: theme.shadows[3],
  background: 'linear-gradient(135deg, #F5F5F5,rgb(131, 134, 222))',
  '&:hover': {
    boxShadow: theme.shadows[8],
  },
}));

const Dashboard = () => {
  const userId = sessionStorage.getItem('userid');
  const { isWebSocketConnected, sendMessage, message } = useWebSocketContext();

  // State for storing fetched stats data
  const [stats, setStats] = useState({
    screenCount: 0,
    activeScreenCount: 0,
    playlistCount: 0,
    cityFileCount: 0,
  });

  // Fetch screen settings from the API
  const fetchScreenSettings = async () => {
    try {
      const res = await getRequest(`/getStats/${userId}`);
      if (res) {
        setStats({
          screenCount: res.screenCount,
          activeScreenCount: res.activeScreenCount,
          playlistCount: res.playlistCount,
          cityFileCount: res.cityFileCount,
        });
      }
    } catch (error) {
      console.error('Error fetching screen settings or pairing code', error);
    }
  };

  useEffect(() => {
    if (isWebSocketConnected && userId) {
      sendMessage({ command: 'register', userId: userId });
      console.log('sent from websocket');
    }

    // Fetch data when component mounts
    fetchScreenSettings();
  }, [isWebSocketConnected, userId]);

  // Update dashboardData dynamically using the fetched stats
  const dashboardData = [
    {
      title: 'Total Screens',
      value: stats.screenCount,
      image: 'https://media.istockphoto.com/id/1159375409/photo/financial-stock-exchange-market-display-screen-board-on-the-street.jpg?s=612x612&w=0&k=20&c=HUUdqLDsQNk7B4AUxkzp6YZFvn5B1SvWkLmd5_2tUBU=',
    },
    {
      title: 'Active Screens',
      value: stats.activeScreenCount,
      image: 'https://st2.depositphotos.com/42900380/44738/i/450/depositphotos_447381966-stock-photo-abstract-wide-technology-background-hexagons.jpg',
    },
    {
      title: 'Total Playlists',
      value: stats.playlistCount,
      image: 'https://cdn-d.smehost.net/sites/33342fef016449209469a86ecd55cb00/wp-content/uploads/2016/03/playlist2.png',
    },
    {
      title: 'Files Count',
      value: stats.cityFileCount,
      image: 'https://static.thenounproject.com/png/117192-200.png',
    },
  ];

  return (
    <Box
      sx={{
        padding: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Typography variant="h3" align="center" gutterBottom color="primary">
        Digital Signage Dashboard
      </Typography>

      <Grid container spacing={3}>
        {dashboardData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StyledCard>
              <CardMediaContainer image={item.image} title={item.title} />
              <CardContent>
                <Typography variant="h5" align="center" color="white" fontWeight="bold">
                  {item.title}
                </Typography>
                <Typography variant="h4" align="center" color="white" fontWeight="bold">
                  {item.value}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}

        {/* You can keep your other Paper components as is */}
      </Grid>
    </Box>
  );
};

export default Dashboard;




// import React, { useEffect } from 'react';
// import { Box, Typography, Card, CardContent, CardMedia, Grid, Paper } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { useWebSocketContext } from '../../../WebSocketContext';

// const StyledCard = styled(Card)(({ theme }) => ({
//   maxWidth: 350,
//   height: 280,
//   borderRadius: 12,
//   boxShadow: theme.shadows[3],
//   transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//   background: theme.palette.primary.main,
//   '&:hover': {
//     boxShadow: theme.shadows[15],
//     transform: 'translateY(-10px)',
//   },
// }));

// const CardMediaContainer = styled(CardMedia)(({ theme }) => ({
//   height: 150,
//   backgroundSize: 'contain',
//   backgroundColor: theme.palette.grey[300],
//   borderRadius: '12px 12px 0 0', 
// }));

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   borderRadius: 12,
//   boxShadow: theme.shadows[3],
//   background: 'linear-gradient(135deg, #F5F5F5,rgb(131, 134, 222))',
//   '&:hover': {
//     boxShadow: theme.shadows[8],
//   },
// }));

// const dashboardData = [
//   {
//     title: 'Total Screens',
//     value: '15',
//     image: 'https://placehold.co/500x150', 
//   },
//   {
//     title: 'Total Playlists',
//     value: '5',
//     image: 'https://placehold.co/500x150', 
//   },
//   {
//     title: 'Total Cities',
//     value: '8',
//     image: 'https://placehold.co/500x150', 
//   },
//   {
//     title: 'Active Screens',
//     value: '12',
//     image: 'https://placehold.co/500x150', 
//   },
// ];

// const Dashboard = () => {
//   const userId = sessionStorage.getItem('userid');
//   const { isWebSocketConnected, sendMessage, message } = useWebSocketContext();

//   useEffect(() => {
//     if (isWebSocketConnected && userId) {
//       sendMessage({ command: 'register', userId: userId });
//       console.log('sent from websocket');
//     }
//   }, [isWebSocketConnected]);

//   return (
//     <Box
//       sx={{
//         padding: 2,
//         display: 'flex',
//         flexDirection: 'column',
//         gap: 2,
//         height: '100vh',
//         overflow: 'auto',
//       }}
//     >
//       <Typography variant="h3" align="center" gutterBottom color="primary">
//         Digital Signage Dashboard
//       </Typography>

//       <Grid container spacing={3}>
//         {dashboardData.map((item, index) => (
//           <Grid item xs={12} sm={6} md={3} key={index}>
//             <StyledCard>
//               <CardMediaContainer
//                 image={item.image}
//                 title={item.title}
//               />
//               <CardContent>
//                 <Typography variant="h5" align="center" color="white" fontWeight="bold">
//                   {item.title}
//                 </Typography>
//                 <Typography variant="h4" align="center" color="white" fontWeight="bold">
//                   {item.value}
//                 </Typography>
//               </CardContent>
//             </StyledCard>
//           </Grid>
//         ))}

//         <Grid item xs={12} md={6}>
//           <StyledPaper>
//             <Typography variant="h5" gutterBottom color="primary">
//               City List
//             </Typography>
//             <Box>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 City A - Active
//               </Typography>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 City B - Active
//               </Typography>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 City C - Inactive
//               </Typography>
//             </Box>
//           </StyledPaper>
//         </Grid>

//         <Grid item xs={12} md={6}>
//           <StyledPaper>
//             <Typography variant="h5" gutterBottom color="primary">
//               Active Screens and Locations
//             </Typography>
//             <Box>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 Screen 1 - Location A
//               </Typography>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 Screen 2 - Location B
//               </Typography>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 Screen 3 - Location C
//               </Typography>
//             </Box>
//           </StyledPaper>
//         </Grid>

//         <Grid item xs={12} md={6}>
//           <StyledPaper>
//             <Typography variant="h5" gutterBottom color="primary">
//               Upcoming Campaigns / Events
//             </Typography>
//             <Box>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 Campaign 1 - Start Date: 2023-05-01
//               </Typography>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 Campaign 2 - Start Date: 2023-06-01
//               </Typography>
//               <Typography variant="body1" color="text.secondary" align="center">
//                 Event 1 - Date: 2023-04-15
//               </Typography>
//             </Box>
//           </StyledPaper>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default Dashboard;
