// 6x6
import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

// Function to calculate the widget span (rowSpan and colSpan)
const calculateWidgetSpan = (cells) => {
  const rows = cells.map((cell) => Math.floor(cell / 6)); // 6 columns
  const cols = cells.map((cell) => cell % 6); // 6 columns

  const rowSpan = Math.max(...rows) - Math.min(...rows) + 1;
  const colSpan = Math.max(...cols) - Math.min(...cols) + 1;

  return {
    rowSpan,
    colSpan,
    startRow: Math.min(...rows),
    startCol: Math.min(...cols),
  };
};

// Function to render color based on widget type
const renderColor = (widgetType) => {
  switch (widgetType) {
    case "image":
      return "#f9a19a";
    case "video":
      return "#90caf9";
    case "banner":
      return "#ffcb7f";
    case "text":
      return "#a5d7a7";
    default:
      return "#f8f8f8";
  }
};

// SnapshotGrid component
const SnapshotGrid = ({ snapshotdata }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)", // 6 columns
          gridTemplateRows: "repeat(6, 1fr)", // 6 rows
          width: "80%",
          height: "300px", // Set a fixed height for the grid
          margin: 0.5,
          mt: 3,
          border: "1px solid black",
        }}
      >
        {snapshotdata.map((widget) => {
          const { widgetType, cells, id } = widget;
          const { rowSpan, colSpan, startRow, startCol } = calculateWidgetSpan(cells);

          return (
            <Grid
              item
              key={id}
              sx={{
                gridRow: `${startRow + 1} / span ${rowSpan}`,
                gridColumn: `${startCol + 1} / span ${colSpan}`,
                minHeight: `${rowSpan * 50}px`, // Adjusting for better spacing
                minWidth: `${colSpan * 50}px`, // Adjusting for better spacing
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "2px solid #ddd",
                borderRadius: 1,
                backgroundColor: renderColor(widgetType),
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  opacity: 1,
                  textTransform: "capitalize",
                }}
              >
                Block Type: {widgetType}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default SnapshotGrid;
