import React, { useEffect, useState } from "react";
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Slider, Tooltip, TextField, Switch, FormControlLabel } from "@mui/material";
import { getRequest, postRequest } from "./api";
import { useWebSocketContext } from "../../../../WebSocketContext";
import { AddIcCallOutlined, Remove, RemoveCircleOutline } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { SketchPicker } from 'react-color';

const Preview = ({ screenId }) => {
  const [orientation, setOrientation] = useState("portrait");
  const [mute, setMute] = useState(true);
  const [screenSize, setScreenSize] = useState("small");
  const [screenSetting, setScreenSetting] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openBannerDialog, setOpenBannerDialog] = useState(false);
  const [pairingCode, setPairingCode] = useState(null);
  const userId = sessionStorage.getItem("userid");
  const [loading, setLoading] = useState(true);
  const { isWebSocketConnected, sendMessage } = useWebSocketContext();
  const [zoom, setZoom] = useState(1);
  const [count, setCount] = useState(1);
  const [position, setPosition] = useState("top");
  const [addBanner, setAddBanner] = useState(false); // Toggle for adding banner

  const [banner, setBanner] = useState({
    position: position,
    text: "This is new banner!",
    textColor: "#000000",
    fontSize: 24,
    backgroundColor: "#fff",
    height: 60
  });

  const IncNum = () => {
    setCount(count + 1);
  };
  const DecNum = () => {
    if (count > 1) setCount(count - 1);
    else {
      setCount(1);
    }
  };

  useEffect(() => {
    fetchScreenSettings();
  }, [screenId]);

  const fetchScreenSettings = async () => {
    setLoading(true);
    try {
      const response = await postRequest("/getAllScreenInfo", {
        screen_code: screenId,
      });
      setScreenSetting(response.data);

      const pairingResponse = await getRequest(`/single_screen/${screenId}`);
      setPairingCode(pairingResponse.pairing_code);
    } catch (error) {
      console.error("Error fetching screen settings or pairing code", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePublishContent = () => {
    setOpenDialog(true);
  };

  const confirmPublish = async () => {
    if (isWebSocketConnected && pairingCode && screenSetting) {
      let updatedGridData = [];
      try {
        const gridDataArray = JSON.parse(screenSetting.config);
        let playlist_id = 0;
        let playlist_found = 0;
        updatedGridData = gridDataArray.map((widget) => {
          if (widget.widgetType === "video") {
            return { ...widget, isMuted: mute };
          }
          if (widget.widgetType === "playlist") {
            playlist_id = widget.playlist_id;
            playlist_found = 1;
          }
          return widget;
        });

        if (playlist_found) {
          const response = await getRequest(`playlist_item/${playlist_id}`);

          updatedGridData = gridDataArray.map((widget) => {
            if (widget.widgetType === "playlist") {
              return { ...widget, url: response, delay: count };
            }
            
            return widget;
          });
        }

      } catch (error) {
        console.error("Error parsing grid data:", error);
      }

      const data = {
        orientation,
        gridData: JSON.stringify(updatedGridData),
        banner: addBanner ? banner : null // Include banner only if addBanner is true
      };
      
      const message = {
        command: "message",
        content: true,
        to: pairingCode,
        from: userId,
        message: data,
      };

      sendMessage(message);
      setOpenDialog(false);
    } else {
      console.error("WebSocket is not connected or data is missing");
    }
  };

  const cancelPublish = () => {
    setOpenDialog(false);
  };

  const handleOrientationChange = (event) => {
    setOrientation(event.target.value);
  };
  const handlePositionChange = (event) => {
    setPosition(event.target.value);
    setBanner({ ...banner, position: event.target.value });
  };

  const handleAudio = (event) => {
    setMute(event.target.value);
  };

  const handleScreenSizeChange = (event) => {
    setScreenSize(event.target.value);
  };

  const handleZoomChange = (event, newValue) => {
    setZoom(newValue);
  };

  const handleBannerChange = (prop) => (event) => {
    setBanner({ ...banner, [prop]: event.target.value });
  };

  const handleColorChange = (prop) => (color) => {
    setBanner({ ...banner, [prop]: color.hex });
  };

  const handleBannerDialogOpen = () => {
    setOpenBannerDialog(true);
  };

  const handleBannerDialogClose = () => {
    setOpenBannerDialog(false);
  };

  const handleAddBannerChange = (event) => {
    setAddBanner(event.target.checked);
  };

  const screenStyles = {
    small: { portrait: [150, 300], landscape: [300, 150] },
    medium: { portrait: [400, 650], landscape: [650, 400] },
    large: { portrait: [600, 1000], landscape: [1000, 600] },
  };

  const getOrientationStyles = () => {
    const [width, height] = screenStyles[screenSize][orientation];
    return {
      width: `${width}px`,
      height: `${height}px`,
      border: "2px solid #000",
      position: "relative",
      overflow: "hidden",
      transform: `scale(${zoom})`,
      transformOrigin: "top left",
      backgroundColor: "#f0f0f0",
      backgroundImage: "linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)",
      backgroundSize: `calc(100% / 6) calc(100% / 6)`,
      display: "grid",
      gridTemplateRows: "repeat(6, 1fr)",
      gridTemplateColumns: "repeat(6, 1fr)",
    };
  };

  const calculateWidgetSpan = (cells) => {
    if (!cells || !Array.isArray(cells) || cells.length === 0)
      return { rowSpan: 1, colSpan: 1, startRow: 0, startCol: 0 };

    const rows = cells.map((cell) => Math.floor(cell / 6));
    const cols = cells.map((cell) => cell % 6);

    return {
      rowSpan: Math.max(...rows) - Math.min(...rows) + 1,
      colSpan: Math.max(...cols) - Math.min(...cols) + 1,
      startRow: Math.min(...rows),
      startCol: Math.min(...cols),
    };
  };

  const renderWidgetContent = (widgetType, url, text) => {
    switch (widgetType) {
      case "image":
        return (
          <img
            src={url}
            alt="Image"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        );
      case "video":
        return (
          <video controls autoPlay loop muted style={{ width: "100%", height: "100%" }}>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case "banner":
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: banner.backgroundColor,
              color: banner.textColor,
              fontWeight: "bold",
              padding: 1,
              fontSize: banner.fontSize,
            }}
          >
            {banner.text}
          </Box>
        );
      case "text":
        return (
          <Typography variant="body2" sx={{ textAlign: "center", padding: 1 }}>
            {text}
          </Typography>
        );
      case "playlist":
        return (
          <Typography variant="body2" sx={{ textAlign: "center", padding: 1 }}>
            {"playlist"}
          </Typography>
        );
      default:
        return <Typography variant="body2">Unknown Widget</Typography>;
    }
  };

  const renderWidgets = () => {
    try {
      const configArray = JSON.parse(screenSetting?.config || "[]");

      return configArray.map((widget, index) => {
        const { widgetType, cells, url, text } = widget;
        const { rowSpan, colSpan, startRow, startCol } = calculateWidgetSpan(cells);

        return (
          <Box
            key={index}
            sx={{
              gridRow: `${startRow + 1} / span ${rowSpan}`,
              gridColumn: `${startCol + 1} / span ${colSpan}`,
              border: "2px solid #ddd",
              borderRadius: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              overflow: "hidden",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            {renderWidgetContent(widgetType, url, text)}
          </Box>
        );
      });
    } catch (error) {
      console.error("Error parsing config JSON:", error);
      return null;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>Screen Preview</Typography>
      <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center", flexWrap: 'wrap', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={handlePublishContent} sx={{ height: "48px", width: "150px" }}>
          Publish Screen
        </Button>
        <FormControlLabel
          control={<Switch checked={addBanner} onChange={handleAddBannerChange} />}
          label="Add Banner"
        />
        {addBanner && (
          <Button variant="contained" color="secondary" onClick={handleBannerDialogOpen} sx={{ height: "48px", width: "150px" }}>
            Customize Banner
          </Button>
        )}
      
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Banner Position</InputLabel>
          <Select value={position} label="Position" onChange={handlePositionChange} sx={{ width: 150 }}>
            <MenuItem value="top">Top</MenuItem>
            <MenuItem value="bottom">Bottom</MenuItem>
          </Select>
        </FormControl>
      
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Orientation</InputLabel>
          <Select value={orientation} label="Orientation" onChange={handleOrientationChange} sx={{ width: 150 }}>
            <MenuItem value="portrait">Portrait</MenuItem>
            <MenuItem value="landscape">Landscape</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Mute Audio</InputLabel>
          <Select value={mute} label="Mute Audio" onChange={handleAudio} sx={{ width: 150 }}>
            <MenuItem value="true">True</MenuItem>
            <MenuItem value="false">False</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 150 }}>
          <InputLabel>Screen Size</InputLabel>
          <Select value={screenSize} label="Screen Size" onChange={handleScreenSizeChange} sx={{ width: 150 }}>
            <MenuItem value="small">24"</MenuItem>
            <MenuItem value="medium">32"</MenuItem>
            <MenuItem value="large">43"</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ width: 150 }}>
          <Typography variant="caption" sx={{ marginBottom: 1 }}>Zoom</Typography>
          <Slider value={zoom} onChange={handleZoomChange} min={0.5} max={2} step={0.1} valueLabelDisplay="auto" sx={{ width: 150 }} />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="caption" sx={{ marginRight: 1 }}>Delay</Typography>
          <Tooltip title="Increase Delay">
            <Button onClick={IncNum} sx={{ minWidth: "auto", padding: 0 }}>
              <AddIcon />
            </Button>
          </Tooltip>
          <Typography variant="body1" sx={{ marginX: 2 }}>{count}</Typography>
          <Button onClick={DecNum} sx={{ minWidth: "auto", padding: 0 }}>
            <RemoveIcon />
          </Button>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 3 }}>
        <Box sx={getOrientationStyles()}>{renderWidgets()}</Box>
      </Box>

      <Dialog open={openDialog} onClose={cancelPublish}>
        <DialogTitle>Confirm Publish</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to publish this screen?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelPublish}>Cancel</Button>
          <Button onClick={confirmPublish} variant="contained" color="primary">
            Publish
          </Button>
        </DialogActions>
      </Dialog>

      {addBanner && (
        <Dialog open={openBannerDialog} onClose={handleBannerDialogClose}>
          <DialogTitle>Customize Banner</DialogTitle>
          <DialogContent>
            <TextField
              label="Banner Text"
              value={banner.text}
              onChange={handleBannerChange('text')}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Font Size"
              type="number"
              value={banner.fontSize}
              onChange={handleBannerChange('fontSize')}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Height"
              type="number"
              value={banner.height}
              onChange={handleBannerChange('height')}
              fullWidth
              margin="normal"
            />
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="subtitle1">Text Color</Typography>
              <SketchPicker
                color={banner.textColor}
                onChangeComplete={handleColorChange('textColor')}
              />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Typography variant="subtitle1">Background Color</Typography>
              <SketchPicker
                color={banner.backgroundColor}
                onChangeComplete={handleColorChange('backgroundColor')}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleBannerDialogClose}>Cancel</Button>
            <Button onClick={handleBannerDialogClose} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Preview;

// working but now we want scrolling banner also 
// import React, { useEffect, useState } from "react";
// import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Slider, Tooltip, TextField } from "@mui/material";
// import { getRequest, postRequest } from "./api";
// import { useWebSocketContext } from "../../../../WebSocketContext";
// import { AddIcCallOutlined, Remove, RemoveCircleOutline } from "@mui/icons-material";
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
// import { SketchPicker } from 'react-color';

// const Preview = ({ screenId }) => {
//   const [orientation, setOrientation] = useState("portrait");
//   const [mute, setMute] = useState(true);
//   const [screenSize, setScreenSize] = useState("small");
//   const [screenSetting, setScreenSetting] = useState({});
//   const [openDialog, setOpenDialog] = useState(false);
//   const [openBannerDialog, setOpenBannerDialog] = useState(false);
//   const [pairingCode, setPairingCode] = useState(null);
//   const userId = sessionStorage.getItem("userid");
//   const [loading, setLoading] = useState(true);
//   const { isWebSocketConnected, sendMessage } = useWebSocketContext();
//   const [zoom, setZoom] = useState(1);
//   const [count, setCount] = useState(1);
//   const [position, setposition] = useState("top");

//   const [banner, setBanner] = useState({
//     position: position,
//     text: "This is new banner!",
//     textColor: "#000000",
//     fontSize: 24,
//     backgroundColor: "#fff",
//     height: 60
//   });

//   const IncNum = () => {
//     setCount(count + 1);
//   };
//   const DecNum = () => {
//     if (count > 1) setCount(count - 1);
//     else {
//       setCount(1);
//     }
//   };

//   useEffect(() => {
//     fetchScreenSettings();
//   }, [screenId]);

//   const fetchScreenSettings = async () => {
//     setLoading(true);
//     try {
//       const response = await postRequest("/getAllScreenInfo", {
//         screen_code: screenId,
//       });
//       setScreenSetting(response.data);

//       const pairingResponse = await getRequest(`/single_screen/${screenId}`);
//       setPairingCode(pairingResponse.pairing_code);
//     } catch (error) {
//       console.error("Error fetching screen settings or pairing code", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePublishContent = () => {
//     setOpenDialog(true);
//   };

//   const confirmPublish = async () => {
//     if (isWebSocketConnected && pairingCode && screenSetting) {
//       let updatedGridData = [];
//       try {
//         const gridDataArray = JSON.parse(screenSetting.config);
//         let playlist_id = 0;
//         let playlist_found = 0;
//         updatedGridData = gridDataArray.map((widget) => {
//           if (widget.widgetType === "video") {
//             return { ...widget, isMuted: mute };
//           }
//           if (widget.widgetType === "playlist") {
//             playlist_id = widget.playlist_id;
//             playlist_found = 1;
//           }
//           return widget;
//         });

//         if (playlist_found) {
//           const response = await getRequest(`playlist_item/${playlist_id}`);

//           updatedGridData = gridDataArray.map((widget) => {
//             if (widget.widgetType === "playlist") {
//               return { ...widget, url: response, delay: count };
//             }
            
//             return widget;
//           });
//         }

//       } catch (error) {
//         console.error("Error parsing grid data:", error);
//       }

//       const data = {
//         orientation,
//         gridData: JSON.stringify(updatedGridData),
//         banner
//       };
      
//       const message = {
//         command: "message",
//         content: true,
//         to: pairingCode,
//         from: userId,
//         message: data,
//       };

//       sendMessage(message);
//       setOpenDialog(false);
//     } else {
//       console.error("WebSocket is not connected or data is missing");
//     }
//   };

//   const cancelPublish = () => {
//     setOpenDialog(false);
//   };

//   const handleOrientationChange = (event) => {
//     setOrientation(event.target.value);
//   };
//   const handlePositionChange = (event) => {
//     setposition(event.target.value);
//   };

//   const handleAudio = (event) => {
//     setMute(event.target.value);
//   };

//   const handleScreenSizeChange = (event) => {
//     setScreenSize(event.target.value);
//   };

//   const handleZoomChange = (event, newValue) => {
//     setZoom(newValue);
//   };

//   const handleBannerChange = (prop) => (event) => {
//     setBanner({ ...banner, [prop]: event.target.value });
//   };

//   const handleColorChange = (prop) => (color) => {
//     setBanner({ ...banner, [prop]: color.hex });
//   };

//   const handleBannerDialogOpen = () => {
//     setOpenBannerDialog(true);
//   };

//   const handleBannerDialogClose = () => {
//     setOpenBannerDialog(false);
//   };

//   const screenStyles = {
//     small: { portrait: [150, 300], landscape: [300, 150] },
//     medium: { portrait: [400, 650], landscape: [650, 400] },
//     large: { portrait: [600, 1000], landscape: [1000, 600] },
//   };

//   const getOrientationStyles = () => {
//     const [width, height] = screenStyles[screenSize][orientation];
//     return {
//       width: `${width}px`,
//       height: `${height}px`,
//       border: "2px solid #000",
//       position: "relative",
//       overflow: "hidden",
//       transform: `scale(${zoom})`,
//       transformOrigin: "top left",
//       backgroundColor: "#f0f0f0",
//       backgroundImage: "linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)",
//       backgroundSize: `calc(100% / 6) calc(100% / 6)`,
//       display: "grid",
//       gridTemplateRows: "repeat(6, 1fr)",
//       gridTemplateColumns: "repeat(6, 1fr)",
//     };
//   };

//   const calculateWidgetSpan = (cells) => {
//     if (!cells || !Array.isArray(cells) || cells.length === 0)
//       return { rowSpan: 1, colSpan: 1, startRow: 0, startCol: 0 };

//     const rows = cells.map((cell) => Math.floor(cell / 6));
//     const cols = cells.map((cell) => cell % 6);

//     return {
//       rowSpan: Math.max(...rows) - Math.min(...rows) + 1,
//       colSpan: Math.max(...cols) - Math.min(...cols) + 1,
//       startRow: Math.min(...rows),
//       startCol: Math.min(...cols),
//     };
//   };

//   const renderWidgetContent = (widgetType, url, text) => {
//     switch (widgetType) {
//       case "image":
//         return (
//           <img
//             src={url}
//             alt="Image"
//             style={{ width: "100%", height: "100%", objectFit: "cover" }}
//           />
//         );
//       case "video":
//         return (
//           <video controls autoPlay loop muted style={{ width: "100%", height: "100%" }}>
//             <source src={url} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         );
//       case "banner":
//         return (
//           <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: banner.backgroundColor, color: banner.textColor, fontWeight: "bold", padding: 1, fontSize: banner.fontSize }}>
//             {banner.text}
//           </Box>
//         );
//       case "text":
//         return (
//           <Typography variant="body2" sx={{ textAlign: "center", padding: 1 }}>
//             {text}
//           </Typography>
//         );
//       case "playlist":
//         return (
//           <Typography variant="body2" sx={{ textAlign: "center", padding: 1 }}>
//             {"playlist"}
//           </Typography>
//         );
//       default:
//         return <Typography variant="body2">Unknown Widget</Typography>;
//     }
//   };

//   const renderWidgets = () => {
//     try {
//       const configArray = JSON.parse(screenSetting?.config || "[]");

//       return configArray.map((widget, index) => {
//         const { widgetType, cells, url, text } = widget;
//         const { rowSpan, colSpan, startRow, startCol } = calculateWidgetSpan(cells);

//         return (
//           <Box
//             key={index}
//             sx={{
//               gridRow: `${startRow + 1} / span ${rowSpan}`,
//               gridColumn: `${startCol + 1} / span ${colSpan}`,
//               border: "2px solid #ddd",
//               borderRadius: 1,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               textAlign: "center",
//               overflow: "hidden",
//               backgroundColor: "#fff",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//             }}
//           >
//             {renderWidgetContent(widgetType, url, text)}
//           </Box>
//         );
//       });
//     } catch (error) {
//       console.error("Error parsing config JSON:", error);
//       return null;
//     }
//   };

//   if (loading) {
//     return (
//       <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ padding: 3 }}>
//       <Typography variant="h6" sx={{ marginBottom: 2 }}>Screen Preview</Typography>
//       <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center", flexWrap: 'wrap', gap: 2 }}>
//         <Button variant="contained" color="primary" onClick={handlePublishContent} sx={{ height: "48px", width: "150px" }}>
//           Publish Screen
//         </Button>
//         <Button variant="contained" color="secondary" onClick={handleBannerDialogOpen} sx={{ height: "48px", width: "150px" }}>
//           Customize Banner
//         </Button>
      
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Banner Position</InputLabel>
//           <Select value={position} label="Position" onChange={handlePositionChange} sx={{ width: 150 }}>
//             <MenuItem value="top">Top</MenuItem>
//             <MenuItem value="bottom">Bottom</MenuItem>
//           </Select>
//         </FormControl>
      
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Orientation</InputLabel>
//           <Select value={orientation} label="Orientation" onChange={handleOrientationChange} sx={{ width: 150 }}>
//             <MenuItem value="portrait">Portrait</MenuItem>
//             <MenuItem value="landscape">Landscape</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Mute Audio</InputLabel>
//           <Select value={mute} label="Mute Audio" onChange={handleAudio} sx={{ width: 150 }}>
//             <MenuItem value="true">True</MenuItem>
//             <MenuItem value="false">False</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Screen Size</InputLabel>
//           <Select value={screenSize} label="Screen Size" onChange={handleScreenSizeChange} sx={{ width: 150 }}>
//             <MenuItem value="small">24"</MenuItem>
//             <MenuItem value="medium">32"</MenuItem>
//             <MenuItem value="large">43"</MenuItem>
//           </Select>
//         </FormControl>
//         <Box sx={{ width: 150 }}>
//           <Typography variant="caption" sx={{ marginBottom: 1 }}>Zoom</Typography>
//           <Slider value={zoom} onChange={handleZoomChange} min={0.5} max={2} step={0.1} valueLabelDisplay="auto" sx={{ width: 150 }} />
//         </Box>
//         <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//           <Typography variant="caption" sx={{ marginRight: 1 }}>Delay</Typography>
//           <Tooltip title="Increase Delay">
//             <Button onClick={IncNum} sx={{ minWidth: "auto", padding: 0 }}>
//               <AddIcon />
//             </Button>
//           </Tooltip>
//           <Typography variant="body1" sx={{ marginX: 2 }}>{count}</Typography>
//           <Button onClick={DecNum} sx={{ minWidth: "auto", padding: 0 }}>
//             <RemoveIcon />
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 3 }}>
//         <Box sx={getOrientationStyles()}>{renderWidgets()}</Box>
//       </Box>

//       <Dialog open={openDialog} onClose={cancelPublish}>
//         <DialogTitle>Confirm Publish</DialogTitle>
//         <DialogContent>
//           <Typography>Are you sure you want to publish this screen?</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={cancelPublish}>Cancel</Button>
//           <Button onClick={confirmPublish} variant="contained" color="primary">
//             Publish
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={openBannerDialog} onClose={handleBannerDialogClose}>
//         <DialogTitle>Customize Banner</DialogTitle>
//         <DialogContent>
//           <TextField
//             label="Banner Text"
//             value={banner.text}
//             onChange={handleBannerChange('text')}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Font Size"
//             type="number"
//             value={banner.fontSize}
//             onChange={handleBannerChange('fontSize')}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Height"
//             type="number"
//             value={banner.height}
//             onChange={handleBannerChange('height')}
//             fullWidth
//             margin="normal"
//           />
//           <Box sx={{ marginTop: 2 }}>
//             <Typography variant="subtitle1">Text Color</Typography>
//             <SketchPicker
//               color={banner.textColor}
//               onChangeComplete={handleColorChange('textColor')}
//             />
//           </Box>
//           <Box sx={{ marginTop: 2 }}>
//             <Typography variant="subtitle1">Background Color</Typography>
//             <SketchPicker
//               color={banner.backgroundColor}
//               onChangeComplete={handleColorChange('backgroundColor')}
//             />
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleBannerDialogClose}>Cancel</Button>
//           <Button onClick={handleBannerDialogClose} variant="contained" color="primary">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default Preview;


// updating for banner at 5 march 2025
// // 6x6
// import React, { useEffect, useState } from "react";
// import { Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, CircularProgress, Slider, Tooltip } from "@mui/material";
// import { getRequest, postRequest } from "./api";
// import { useWebSocketContext } from "../../../../WebSocketContext";
// import { AddIcCallOutlined, Remove, RemoveCircleOutline } from "@mui/icons-material";
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';

// const Preview = ({ screenId }) => {
//   const [orientation, setOrientation] = useState("portrait");
//   const [mute, setMute] = useState(true);
//   const [screenSize, setScreenSize] = useState("small");
//   const [screenSetting, setScreenSetting] = useState({});
//   const [openDialog, setOpenDialog] = useState(false);
//   const [pairingCode, setPairingCode] = useState(null);
//   const userId = sessionStorage.getItem("userid");
//   const [loading, setLoading] = useState(true);
//   const { isWebSocketConnected, sendMessage } = useWebSocketContext();
//   // New state for zoom level
//   const [zoom, setZoom] = useState(1);
//   const [count, setCount] = useState(1);


//   const IncNum = () => {
//     setCount(count + 1);
//   };
//   const DecNum = () => {
//     if (count > 1) setCount(count - 1);
//     else {
//       setCount(1);
//       // alert("min limit reached");
//     }
//   };

//   useEffect(() => {
//     fetchScreenSettings();
//   }, [screenId]);

//   const fetchScreenSettings = async () => {
//     setLoading(true);
//     try {
//       const response = await postRequest("/getAllScreenInfo", {
//         screen_code: screenId,
//       });
//       setScreenSetting(response.data);

//       const pairingResponse = await getRequest(`/single_screen/${screenId}`);
//       setPairingCode(pairingResponse.pairing_code);
//     } catch (error) {
//       console.error("Error fetching screen settings or pairing code", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handlePublishContent = () => {
//     setOpenDialog(true);
//   };

//   const confirmPublish = async () => {
//     if (isWebSocketConnected && pairingCode && screenSetting) {
//       let updatedGridData = [];
//       try {
//         const gridDataArray = JSON.parse(screenSetting.config);
//         let playlist_id = 0;
//         let playlist_found = 0;
//         updatedGridData = gridDataArray.map((widget) => {
//           if (widget.widgetType === "video") {
//             return { ...widget, isMuted: mute };
//           }
//           if (widget.widgetType === "playlist") {
//             playlist_id = widget.playlist_id;
//             playlist_found = 1;
//           }
//           return widget;
//         });

//         if (playlist_found) {
//           const response = await getRequest(`playlist_item/${playlist_id}`);

//           updatedGridData = gridDataArray.map((widget) => {
//             if (widget.widgetType === "playlist") {
//               return { ...widget, url: response, delay: count };
//             }
            
//             return widget;
//           });
//         }

//       } catch (error) {
//         console.error("Error parsing grid data:", error);
//       }

//       const data = {
//         orientation, // shorthand for orientation: orientation
//         gridData: JSON.stringify(updatedGridData), // ensure updatedGridData is correctly structured
//         banner: {
//           position: "top",
//           text: "This is new banner!",
//           textColor: "#000000",
//           fontSize: 24, // changed to an integer if no decimals are needed
//           backgroundColor: "#fff",
//           height: 60
//         }
//       };
      
//       const message = {
//         command: "message",
//         content: true,
//         to: pairingCode,
//         from: userId,
//         message: data,
//       };

//       sendMessage(message);
//       setOpenDialog(false);
//     } else {
//       console.error("WebSocket is not connected or data is missing");
//     }
//   };

//   const cancelPublish = () => {
//     setOpenDialog(false);
//   };

//   const handleOrientationChange = (event) => {
//     setOrientation(event.target.value);
//   };

//   const handleAudio = (event) => {
//     setMute(event.target.value);
//   };

//   const handleScreenSizeChange = (event) => {
//     setScreenSize(event.target.value);
//   };

//   const handleZoomChange = (event, newValue) => {
//     setZoom(newValue);
//   };

//   const screenStyles = {
//     small: { portrait: [150, 300], landscape: [300, 150] },
//     medium: { portrait: [400, 650], landscape: [650, 400] },
//     large: { portrait: [600, 1000], landscape: [1000, 600] },
//   };

//   const getOrientationStyles = () => {
//     const [width, height] = screenStyles[screenSize][orientation];
//     return {
//       width: `${width}px`,
//       height: `${height}px`,
//       border: "2px solid #000",
//       position: "relative",
//       overflow: "hidden",
//       transform: `scale(${zoom})`,
//       transformOrigin: "top left",
//       backgroundColor: "#f0f0f0",
//       // Updated backgroundSize for 6x6 grid
//       backgroundImage: "linear-gradient(to right, rgba(0,0,0,0.1) 1px, transparent 1px), linear-gradient(to bottom, rgba(0,0,0,0.1) 1px, transparent 1px)",
//       backgroundSize: `calc(100% / 6) calc(100% / 6)`,
//       display: "grid",
//       gridTemplateRows: "repeat(6, 1fr)",
//       gridTemplateColumns: "repeat(6, 1fr)",
//     };
//   };

//   const calculateWidgetSpan = (cells) => {
//     if (!cells || !Array.isArray(cells) || cells.length === 0)
//       return { rowSpan: 1, colSpan: 1, startRow: 0, startCol: 0 };

//     const rows = cells.map((cell) => Math.floor(cell / 6)); // Adjusted for 6 columns
//     const cols = cells.map((cell) => cell % 6); // Adjusted for 6 columns

//     return {
//       rowSpan: Math.max(...rows) - Math.min(...rows) + 1,
//       colSpan: Math.max(...cols) - Math.min(...cols) + 1,
//       startRow: Math.min(...rows),
//       startCol: Math.min(...cols),
//     };
//   };

//   const renderWidgetContent = (widgetType, url, text) => {
//     switch (widgetType) {
//       case "image":
//         return (
//           <img
//             src={url}
//             alt="Image"
//             style={{ width: "100%", height: "100%", objectFit: "cover" }}
//           />
//         );
//       case "video":
//         return (
//           <video controls autoPlay loop muted style={{ width: "100%", height: "100%" }}>
//             <source src={url} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         );
//       case "banner":
//         return (
//           <Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffcb7f", color: "#333", fontWeight: "bold", padding: 1 }}>
//             {text}
//           </Box>
//         );
//       case "text":
//         return (
//           <Typography variant="body2" sx={{ textAlign: "center", padding: 1 }}>
//             {text}
//           </Typography>
//         );
//       case "playlist":
//         return (
//           <Typography variant="body2" sx={{ textAlign: "center", padding: 1 }}>
//             {"playlist"}
//           </Typography>
//         );
//       default:
//         return <Typography variant="body2">Unknown Widget</Typography>;
//     }
//   };

//   const renderWidgets = () => {
//     try {
//       const configArray = JSON.parse(screenSetting?.config || "[]");

//       return configArray.map((widget, index) => {
//         const { widgetType, cells, url, text } = widget;
//         const { rowSpan, colSpan, startRow, startCol } = calculateWidgetSpan(cells);

//         return (
//           <Box
//             key={index}
//             sx={{
//               gridRow: `${startRow + 1} / span ${rowSpan}`,
//               gridColumn: `${startCol + 1} / span ${colSpan}`,
//               border: "2px solid #ddd",
//               borderRadius: 1,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               textAlign: "center",
//               overflow: "hidden",
//               backgroundColor: "#fff",
//               boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
//             }}
//           >
//             {renderWidgetContent(widgetType, url, text)}
//           </Box>
//         );
//       });
//     } catch (error) {
//       console.error("Error parsing config JSON:", error);
//       return null;
//     }
//   };

//   if (loading) {
//     return (
//       <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <Box sx={{ padding: 3 }}>
//       <Typography variant="h6" sx={{ marginBottom: 2 }}>Screen Preview</Typography>
//       <Box sx={{ marginBottom: 2, display: "flex", alignItems: "center", flexWrap: 'wrap', gap: 2 }}>
//         <Button variant="contained" color="primary" onClick={handlePublishContent} sx={{ height: "48px", width: "150px" }}>
//           Publish Screen
//         </Button>
      
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Orientation</InputLabel>
//           <Select value={orientation} label="Orientation" onChange={handleOrientationChange} sx={{ width: 150 }}>
//             <MenuItem value="portrait">Portrait</MenuItem>
//             <MenuItem value="landscape">Landscape</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Mute Audio</InputLabel>
//           <Select value={mute} label="Mute Audio" onChange={handleAudio} sx={{ width: 150 }}>
//             <MenuItem value="true">True</MenuItem>
//             <MenuItem value="false">False</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl sx={{ minWidth: 150 }}>
//           <InputLabel>Screen Size</InputLabel>
//           <Select value={screenSize} label="Screen Size" onChange={handleScreenSizeChange} sx={{ width: 150 }}>
//             <MenuItem value="small">24"</MenuItem>
//             <MenuItem value="medium">32"</MenuItem>
//             <MenuItem value="large">43"</MenuItem>
//           </Select>
//         </FormControl>
//         <Box sx={{ width: 150 }}>
//           <Typography variant="caption" sx={{ marginBottom: 1 }}>Zoom</Typography>
//           <Slider value={zoom} onChange={handleZoomChange} min={0.5} max={2} step={0.1} valueLabelDisplay="auto" sx={{ width: 150 }} />
//         </Box>
//         <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
//           <Typography variant="caption" sx={{ marginRight: 1 }}>Delay</Typography>
//           <Tooltip title="Increase Delay">
//             <Button onClick={IncNum} sx={{ minWidth: "auto", padding: 0 }}>
//               <AddIcon />
//             </Button>
//           </Tooltip>
//           <Typography variant="body1" sx={{ marginX: 2 }}>{count}</Typography>
//           <Button onClick={DecNum} sx={{ minWidth: "auto", padding: 0 }}>
//             <RemoveIcon />
//           </Button>
//         </Box>
//       </Box>

//       <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 3 }}>
//         <Box sx={getOrientationStyles()}>{renderWidgets()}</Box>
//       </Box>

//       <Dialog open={openDialog} onClose={cancelPublish}>
//         <DialogTitle>Confirm Publish</DialogTitle>
//         <DialogContent>
//           <Typography>Are you sure you want to publish this screen?</Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={cancelPublish}>Cancel</Button>
//           <Button onClick={confirmPublish} variant="contained" color="primary">
//             Publish
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default Preview;
