// 6x6
//  this layout belongs to screen details advance settings
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Slider,
} from "@mui/material";
import { Add, Attachment, Delete } from "@mui/icons-material";
import { getRequest, postRequest } from "../api";
import { useParams } from "react-router-dom";
import { SketchPicker } from 'react-color';

const WIDGET_TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  BANNER: "banner",
  TEXT: "text",
  PLAYLIST: "playlist",
};

const MyLayouts = ({ layouts, setlayouts }) => {

  console.log('my layouts', layouts)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  const [playlists, setPlaylists] = useState([]);

  const [selectedLayoutId, setSelectedLayoutId] = useState(null);
  // New state for the specific widget id to update
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const [selectedWidgetType, setSelectedWidgetType] = useState(null);
  const [openFileManager, setOpenFileManager] = useState(false);
  const [fileManagerFiles, setFileManagerFiles] = useState([]);
  const [mediatype, setMediatype] = useState("");
  const [medialoading, setMedialoading] = useState(false);
  const userId = sessionStorage.getItem("userid");
  const { screenId } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  // banner
  const [fontSize, setFontSize] = useState(20); // Default font size
  const [backgroundColor, setBackgroundColor] = useState('#fff'); // Default background color
  const [textColor, setTextColor] = useState('#000000'); // Default text color
  const [height, setHeight] = useState(60); // Default height



  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  const handleBackgroundColorChange = (e) => {
    setBackgroundColor(e.target.value);
  };

  const handleTextColorChange = (e) => {
    setTextColor(e.target.value);
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
  };

  // const handleSaveTextInput = () => {
  //   // Save the inputs (you can send these to your backend or use them within your app)
  //   console.log('Saving inputs:', { textInput, fontSize, backgroundColor, textColor, height });
  //   setDialogOpen(false); // Close the dialog after saving
  // };


  const fetchPlaylist = async () => {
    try {
      const response = await getRequest(`/playlists/${userId}`);
      console.log("All playlist info:", response);

      // Ensure response.data is an array before setting state
      if (Array.isArray(response)) {
        setPlaylists(response);
      } else {
        console.error("Unexpected response format:", response);
        setPlaylists([]); // Reset to empty array if format is incorrect
      }
    } catch (error) {
      console.error("Error fetching playlists", error);
      setPlaylists([]); // Handle error case by setting an empty array
    }
  };

  // Fetch playlists on component mount
  useEffect(() => {
    fetchPlaylist();
  }, []);



  // Helper to return color based on widget type
  const renderWidgetColor = (widgetType) => {
    switch (widgetType) {
      case WIDGET_TYPES.IMAGE:
        return "#f9a19a";
      case WIDGET_TYPES.VIDEO:
        return "#90caf9";
      case WIDGET_TYPES.BANNER:
        return "#ffcb7f";
      case WIDGET_TYPES.TEXT:
        return "#a5d7a7";
      default:
        return "#f8f8f8";
    }
  };

  // Calculate grid parameters from cells
  const calculateWidgetSpan = (cells) => {
    const rows = cells.map((cell) => Math.floor(cell / 6));
    const cols = cells.map((cell) => cell % 6);

    return {
      rowSpan: Math.max(...rows) - Math.min(...rows) + 1,
      colSpan: Math.max(...cols) - Math.min(...cols) + 1,
      startRow: Math.min(...rows),
      startCol: Math.min(...cols),
    };
  };

  // Update to store widget id along with layout id and type
  const handleAddButtonClick = (layoutId, widgetType, widgetId) => {
    setSelectedLayoutId(layoutId);
    setSelectedWidgetType(widgetType);
    setSelectedWidgetId(widgetId); // Store the widget's unique id
    setDialogOpen(true);
  };

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value);
  };

  const handlePlaylistChange = (event) => {
    setSelectedPlaylist(event.target.value);
  };

  const handleSaveTextInput = () => {
    const updatedLayouts = layouts.map((layout) => {
      if (layout.id === selectedLayoutId) {
        const config = JSON.parse(layout.config);
        const updatedConfig = config.map((widget) => {
          // Only update the widget with the matching id
          if (widget.id === selectedWidgetId) {
            const updatedWidget = { ...widget };

            switch (selectedWidgetType) {
              case WIDGET_TYPES.IMAGE:
              case WIDGET_TYPES.VIDEO:
                updatedWidget.url = textInput;
                break;
              case WIDGET_TYPES.BANNER:
                updatedWidget.text = textInput;
                updatedWidget.textColor = textColor;
                updatedWidget.fontSize = fontSize;
                updatedWidget.backgroundColor = backgroundColor;
                updatedWidget.height = height;
                break;
              case WIDGET_TYPES.PLAYLIST:
                updatedWidget.playlist_id = selectedPlaylist;
                break;
              default:
                break;
            }
            return updatedWidget;
          }
          return widget;
        });
        return { ...layout, config: JSON.stringify(updatedConfig) };
      }
      return layout;
    });

    setlayouts(updatedLayouts);
    console.log("updatedLayouts", updatedLayouts);
    setTextInput("");
    setSelectedPlaylist("");
    setDialogOpen(false);
  };

  const handleRemoveWidgetContent = (layoutId, widgetType, widgetId) => {
    const updatedLayouts = layouts.map((layout) => {
      if (layout.id === layoutId) {
        const config = JSON.parse(layout.config);
        const updatedConfig = config.map((widget) => {
          if (widget.id === widgetId && widget.widgetType === widgetType) {
            const updatedWidget = { ...widget };
            switch (widgetType) {
              case WIDGET_TYPES.IMAGE:
              case WIDGET_TYPES.VIDEO:
                updatedWidget.url = "";
                break;
              case WIDGET_TYPES.BANNER:
                updatedWidget.text = "";
                break;
              case WIDGET_TYPES.PLAYLIST:
                updatedWidget.url = "";
                break;
              default:
                break;
            }
            return updatedWidget;
          }
          return widget;
        });
        return { ...layout, config: JSON.stringify(updatedConfig) };
      }
      return layout;
    });

    setlayouts(updatedLayouts);
  };

  const handleFileManagerOpen = (type) => {
    setOpenFileManager(true);
    setMediatype(type);
    fetchFileManagerItems(type);
  };

  const fetchFileManagerItems = async (type) => {
    setMedialoading(true);
    try {
      const response = await getRequest(`/files?type=${type}&user_id=${userId}`);
      console.log('files ', response.files)
      setFileManagerFiles(response.files);
    } catch (error) {
      console.error(error);
    } finally {
      setMedialoading(false);
    }
  };

  const handleFileManagerClose = () => {
    setOpenFileManager(false);
    setMediatype("");
  };

  const handleFileSelect = (url) => {
    setTextInput(url);
    handleFileManagerClose();
  };


  const handleApplyLayout = async (layoutId) => {
    const selectedLayout = layouts.find((layout) => layout.id === layoutId);
    if (!selectedLayout) return;
    const updatedData = {
      screen_code: screenId,
      user_id: userId,
      layout_id: layoutId,
      config: selectedLayout.config,
    };

    try {
      console.log("sending this data ", updatedData);
      await postRequest("/screen-settings", updatedData);
      setSnackbarMessage("Layout applied successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      console.error("Error applying layout:", error);
      setSnackbarMessage("Failed to apply layout. Please try again.");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  const renderColor = (widgetType) => {
    switch (widgetType) {
      case "image":
        return "#f9a19a";
      case "video":
        return "#90caf9";
      case "banner":
        return "#ffcb7f";
      case "text":
        return "#a5d7a7";
      default:
        return "#f8f8f8";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: 3 }}>
      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        My Layouts
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 2 }}>
        Choose an existing layout below to fit your display needs.
      </Typography>
      {/*  we have to check this function again */}
      <Grid container spacing={3}>
        {layouts.map((template) => (
          <Grid item xs={12} sm={6} md={4} key={template.id}>
            <Card sx={{ display: "flex", flexDirection: "column", boxShadow: 3, borderRadius: 2 }}>
              <Grid container spacing={0} sx={{ display: "grid", width: "100%", p: 1, mt: 3 }}>
                {JSON.parse(template.config).map((widget) => {
                  const { widgetType, cells } = widget;
                  const { rowSpan, colSpan, startRow, startCol } = calculateWidgetSpan(cells);

                  return (
                    <Grid
                      item
                      key={widget.id}
                      xs={false}
                      sx={{
                        gridRow: `${startRow + 0.2} / span ${rowSpan}`,
                        gridColumn: `${startCol + 1} / span ${colSpan}`,
                        minHeight: `${rowSpan * 30}px`,
                        minWidth: `${colSpan * 20}px`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px solid #ddd",
                        borderRadius: 1,
                        backgroundColor: renderWidgetColor(widgetType),
                        position: "relative",
                      }}
                    >
                      {widget.url || widget.text ? (
                        widget.widgetType === WIDGET_TYPES.IMAGE ? (
                          <img
                            src={widget.url}
                            alt="Preview"
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                          />
                        ) : widget.widgetType === WIDGET_TYPES.VIDEO ? (
                          <video width="100%" height="100%" autoPlay loop muted>
                            <source src={widget.url} type="video/mp4" />
                          </video>
                        ) : widget.widgetType === WIDGET_TYPES.BANNER ? (
                          <Typography
                            variant="h6"
                            sx={{ textAlign: "center", padding: 1, color: "#fff" }}
                          >
                            {widget.text}
                          </Typography>
                        ) : null
                      ) : (
                        <IconButton
                          onClick={() =>
                            // Pass the widget.id along with layout id and widget type
                            handleAddButtonClick(template.id, widget.widgetType, widget.id)
                          }
                        >
                          <Add />
                        </IconButton>
                      )}

                      {(widget.url || widget.text) && (
                        <IconButton
                          onClick={() =>
                            handleRemoveWidgetContent(template.id, widget.widgetType, widget.id)
                          }
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "red",
                          }}
                        >
                          <Delete />
                        </IconButton>
                      )}
                    </Grid>
                  );
                })}
              </Grid>


              <CardContent>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {template.name}
                  </Typography>
                </div>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                  {template.description}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title="Apply Layout" arrow>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ borderRadius: 1 }}
                      onClick={() => handleApplyLayout(template.id)}
                    >
                      Apply
                    </Button>
                  </Tooltip>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>


      {/* File Manager Dialog */}
      <Dialog open={openFileManager} onClose={handleFileManagerClose}>
        <DialogTitle>Select File</DialogTitle>
        <DialogContent>
          {medialoading ? (
            <div>Loading...</div>
          ) : (
            <Grid container spacing={2}>
              {fileManagerFiles.map((file) => (
                <Grid item xs={4} key={file.id}>
                  <Box sx={{ padding: 2, border: "1px solid #ddd", borderRadius: 2 }}>
                    {mediatype === WIDGET_TYPES.IMAGE ? (
                      <img src={file.url} alt={file.name} style={{ width: "100%", height: "auto" }} />
                    ) : (
                      <video width="100%" height="auto" autoPlay controls>
                        <source src={file.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <Button onClick={() => handleFileSelect(file.url)} variant="outlined" fullWidth sx={{ mt: 2 }}>
                      Select
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileManagerClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          {selectedWidgetType === WIDGET_TYPES.BANNER
            ? "Enter Banner Text"
            : selectedWidgetType === WIDGET_TYPES.PLAYLIST
              ? "Select Playlist"
              : "Enter Custom Link"}
        </DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          {
            selectedWidgetType === WIDGET_TYPES.BANNER ? (
              <Box>
                <TextField
                  label="Enter Banner Text"
                  variant="outlined"
                  fullWidth
                  value={textInput}
                  onChange={handleTextInputChange}
                  margin="normal"
                  sx={{
                    fontSize: fontSize, // Dynamic font size
                    backgroundColor: backgroundColor, // Dynamic background color
                    color: textColor, // Dynamic text color
                    height: `${height}px`, // Dynamic height
                    '& .MuiInputBase-root': {
                      height: '100%',
                    },
                    '& .MuiOutlinedInput-root': {
                      height: '100%',
                    },
                  }}
                />

                {/* Font Size Slider */}
                <Box sx={{ marginTop: 2 }}>
                  <Slider
                    value={fontSize}
                    min={10}
                    max={50}
                    onChange={handleFontSizeChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}px`}
                    sx={{ width: '100%' }}
                  />
                  <TextField
                    label="Font Size (px)"
                    variant="outlined"
                    fullWidth
                    value={fontSize}
                    onChange={(e) => setFontSize(e.target.value)}
                    margin="normal"
                    type="number"
                  />
                </Box>

                {/* Background Color Picker */}
                <Box sx={{ marginTop: 2 }}>
                  <TextField
                    label="Background Color"
                    variant="outlined"
                    fullWidth
                    value={backgroundColor}
                    onChange={(e) => setBackgroundColor(e.target.value)}
                    margin="normal"
                  />
                  {/* Color Picker for Background Color */}
                  <SketchPicker
                    color={backgroundColor} // Ensure this is set to color value (hex)
                    onChangeComplete={(color) => setBackgroundColor(color.hex)} // Extract hex value from color object
                    sx={{ marginTop: 2 }}
                  />
                </Box>

                {/* Text Color Picker */}
                <Box sx={{ marginTop: 2 }}>
                  <TextField
                    label="Text Color"
                    variant="outlined"
                    fullWidth
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                    margin="normal"
                  />
                  {/* Color Picker for Text Color */}
                  <SketchPicker
                    color={textColor} // Ensure this is set to color value (hex)
                    onChangeComplete={(color) => setTextColor(color.hex)} // Extract hex value from color object
                    sx={{ marginTop: 2 }}
                  />
                </Box>

                {/* Height Slider */}
                <Box sx={{ marginTop: 2 }}>
                  <Slider
                    value={height}
                    min={40}
                    max={120}
                    onChange={handleHeightChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => `${value}px`}
                    sx={{ width: '100%' }}
                  />
                  <TextField
                    label="Height (px)"
                    variant="outlined"
                    fullWidth
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                    margin="normal"
                    type="number"
                  />
                </Box>
              </Box>
            ) : selectedWidgetType === WIDGET_TYPES.PLAYLIST ? (
              <FormControl fullWidth margin="normal">
                <InputLabel>Select Playlist</InputLabel>
                <Select value={selectedPlaylist} onChange={handlePlaylistChange} label="Select Playlist">
                  {playlists?.length > 0 ? (
                    playlists?.map((playlist) => (
                      <MenuItem key={playlist?.id} value={playlist?.id}>
                        {playlist.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No playlists available</MenuItem>
                  )}
                </Select>
              </FormControl>
            ) : (
              <TextField
                label="Enter Custom Link"
                variant="outlined"
                fullWidth
                value={textInput}
                onChange={handleTextInputChange}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Attachment">
                        <IconButton onClick={() => handleFileManagerOpen(selectedWidgetType)} edge="end">
                          <Attachment />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveTextInput}>Save</Button>
        </DialogActions>
      </Dialog>



      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MyLayouts;
