import React from 'react';

function Services() {
  return (
    <section id="services">
      <div className="heading">
        <h2>Digital Signage Solutions</h2>
        <h1>Empower Your Business with Flexible Display Options</h1>
      </div>

      <div className="services-content">
        <div className="box">
          <img src="https://i.postimg.cc/2Scvd0nc/icon.png" alt="USB-based" />
          <span>USB-based Digital Signage</span>
          <p>Easy plug-and-play solution. Just load your content onto a USB stick and display it anywhere.</p>
        </div>

        <div className="box">
          <img src="https://i.postimg.cc/2Scvd0nc/icon.png" alt="Cloud-based" />
          <span>Cloud-based Management</span>
          <p>Manage your digital signage content remotely, with real-time updates and scheduling.</p>
        </div>

        <div className="box">
          <img src="https://i.postimg.cc/2Scvd0nc/icon.png" alt="Scheduling" />
          <span>Content Scheduling</span>
          <p>Set up automated content schedules to display different content at different times.</p>
        </div>

        <div className="box">
          <img src="https://i.postimg.cc/2Scvd0nc/icon.png" alt="Multi-screen" />
          <span>Multi-screen Display</span>
          <p>Display your content across multiple screens simultaneously for larger impact.</p>
        </div>

        <div className="box">
          <img src="https://i.postimg.cc/2Scvd0nc/icon.png" alt="Analytics" />
          <span>Analytics & Reporting</span>
          <p>Track the performance of your digital signage displays with detailed analytics and reports.</p>
        </div>

        <div className="box">
          <img src="https://i.postimg.cc/2Scvd0nc/icon.png" alt="Customizable Templates" />
          <span>Customizable Templates</span>
          <p>Choose from a variety of templates and customize them to match your brand and messaging.</p>
        </div>
      </div>
    </section>
  );
}

export default Services;
