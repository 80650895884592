import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 

function Navbar() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <nav>
      <a href="#" className="logo">
        <i className='bx bx-play'></i>Digi-Signage
      </a>
      <div className={`toggle ${menuActive ? 'active' : ''}`} onClick={toggleMenu}></div>
      <ul className={`menu ${menuActive ? 'active' : ''}`}>
        <li><a href="#services" onClick={toggleMenu}>Services</a></li>
        <li><a href="#team" onClick={toggleMenu}>Our Team</a></li>
        {/* <li><a href="#clients" onClick={toggleMenu}>Agency</a></li> */}
        <li><a href="#connect" onClick={toggleMenu}>Contact Us</a></li>

        {/* Replace <a> tags with <Link> for navigation */}
        <li><Link to="/login" className="login-btn" onClick={toggleMenu}>Login</Link></li>
        <li><Link to="/signup" className="signup-btn" onClick={toggleMenu}>Sign Up</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
















      





      












