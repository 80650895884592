// src/routes/adminRoutes.js
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Admin/section/pages/Dashboard";
import AddUser from "../Admin/section/pages/Component/AddUser";
import CloudUsers from "../Admin/section/pages/Component/CloudUsers";
import UsbUsers from "../Admin/section/pages/Component/UsbUsers";
import CloudUserDetails from "../Admin/section/pages/Component/CloudUserDetails";
import UsbUserDetails from "../Admin/section/pages/Component/UsbUserDetails";

const AdminRoutes = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="/addusers" element={<AddUser/>}/>
    <Route path="/cloudusers" element={<CloudUsers/>}/>
    <Route path="/clouduser/:id" element={<CloudUserDetails/>} />
    <Route path="/usbusers" element={<UsbUsers/>}/>
    <Route path="/usbusers/:id" element={<UsbUserDetails/>} />
   
  </Routes>
);

export default AdminRoutes;
