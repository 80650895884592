import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Menu,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  Input,
  TextField,
  Snackbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CornerText from "./CornerText";
import { postRequest, putRequest, getRequest, deleteRequest } from "./api";

const LayoutSettings = () => {
  const [layoutConfig, setLayoutConfig] = useState({
    id: null,
    name: "",
    rows: 1,
    columns: 1,
    orientation: "landscape",
    sections: [],
    banner: "disable",
    bannerPosition: "top",
    bannerText: "",
  });
  const [layouts, setLayouts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const userId = sessionStorage.getItem("userid");

  useEffect(() => {
    fetchLayouts();
  }, []);

  const fetchLayouts = async () => {
    setLoading(true);
    try {
      console.log("fetching layouts with id", userId);
      const data = await getRequest(`/layouts/${userId}`);
      setLayouts(data);
      setSnackbarMessage("Layouts fetched successfully!");
    } catch (error) {
      setSnackbarMessage("Error fetching layouts", error);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLayoutConfig((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleMenuClick = (event, sectionIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedSection(sectionIndex);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleContentSelection = (contentType) => {
    if (selectedSection === null) return;

    const updatedSections = [...(layoutConfig.sections || [])];

    updatedSections[selectedSection] = {
      block: selectedSection + 1,
      type: contentType.toLowerCase(),
      url: contentType === "image" || contentType === "video" ? "" : undefined,
      value: contentType === "text" ? "" : undefined,
      id: contentType === "playlist" ? 1 : null,
    };

    setLayoutConfig((prevState) => ({
      ...prevState,
      sections: updatedSections,
    }));
    setAnchorEl(null);
  };

  const handleSaveLayout = async () => {
    setLoading(true);
    try {
      const response = layoutConfig.id
        ? await putRequest(`/layouts/${layoutConfig.id}`, layoutConfig)
        : await postRequest("/layouts", layoutConfig);
      setSnackbarMessage(
        layoutConfig.id
          ? "Layout updated successfully!"
          : "Layout created successfully!"
      );
      fetchLayouts();
    } catch (error) {
      setSnackbarMessage("Error saving layout");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleNewLayout = () => {
    setLayoutConfig({
      id: null,
      name: "",
      rows: 1,
      columns: 1,
      orientation: "landscape",
      sections: [],
      banner: "disable",
      bannerPosition: "top",
      bannerText: "",
    });
  };

  const handleScreenIdChange = (e) =>
    setLayoutConfig((prevState) => ({
      ...prevState,
      name: e.target.value,
    }));

  const handleDeleteLayout = async (layoutId) => {
    setLoading(true);
    try {
      await deleteRequest(`/layouts/${layoutId}`);
      setSnackbarMessage("Layout deleted successfully!");
      fetchLayouts();
    } catch (error) {
      setSnackbarMessage("Error deleting layout");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleEditLayout = (layout) => {
    setLayoutConfig({
      id: layout.id,
      name: layout.name,
      rows: layout.rows,
      columns: layout.columns,
      orientation: layout.orientation,
      sections: layout?.sections?.length > 0 ? layout.sections : [],
      banner: layout.banner || "disable",
      bannerPosition: layout.bannerPosition || "top",
      bannerText: layout.bannerText || "",
    });
  };

  const renderLayoutPreview = () => {
    const { rows, columns, sections, banner, bannerPosition, bannerText } =
      layoutConfig;

    const layoutStyles = {
      display: "grid",
      gridTemplateColumns: `repeat(${columns}, 1fr)`,
      gridTemplateRows: `repeat(${rows}, 1fr)`,
      gap: "10px",
      height: "300px",
      position: "relative",
    };

    const sectionsPreview = [...Array(rows * columns)].map((_, index) => {
      const sectionContent =
        sections && sections[index] ? sections[index].type : "No content";
      return (
        <Box
          key={index}
          sx={{
            backgroundColor: "#aeb3da",
            height: "100%",
            borderRadius: 1,
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            border: "1px solid #ddd",
          }}
        >
          <CornerText sectionContent={sectionContent} />
          <IconButton
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            color="primary"
            onClick={(e) => handleMenuClick(e, index)}
          >
            <AddIcon />
          </IconButton>
        </Box>
      );
    });

    return (
      <Box sx={layoutStyles} border={1} padding={2} borderRadius={2}>
        {banner === "enable" && bannerPosition === "top" && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              backgroundColor: "#333",
              color: "white",
              padding: 2,
              textAlign: "center",
            }}
          >
            {bannerText || "Banner Content (Top)"}
          </Box>
        )}
        {sectionsPreview}
        {banner === "enable" && bannerPosition === "bottom" && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              backgroundColor: "#333",
              color: "white",
              padding: 2,
              textAlign: "center",
            }}
          >
            {bannerText || "Banner Content (Bottom)"}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Button variant="contained" onClick={handleNewLayout} sx={{ mb: 3 }}>
        New Layout
      </Button>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Layout Settings
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Layout Name"
            value={layoutConfig.name}
            onChange={handleScreenIdChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="rows-select-label">Rows</InputLabel>
            <Select
              labelId="rows-select-label"
              name="rows"
              value={layoutConfig.rows}
              onChange={handleInputChange}
              label="Rows"
            >
              {[1, 2, 3, 4, 5].map((row) => (
                <MenuItem key={row} value={row}>
                  {row}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id="columns-select-label">Columns</InputLabel>
            <Select
              labelId="columns-select-label"
              name="columns"
              value={layoutConfig.columns}
              onChange={handleInputChange}
              label="Columns"
            >
              {[1, 2, 3, 4, 5].map((col) => (
                <MenuItem key={col} value={col}>
                  {col}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Orientation
        </Typography>
        <RadioGroup
          row
          name="orientation"
          value={layoutConfig.orientation}
          onChange={handleInputChange}
        >
          <FormControlLabel
            value="landscape"
            control={<Radio />}
            label="Landscape"
          />
          <FormControlLabel
            value="portrait"
            control={<Radio />}
            label="Portrait"
          />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" sx={{ mb: 2 }}>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Banner
        </Typography>
        <RadioGroup
          row
          name="banner"
          value={layoutConfig.banner}
          onChange={handleInputChange}
        >
          <FormControlLabel value="enable" control={<Radio />} label="Enable" />
          <FormControlLabel
            value="disable"
            control={<Radio />}
            label="Disable"
          />
        </RadioGroup>
        {layoutConfig.banner === "enable" && (
          <>
            <RadioGroup
              row
              name="bannerPosition"
              value={layoutConfig.bannerPosition}
              onChange={handleInputChange}
            >
              <FormControlLabel value="top" control={<Radio />} label="Top" />
              <FormControlLabel
                value="bottom"
                control={<Radio />}
                label="Bottom"
              />
            </RadioGroup>

            <TextField
              label="Banner Text"
              name="bannerText"
              value={layoutConfig.bannerText}
              onChange={handleInputChange}
              fullWidth
              sx={{ mt: 2 }}
            />
          </>
        )}
      </FormControl>

      <Button
        variant="contained"
        onClick={handleSaveLayout}
        sx={{ mb: 3, marginTop: 4 }}
      >
        {layoutConfig.id ? "Update Layout" : "Save Layout"}
      </Button>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Preview of Screen Layout:
        </Typography>
        {renderLayoutPreview()}
      </Box>

      <Typography variant="h6" sx={{ mb: 2 }}>
        Existing Layouts:
      </Typography>
      <Box>
        {layouts?.map((layout) => (
          <Box
            key={layout.id}
            sx={{ mb: 1, display: "flex", justifyContent: "space-between" }}
          >
            <Typography>{layout.name}</Typography>
            <Box>
              <Button onClick={() => handleEditLayout(layout)} color="primary">
                Apply this layout
              </Button>
              <Button
                onClick={() => handleDeleteLayout(layout.id)}
                color="error"
              >
                Delete
              </Button>
            </Box>
          </Box>
        ))}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleContentSelection("Image")}>
          Image
        </MenuItem>
        <MenuItem onClick={() => handleContentSelection("Video")}>
          Video
        </MenuItem>
        <MenuItem onClick={() => handleContentSelection("Playlist")}>
          Playlist
        </MenuItem>
        <MenuItem onClick={() => handleContentSelection("Text")}>Text</MenuItem>
      </Menu>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default LayoutSettings;
