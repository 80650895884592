import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { ContentCopy, Publish } from "@mui/icons-material";
import axios from "axios";

const CloneScreens = () => {
  const [screens, setScreens] = useState([]);
  const [sourceScreen, setSourceScreen] = useState(null);
  const [targetScreens, setTargetScreens] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const token = sessionStorage.getItem("Token");
  const user_id = sessionStorage.getItem("userid");

  useEffect(() => {
    fetchScreens();
  }, []);

  const fetchScreens = () => {
    setLoading(true);
    axios
      .get(`https://digiflyer.in/cloudapi/api/v1/user/screens/${user_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setScreens(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching screens:", error);
        setMessage("Error fetching screens");
        setSeverity("error");
        setLoading(false);
      });
  };

  const handleApplySettings = () => {
    console.log({
      sourceScreen: sourceScreen.id,
      targetScreens: targetScreens.map((s) => s.id),
    })
    if (!sourceScreen || targetScreens.length === 0) {
      setMessage("Please select a source and at least one target screen");
      setSeverity("error");
      return;
    }
    setLoading(true);
    axios
      .post("https://digiflyer.in/cloudapi/api/v1/user/apply-screen-settings", {
        sourceScreen: sourceScreen.id,
        targetScreens: targetScreens.map((s) => s.id),
      }, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(() => {
        setMessage("Settings applied successfully");
        setSeverity("success");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error applying settings:", error);
        setMessage("Error applying settings");
        setSeverity("error");
        setLoading(false);
      });
  };

  const handleApplyAndPublish = () => {
    handleApplySettings();
    // TODO: Add publish logic
  };

  return (
    <Box sx={{ maxWidth: 500, margin: "auto", mt: 4 }}>
      <Card sx={{ p: 3, borderRadius: 3, boxShadow: 3 }}>
        <CardContent>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Copy & Apply Screen Settings
          </Typography>

          <Autocomplete
            options={screens}
            getOptionLabel={(option) => option.name}
            value={sourceScreen}
            onChange={(event, newValue) => setSourceScreen(newValue)}
            renderInput={(params) => <TextField {...params} label="Source Screen" fullWidth />}
            sx={{ mb: 2 }}
          />

          <Autocomplete
            multiple
            options={screens}
            getOptionLabel={(option) => option.name}
            value={targetScreens}
            onChange={(event, newValue) => setTargetScreens(newValue)}
            renderInput={(params) => <TextField {...params} label="Target Screens" fullWidth />}
            sx={{ mb: 2 }}
          />

          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplySettings}
              startIcon={loading ? <CircularProgress size={20} /> : <ContentCopy />}
              disabled={loading}
              fullWidth
            >
              Apply Settings
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleApplyAndPublish}
              startIcon={loading ? <CircularProgress size={20} /> : <Publish />}
              disabled={loading}
              fullWidth
            >
              Apply & Publish
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Snackbar
        open={message !== ""}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert severity={severity} onClose={() => setMessage("")}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CloneScreens;
