import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, CircularProgress, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getRequest, postRequest, deleteRequest } from './api';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

const Playlist = () => {
  const [playlists, setPlaylists] = useState([]);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [loading, setLoading] = useState(false); 
  const [addingPlaylist, setAddingPlaylist] = useState(false); 
  const [snackbarOpen, setSnackbarOpen] = useState(false); 
  const [snackbarMessage, setSnackbarMessage] = useState(''); 
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 
  const userId = sessionStorage.getItem('userid');

  const navigate = useNavigate(); 

  useEffect(() => {
    fetchPlaylistsData();
  }, []);

  const fetchPlaylistsData = async () => {
    setLoading(true);
    try {
      const response = await getRequest(`/playlists/${userId}`);
      setPlaylists(response);
    } catch (error) {
      console.error('Error fetching playlists:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddPlaylist = async () => {
    if (!newPlaylistName) {
      alert('Please enter a playlist name.');
      return;
    }
    
    setAddingPlaylist(true); 

    try {
      const newPlaylist = { name: newPlaylistName, user_id: userId };
      const response = await postRequest('/playlists', newPlaylist);
      
      setNewPlaylistName('');
      setSnackbarMessage('Playlist added successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      fetchPlaylistsData();

    } catch (error) {
      console.error('Error creating playlist:', error);
      setSnackbarMessage('Error creating playlist.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setAddingPlaylist(false); 
    }
  };

  const handleDeletePlaylist = async (id) => {
    try {
      await deleteRequest(`/playlists/${id}`);
      setPlaylists(playlists.filter(playlist => playlist.id !== id));
      setSnackbarMessage('Playlist deleted successfully!');
      fetchPlaylistsData();
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting playlist:', error);
      setSnackbarMessage('Error deleting playlist.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditPlaylist = (id) => {
    navigate(`/playlist/${id}`);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Playlist Name', width: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleEditPlaylist(params.row.playlist_code)}
            sx={{ mr: 1 }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleDeletePlaylist(params.row.playlist_code)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Playlist Section
      </Typography>

      <TextField
        label="New Playlist Name"
        value={newPlaylistName}
        onChange={(e) => setNewPlaylistName(e.target.value)}
        fullWidth
        sx={{ mb: 2 }}
      />
      
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleAddPlaylist}
        disabled={addingPlaylist} 
      >
        {addingPlaylist ? 'Adding...' : 'Add Playlist'}
      </Button>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ mt: 3, height: 400, width: '100%' }}>
          <DataGrid
            rows={playlists}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            density="compact" 

          />
        </Box>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default Playlist;
