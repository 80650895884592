import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  Tabs,
  Tab,
  Snackbar,
  CircularProgress,
  Modal,
  IconButton,
} from "@mui/material";
import { getRequest, postRequest, deleteRequest } from "./api";
import { useParams } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { Close } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const EditPlaylist = () => {
  const { id } = useParams();
  const [playlist, setPlaylist] = useState({});
  const [playlistName, setPlaylistName] = useState("");
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileTypes, setFileTypes] = useState("image");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openModal, setOpenModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const userId = sessionStorage.getItem("userid");

  const handleFilePreview = (file) => {
    setCurrentFile(file);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentFile(null);
  };

  const fetchPlaylistData = useCallback(async () => {
    try {
      const response = await getRequest(`/single_playlist/${id}`);
      setPlaylist(response);
      setPlaylistName(response.name);
    } catch (error) {
      console.error("Error fetching playlist:", error);
    }
  }, [id]);

  const fetchFiles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getRequest(
        `/files?type=${fileTypes}&user_id=${sessionStorage.getItem("userid")}`
      );
      setFiles(response.files);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      setLoading(false);
    }
  }, [fileTypes]);

  const fetchPlaylistItems = useCallback(async () => {
    try {
      const response = await getRequest(`/playlist-items/${id}`);
      setSelectedFiles(response.map((item) => item.file_id));
    } catch (error) {
      console.error("Error fetching playlist items:", error);
    }
  }, [id]);

  useEffect(() => {
    fetchPlaylistData();
    fetchFiles();
    fetchPlaylistItems();
  }, [id, fetchPlaylistData, fetchFiles, fetchPlaylistItems]);

  const handleFileSelect = async (fileId) => {
    try {
      setLoading(true);

      if (selectedFiles.includes(fileId)) {
        const response = await deleteRequest(`/playlist-items/${id}/${fileId}`);
        setSnackbarMessage("File removed from playlist!");
        setSnackbarSeverity("success");
        // setSelectedFiles(selectedFiles.filter((id) => id !== fileId));
      } else {
        const response = await postRequest("/playlist-items", {
          playlist_code: id,
          file_id: fileId,
        });
        setSnackbarMessage("File added to playlist!");
        setSnackbarSeverity("success");
        // setSelectedFiles([...selectedFiles, fileId]);
      }

      setSnackbarOpen(true);
      fetchPlaylistItems();
    } catch (error) {
      console.error("Error updating playlist:", error);
      setSnackbarMessage("Error updating playlist");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setFileTypes(newValue === 0 ? "image" : "video");
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Edit Playlist: {playlistName}
      </Typography>

      <input
        type="text"
        value={playlistName}
        onChange={(e) => setPlaylistName(e.target.value)}
        placeholder="Enter playlist name"
        style={{ padding: 10, width: "100%", marginBottom: 20 }}
      />

      <Typography variant="h6" sx={{ marginTop: 3 }}>
        Add Files to Playlist
      </Typography>

      <Tabs
        value={fileTypes === "image" ? 0 : 1}
        onChange={handleTabChange}
        aria-label="file categories"
      >
        <Tab label="Images" />
        <Tab label="Videos" />
      </Tabs>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {files.map((file) => (
            <Grid item xs={6} sm={4} md={3} key={file.id}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid #ddd",
                  padding: 2,
                  borderRadius: 1,
                  position: "relative",
                  backgroundColor: selectedFiles.includes(file.id)
                    ? "#e0f7fa"
                    : "transparent",
                  transition: "background-color 0.3s ease",
                  "&:hover": {
                    boxShadow: 3,
                  },
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file.type.startsWith("image") ? (
                    <img
                      src={file.url}
                      alt={file.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: 4,
                        transition: "transform 0.3s ease",
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.transform = "scale(1.1)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.transform = "scale(1)")
                      }
                    />
                  ) : (
                    <video width="100" height="100" controls>
                      <source src={file.url} type={file.type} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Box>

                <Typography
                  variant="body2"
                  sx={{
                    marginTop: 1,
                    textAlign: "center",
                    wordWrap: "break-word",
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton
                  onClick={() => handleFilePreview(file)}
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: 0.5,
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                    },
                  }}
                >
                  <VisibilityIcon color="primary" />
                </IconButton>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFiles.includes(file.id)}
                      onChange={() => handleFileSelect(file.id)}
                      sx={{
                        position: "absolute",
                        bottom: 10,
                        right: 10,
                        zIndex: 1,
                      }}
                    />
                  }
                  label=""
                  sx={{ position: "absolute", bottom: 10, right: 10 }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: 2,
              boxShadow: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "auto",
              height: "auto",
              maxWidth: "90%",
              maxHeight: "90vh",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 1,
                fontSize: "5rem",
                padding: "8px",
              }}
            >
              <Close color="error" />
            </IconButton>

            {currentFile?.type.startsWith("image") ? (
              <img
                src={currentFile?.url}
                alt={currentFile?.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            ) : (
              <video width="100%" controls>
                <source src={currentFile?.url} type={currentFile?.type} />
                Your browser does not support the video tag.
              </video>
            )}

            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {currentFile?.name}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <MuiAlert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default EditPlaylist;
