// 6x6
export const isRectangle = (cells) => {
  if (cells.length < 2) return true;

  const rows = new Set();
  const cols = new Set();

  // Loop through cells and calculate their row and column
  cells.forEach((index) => {
    const row = Math.floor(index / 6); // Adjusted for 6 columns
    const col = index % 6; // Adjusted for 6 columns
    rows.add(row);
    cols.add(col);
  });

  // Convert rows and columns sets to sorted arrays
  const rowArray = Array.from(rows).sort((a, b) => a - b);
  const colArray = Array.from(cols).sort((a, b) => a - b);

  // Check if rows and columns are consecutive
  const rowsAreConsecutive = rowArray.every((row, i) => i === 0 || row === rowArray[i - 1] + 1);
  const colsAreConsecutive = colArray.every((col, i) => i === 0 || col === colArray[i - 1] + 1);

  // Find the top-left and bottom-right coordinates of the rectangle
  const topLeft = {
    row: Math.min(...rowArray),
    col: Math.min(...colArray),
  };
  const bottomRight = {
    row: Math.max(...rowArray),
    col: Math.max(...colArray),
  };

  // Check that all cells in the defined rectangle are present
  for (let row = topLeft.row; row <= bottomRight.row; row++) {
    for (let col = topLeft.col; col <= bottomRight.col; col++) {
      const cellIndex = row * 6 + col; // Adjusted for 6 columns
      if (!cells.includes(cellIndex)) {
        return false; // If any cell is missing, return false
      }
    }
  }

  return rowsAreConsecutive && colsAreConsecutive;
};
