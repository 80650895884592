import { Routes, Route } from "react-router-dom";
import Dashboard from "../Accountant/section/pages/Dashboard";

const AccontantRoutes = () => (
    <Routes>
    <Route path="/" element={<Dashboard />} />
  </Routes>
);

export default AccontantRoutes;
