import { Routes, Route } from "react-router-dom";
import Dashboard from "../Hr/section/pages/Dashboard";
import EmployeeDirectory from "../Hr/section/pages/EmployeeDirectory";
import RecruitmentTracker from "../Hr/section/pages/RecruitmentTracker";
import Onboarding from "../Hr/section/pages/Onboarding";
import TrainingManagement from "../Hr/section/pages/TrainingManagement";
import PerformanceReviews from "../Hr/section/pages/PerformanceReviews";
import Compliance from "../Hr/section/pages/Compliance";
import Reports from "../Hr/section/pages/Reports";

const HrRoutes = () => (
    <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/employees" element={<EmployeeDirectory />} />
        <Route path="/recruitment" element={<RecruitmentTracker />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/training" element={<TrainingManagement />} />
        <Route path="/performance" element={<PerformanceReviews />} />
        <Route path="/compliance" element={<Compliance />} />
        <Route path="/reports" element={<Reports />} />
    </Routes>
);

export default HrRoutes;
