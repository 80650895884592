import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, LinearProgress, CircularProgress, Divider } from '@mui/material';
import { AccessTime, Visibility, Mouse, ShowChart } from '@mui/icons-material';

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [screensData, setScreensData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setScreensData([
        {
          screenId: 1,
          screenActiveTime: 120, 
          contentViews: 750,
          userInteractions: 300,
          errorRate: 5, 
          uptime: 99.8, 
        },
        {
          screenId: 2,
          screenActiveTime: 95,
          contentViews: 650,
          userInteractions: 400,
          errorRate: 3,
          uptime: 98.9,
        },
        {
          screenId: 3,
          screenActiveTime: 150,
          contentViews: 900,
          userInteractions: 500,
          errorRate: 4,
          uptime: 99.5,
        },
      ]);
      setLoading(false);
    }, 2000); 
  }, []);

  const renderPercentage = (value) => `${value}%`;

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Screen Analytics
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {screensData.map((screenData) => (
            <Box key={screenData.screenId} sx={{ mb: 6 }}>
              <Typography variant="h3" sx={{ mb: 3 }}>
                Screen {screenData.screenId}
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 8px 4px rgba(0, 123, 255, 0.7)', 
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease-in-out', 
                      '&:hover': {
                        boxShadow: '0px 0px 16px 8px rgba(0, 123, 255, 1)', 
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }} color="textSecondary">
                        Active Time
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AccessTime sx={{ mr: 2 }} />
                        <Typography variant="h5">{screenData.screenActiveTime} min</Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={(screenData.screenActiveTime / 180) * 100} sx={{ mt: 2 }} />
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Total active time this session
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 8px 4px rgba(0, 123, 255, 0.7)',
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        boxShadow: '0px 0px 16px 8px rgba(0, 123, 255, 1)',
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }} color="textSecondary">
                        Content Views
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Visibility sx={{ mr: 2 }} />
                        <Typography variant="h5">{screenData.contentViews}</Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={(screenData.contentViews / 1000) * 100} sx={{ mt: 2 }} />
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Total views of content displayed on screen
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* User Interactions Card */}
                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 8px 4px rgba(0, 123, 255, 0.7)',
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        boxShadow: '0px 0px 16px 8px rgba(0, 123, 255, 1)',
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }} color="textSecondary">
                        User Interactions
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Mouse sx={{ mr: 2 }} />
                        <Typography variant="h5">{screenData.userInteractions}</Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={(screenData.userInteractions / 500) * 100} sx={{ mt: 2 }} />
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Total interactions (clicks, touches, etc.)
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 8px 4px rgba(0, 123, 255, 0.7)',
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        boxShadow: '0px 0px 16px 8px rgba(0, 123, 255, 1)',
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }} color="textSecondary">
                        Error Rate
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ShowChart sx={{ mr: 2 }} />
                        <Typography variant="h5">{renderPercentage(screenData.errorRate)}</Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={100 - screenData.errorRate} color="error" sx={{ mt: 2 }} />
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Percentage of failed interactions or issues
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 8px 4px rgba(0, 123, 255, 0.7)',
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease-in-out',
                      '&:hover': {
                        boxShadow: '0px 0px 16px 8px rgba(0, 123, 255, 1)',
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 2 }} color="textSecondary">
                        Uptime
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ShowChart sx={{ mr: 2 }} />
                        <Typography variant="h5">{renderPercentage(screenData.uptime)}</Typography>
                      </Box>
                      <LinearProgress variant="determinate" value={screenData.uptime} sx={{ mt: 2 }} />
                      <Typography variant="body2" sx={{ mt: 1 }}>
                        Screen uptime during the tracking period
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }} />

            </Box>
            
          ))} 
        </Box>
      )}

      <Divider sx={{ my: 4 }} />
      <Typography variant="body2" color="textSecondary" align="center">
        Analytics are based on the current session and simulated data.
      </Typography>
    </Box>
  );
};

export default Analytics;
