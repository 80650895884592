import { useState } from "react";
import { useAuth } from "./AuthContext/AuthContext";
import AdminLayout from "./Admin/section/AdminLayout";
import UserLayout from "./User/section/UserLayout";
import Login from "./Login";
import { useMode } from "./theme";
import HrLayout from "./Hr/section/HrLayout";
import AccountantLayout from "./Accountant/section/AccountantLayout";
import FoLayout from "./FieldOfficer/section/FoLayout";
import LandingPage from "./LandingPage";
import SignupPage from "./SignupPage,";
import { Route, Router, Routes } from "react-router-dom";
import UsbUserLayout from "./usbuser/UsbUserLayout";

function App() {
  const { user } = useAuth();
  const admin = sessionStorage.getItem("admin");
  const user1 = sessionStorage.getItem("user");
  const usbuser = sessionStorage.getItem("usbuser");
  const hr = sessionStorage.getItem("hr");
  const accountant = sessionStorage.getItem("accountant");
  const fo = sessionStorage.getItem("fo");

  const [mode, setMode] = useMode();
  const [sideBar, setSideBar] = useState(true);

  if (admin) {
    return (
      <AdminLayout
        mode={mode}
        setMode={setMode}
        sideBar={sideBar}
        setSideBar={setSideBar}
      />
    );
  }

  if (user1) {
    return <UserLayout mode={mode} setMode={setMode} />;
  }
  if (usbuser) {
    return <UsbUserLayout mode={mode} setMode={setMode} />;
  }

  if (hr) {
    return <HrLayout mode={mode} setMode={setMode} />;
  }

  if (accountant) {
    return <AccountantLayout mode={mode} setMode={setMode} />;
  }

  if (fo) {
    return <FoLayout mode={mode} setMode={setMode} />;
  }

  // return <Login />;
  return (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignupPage />} />
    </Routes>
  );
}

export default App;
