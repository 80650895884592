// import React, { useState } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { Responsive, WidthProvider } from "react-grid-layout";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";

// const ResponsiveGridLayout = WidthProvider(Responsive);

// const ImageVideoList = ({ items, onDragEnd }) => {
//   return (
//     <Droppable droppableId="droppable-list" direction="vertical">
//       {(provided) => (
//         <div
//           ref={provided.innerRef}
//           {...provided.droppableProps}
//           //   style={{ marginRight: "10px", width: "200px" }}
//         >
//           <h3>Drag Items Below</h3>
//           {items.map((item, index) => (
//             <Draggable key={item.id} draggableId={item.id} index={index}>
//               {(provided) => (
//                 <div
//                   ref={provided.innerRef}
//                   {...provided.draggableProps}
//                   {...provided.dragHandleProps}
//                   style={{
//                     ...provided.draggableProps.style,
//                     padding: "10px",
//                     // border: "1px solid black",
//                     margin: "5px",
//                     backgroundColor: "white",
//                     cursor: "move",
//                   }}
//                 >
//                   {item.type === "image" ? (
//                     <img
//                       src={item.src}
//                       alt="Item"
//                       style={{ width: "100px", height: 80 }}
//                     />
//                   ) : (
//                     <video controls width="100px">
//                       <source src={item.src} type="video/mp4" />
//                     </video>
//                   )}
//                 </div>
//               )}
//             </Draggable>
//           ))}
//           {provided.placeholder}
//         </div>
//       )}
//     </Droppable>
//   );
// };

// const DroppableGrid = () => {
//   const [gridItems, setGridItems] = useState([]);
//   const [availableItems] = useState([
//     {
//       id: "1",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     { id: "2", type: "video", src: "https://www.w3schools.com/html/movie.mp4" },
//     {
//       id: "3",
//       type: "image",
//       src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     { id: "5", type: "video", src: "https://www.w3schools.com/html/movie.mp4" },
//     {
//       id: "6",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     { id: "7", type: "video", src: "https://www.w3schools.com/html/movie.mp4" },
//     {
//       id: "8",
//       type: "image",
//       src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     { id: "9", type: "video", src: "https://www.w3schools.com/html/movie.mp4" },
//     {
//       id: "10",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     {
//       id: "11",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//     },
//     {
//       id: "12",
//       type: "image",
//       src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     {
//       id: "13",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//     },
//     {
//       id: "14",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     {
//       id: "15",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//     },
//     {
//       id: "16",
//       type: "image",
//       src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
//     },
//     {
//       id: "17",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//     },
//   ]);
//   // console.log(gridItems)
//   const onDragEnd = (result) => {
//     const { destination, source, draggableId } = result;

//     // If dropped outside the droppable area
//     if (!destination) {
//       return;
//     }

//     // If dropped inside the grid (handle the grid's drop)
//     if (destination.droppableId === "droppable-grid") {
//       const item = availableItems.find(
//         (item) => item.id === result.draggableId
//       );

//       const newItem = {
//         ...item,
//         i: draggableId,
//         x: destination.droppableId === "droppable-grid" ? 0 : 1,
//         // y:
//         //   gridItems.length === 0
//         //     ? 0
//         //     : gridItems[gridItems.length - 1].y +
//         //       gridItems[gridItems.length - 1].h,
//         y:gridItems.length % 2 === 0 ? 0 : 1,
//         w: 1,
//         h: 1,
//       };

//       setGridItems([...gridItems, newItem]);
//     }
//   };
//   const onLayoutChange = (layout, layouts) => {
//     // console.log(layout)
//     // Layout contains information about all items in the grid
//     // console.log(layout);
//     // layout.forEach((item) => {
//     //   // item contains the following properties:
//     //   // i: id of the item, x: column index, y: row index, w: width in grid units, h: height in grid units
//     //   console.log(`Item ${item.i} is at row: ${item.y}, column: ${item.x}`);
//     // });

//      const newItems = layout.map((item) => ({
//       ...item,
//       i: item.i,  // Ensure items still have their ID
//       x: item.x,  // Column position
//       y: item.y,  // Row position
//       w: item.w,  // Width
//       h: item.h,  // Height
//     }));

// //     // Clear items that exceed the maximum grid capacity
// //     const filteredItems = newItems.filter(
// //       (item) => item.y < 4 && item.x < 2
// //     );
// // console.log(filteredItems)
//     // setGridItems(filteredItems);
//   };
//   return (
//     <div style={{ display: "flex" }}>
//       {/* List of draggable items */}
//       <DragDropContext onDragEnd={onDragEnd}>
//         <ImageVideoList items={availableItems} />

//         {/* Droppable grid */}
//         <div style={{ flex: 1 }}>
//           <Droppable droppableId="droppable-grid" direction="horizontal">
//             {(provided) => (
//               <div
//                 ref={provided.innerRef}
//                 {...provided.droppableProps}
//                 style={{
//                   width: "300px",
//                   minHeight: "300px",
//                   border: "1px solid black",
//                   padding: "10px",
//                 }}
//               >
//                 <ResponsiveGridLayout
//                   className="layout"
//                   layouts={
//                     {
//                       //   lg: gridItems.map((item) => ({
//                       //     i: item.i,
//                       //     x: item.x,
//                       //     y: item.y,
//                       //     w: item.w,
//                       //     h: item.h,
//                       //   })),
//                       //   md: gridItems.map((item) => ({
//                       //     i: item.i,
//                       //     x: item.x,
//                       //     y: item.y,
//                       //     w: item.w,
//                       //     h: item.h,
//                       //   })),
//                       //   sm: gridItems.map((item) => ({
//                       //     i: item.i,
//                       //     x: item.x,
//                       //     y: item.y,
//                       //     w: item.w,
//                       //     h: item.h,
//                       //   })),
//                       //   xs: gridItems.map((item) => ({
//                       //     i: item.i,
//                       //     x: item.x,
//                       //     y: item.y,
//                       //     w: item.w,
//                       //     h: item.h,
//                       //   })),
//                     }
//                   }
//                   rowHeight={100}
//                   maxRows={4}
//                   breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
//                   cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
//                   // cols={{
//                   //   lg: 3,
//                   //   md: 3,
//                   //   sm: 2,
//                   //   xs: 1,
//                   // }}
//                   isResizable={true}
//                   isDraggable={true}
//                   onLayoutChange={onLayoutChange}
//                   draggableCancel=".draggable-not"
//                 >
//                   {gridItems.map((item) => (
//                     <div key={item.i} className="grid-item">
//                       {item.type === "image" ? (
//                         <img
//                           src={item.src}
//                           alt="Grid item"
//                           style={{ width: "100%", height: "100%" }}
//                         />
//                       ) : (
//                         <video
//                           controls
//                           style={{ width: "100%", height: "100%" }}
//                         >
//                           <source src={item.src} type="video/mp4" />
//                           Your browser does not support the video tag.
//                         </video>
//                       )}
//                     </div>
//                   ))}
//                 </ResponsiveGridLayout>
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         </div>
//       </DragDropContext>
//     </div>
//   );
// };

// export default DroppableGrid;
// import React, { useState } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { Responsive, WidthProvider } from "react-grid-layout";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";

// const ResponsiveGridLayout = WidthProvider(Responsive);

// const ImageVideoList = ({ items }) => {
//   return (
//     <Droppable droppableId="droppable-list" direction="vertical">
//       {(provided) => (
//         <div
//           ref={provided.innerRef}
//           {...provided.droppableProps}
//           style={{ marginRight: "10px", width: "200px" }}
//         >
//           <h3>Drag Items Below</h3>
//           {items.map((item, index) => (
//             <Draggable key={item.id} draggableId={item.id} index={index}>
//               {(provided) => (
//                 <div
//                   ref={provided.innerRef}
//                   {...provided.draggableProps}
//                   {...provided.dragHandleProps}
//                   style={{
//                     ...provided.draggableProps.style,
//                     padding: "10px",
//                     margin: "5px",
//                     backgroundColor: "white",
//                     cursor: "move",
//                   }}
//                 >
//                   {item.type === "image" ? (
//                     <img
//                       src={item.src}
//                       alt="Item"
//                       style={{ width: "100px", height: 80 }}
//                     />
//                   ) : (
//                     <video controls width="100px">
//                       <source src={item.src} type="video/mp4" />
//                     </video>
//                   )}
//                 </div>
//               )}
//             </Draggable>
//           ))}
//           {provided.placeholder}
//         </div>
//       )}
//     </Droppable>
//   );
// };

// const DroppableGrid = () => {
//   const [gridItems, setGridItems] = useState([]);
//   const [availableItems] = useState([
//     {
//       id: "1",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "2",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "3",
//       type: "image",
//       src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "5",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "6",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "7",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "8",
//       type: "image",
//       src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "9",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "10",
//       type: "image",
//       src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
//       w: 1,
//       h: 1,
//     },
//     {
//       id: "11",
//       type: "video",
//       src: "https://www.w3schools.com/html/movie.mp4",
//       w: 1,
//       h: 1,
//     },
//   ]);

//   const maxGridItems = 8; // 4 rows * 2 columns
//   const gridWidth = 2; // 2 columns
//   const gridHeight = 4; // 4 rows

//   const onDragEnd = (result) => {
//     const { destination, source, draggableId } = result;

//     // If dropped outside the droppable area
//     if (!destination) {
//       return;
//     }

//     // Prevent dropping if grid is full
//     if (gridItems.length >= maxGridItems) {
//       alert("The grid is full! Cannot add more items.");
//       return; // Prevent adding more items if the grid is full
//     }

//     // Get the dropped item
//     const droppedItem = availableItems.find((item) => item.id === draggableId);
//     if (!droppedItem) return;

//     // Check if there is space in the grid
//     let x = -1,
//       y = -1;

//     // Loop through the grid to find an available space
//     for (let row = 0; row < gridHeight; row++) {
//       for (let col = 0; col < gridWidth; col++) {
//         // Check if the space is available for this item (taking width and height into account)
//         const positionOccupied = gridItems.some(
//           (item) =>
//             item.x < col + droppedItem.w &&
//             item.x + item.w > col &&
//             item.y < row + droppedItem.h &&
//             item.y + item.h > row
//         );

//         if (!positionOccupied) {
//           x = col;
//           y = row;
//           break;
//         }
//       }
//       if (x !== -1 && y !== -1) break;
//     }

//     // If no space available, alert the user
//     if (x === -1 || y === -1) {
//       alert("No space available for new items.");
//       return;
//     }

//     // Add the new item at the next available position with its width and height
//     const updatedGridItems = [
//       ...gridItems,
//       {
//         ...droppedItem,
//         x,
//         y,
//         w: droppedItem.w,
//         h: droppedItem.h,
//       },
//     ];

//     // Update the grid with the new item
//     setGridItems(updatedGridItems);
//   };

//   return (
//     <div style={{ display: "flex" }}>
//       {/* List of draggable items */}
//       <DragDropContext onDragEnd={onDragEnd}>
//         <ImageVideoList items={availableItems} />

//         {/* Droppable grid */}
//         <div style={{ flex: 1 }}>
//           <Droppable droppableId="droppable-grid" direction="horizontal">
//             {(provided) => (
//               <div
//                 ref={provided.innerRef}
//                 {...provided.droppableProps}
//                 style={{
//                   width: "300px",
//                   minHeight: "300px",
//                   border: "1px solid black",
//                   padding: "10px",
//                 }}
//               >
//                 <ResponsiveGridLayout
//                   className="layout"
//                   layouts={{
//                     lg: gridItems.map((item) => ({
//                       i: item.id,
//                       x: item.x,
//                       y: item.y,
//                       w: item.w,
//                       h: item.h,
//                     })),
//                   }}
//                   rowHeight={100}
//                   maxRows={gridHeight} // Fix max rows to 4
//                   breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
//                   cols={{
//                     lg: gridWidth,
//                     md: gridWidth,
//                     sm: gridWidth,
//                     xs: gridWidth,
//                   }} // Fix max columns to 2
//                   isResizable={true} // Disable resizing, if needed
//                   isDraggable={true} // Disable dragging in the grid (since we already handle the drop)
//                 >
//                   {gridItems.map((item) => (
//                     <div
//                       key={item.id}
//                       className="grid-item"
//                       data-grid={{
//                         i: item.id,
//                         x: item.x,
//                         y: item.y,
//                         w: item.w,
//                         h: item.h,
//                       }}
//                     >
//                       {item.type === "image" ? (
//                         <img
//                           src={item.src}
//                           alt="Grid item"
//                           style={{ width: "100%", height: "100%" }}
//                         />
//                       ) : item.type === "video" ? (
//                         <video
//                           controls
//                           style={{ width: "100%", height: "100%" }}
//                         >
//                           <source src={item.src} type="video/mp4" />
//                           Your browser does not support the video tag.
//                         </video>
//                       ) : (
//                         <div>Unsupported item type</div> // Fallback for any other types
//                       )}
//                     </div>
//                   ))}
//                 </ResponsiveGridLayout>
//                 {provided.placeholder}
//               </div>
//             )}
//           </Droppable>
//         </div>
//       </DragDropContext>
//     </div>
//   );
// };

// export default DroppableGrid;
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const ImageVideoList = ({ items, onDragEnd }) => {
  return (
    <Droppable droppableId="droppable-list" direction="vertical">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ marginRight: "10px", width: "200px" }}
        >
          <h3>Drag Items Below</h3>
          {items.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                    padding: "10px",
                    margin: "5px",
                    backgroundColor: "white",
                    cursor: "move",
                  }}
                >
                  {item.type === "image" ? (
                    <img
                      src={item.src}
                      alt="Item"
                      style={{ width: "100px", height: 80 }}
                    />
                  ) : (
                    <video controls width="100px">
                      <source src={item.src} type="video/mp4" />
                    </video>
                  )}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const DroppableGrid = () => {
  const [gridItems, setGridItems] = useState([]);
  const [availableItems] = useState([
    {
      id: "1",
      type: "image",
      src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
      w: 1,
      h: 1,
    },
    {
      id: "2",
      type: "video",
      src: "https://www.w3schools.com/html/movie.mp4",
      w: 1,
      h: 1,
    },
    {
      id: "3",
      type: "image",
      src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
      w: 1,
      h: 1,
    },
    {
      id: "5",
      type: "video",
      src: "https://www.w3schools.com/html/movie.mp4",
      w: 1,
      h: 1,
    },
    {
      id: "6",
      type: "image",
      src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
      w: 1,
      h: 1,
    },
    {
      id: "7",
      type: "video",
      src: "https://www.w3schools.com/html/movie.mp4",
      w: 1,
      h: 1,
    },
    {
      id: "8",
      type: "image",
      src: "https://images.pexels.com/photos/206359/pexels-photo-206359.jpeg?auto=compress&cs=tinysrgb&w=600",
      w: 1,
      h: 1,
    },
    {
      id: "9",
      type: "video",
      src: "https://www.w3schools.com/html/movie.mp4",
      w: 1,
      h: 1,
    },
    {
      id: "10",
      type: "image",
      src: "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=600",
      w: 1,
      h: 1,
    },
    {
      id: "11",
      type: "video",
      src: "https://www.w3schools.com/html/movie.mp4",
      w: 1,
      h: 1,
    },
  ]);

  const maxGridItems = 8; // 4 rows * 2 columns
  const gridWidth = 2; // 2 columns
  const gridHeight = 4; // 4 rows
// console.log(gridItems)
// const totalHeight = gridItems.reduce((acc, item) => acc + item.h, 0);
// const totalWidth = gridItems.reduce((acc, item) => acc + Math.min(item.w, 2), 0);
// console.log(totalHeight)
// console.log(totalWidth)
  // Function to check if grid is full
  const isGridFull = () => {
    console.log(gridItems);
    const totalHeight = gridItems.reduce((acc, item) => acc + item.h, 0);
    const totalWidth = gridItems.reduce((acc, item) => acc + item.w, 0);
    console.log(totalHeight, totalWidth);
    return totalHeight <= gridHeight && totalWidth <= gridWidth;
  };

  // Function to check if a resized item will fit into the grid's boundaries
  const isResizeValid = (item) => {
    const totalHeight =
      gridItems.reduce(
        (acc, gridItem) => acc + (gridItem.y === item.y ? gridItem.h : 0),
        0
      ) + item.h;
    const totalWidth =
      gridItems.reduce(
        (acc, gridItem) => acc + (gridItem.x === item.x ? gridItem.w : 0),
        0
      ) + item.w;

    return totalHeight <= gridHeight && totalWidth <= gridWidth;
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // If dropped outside the droppable area
    if (!destination) {
      return;
    }

    const droppedItem = availableItems.find((item) => item.id === draggableId);
    if (!droppedItem) return;

    // Check if the item can fit into the grid
    // if (!isGridFull()) {
    //   alert("The grid is full! Cannot add more items.");
    //   return; // Prevent adding more items if the grid is full
    // }

    // Find available spot for dropped item
    let x = -1,
      y = -1;

    for (let row = 0; row < gridHeight; row++) {
      for (let col = 0; col < gridWidth; col++) {
        if (
          !gridItems.some(
            (item) =>
              item.x < col + droppedItem.w &&
              item.x + item.w > col &&
              item.y < row + droppedItem.h &&
              item.y + item.h > row
          )
        ) {
          x = col;
          y = row;
          break;
        }
      }
      if (x !== -1 && y !== -1) break;
    }

    if (x === -1 || y === -1) {
      alert("No available space to add the item!");
      return; // No space available
    }

    // Add item with its position
    setGridItems([
      ...gridItems,
      { ...droppedItem, x, y, w: droppedItem.w, h: droppedItem.h },
    ]);
  };

  const onLayoutChange = (layout) => {
    layout.forEach((item) => {
      // Ensure the layout stays within the grid bounds
      const newItem = {
        ...item,
        w: item.w > gridWidth ? gridWidth : item.w,
        h: item.h > gridHeight ? gridHeight : item.h,
      };
      // console.log(newItem)
      // Update the grid state
      setGridItems((prev) =>
        prev.map((i) => (i.i === newItem.i ? newItem : i))
      );
    });
  };
console.log(gridItems)
  return (
    <div style={{ display: "flex" }}>
      {/* List of draggable items */}
      <DragDropContext onDragEnd={onDragEnd}>
        <ImageVideoList items={availableItems} />

        {/* Droppable grid */}
        <div style={{ flex: 1 }}>
          <Droppable droppableId="droppable-grid" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  width: "300px",
                  minHeight: "300px",
                  border: "1px solid black",
                  padding: "10px",
                }}
              >
                <ResponsiveGridLayout
                  className="layout"
                  layouts={{
                    lg: gridItems.map((item) => ({
                      i: item.id,
                      x: item.x,
                      y: item.y,
                      w: item.w,
                      h: item.h,
                    })),
                  }}
                  rowHeight={100}
                  maxRows={gridHeight} // Fix max rows to 4
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }}
                  cols={{
                    lg: gridWidth,
                    md: gridWidth,
                    sm: gridWidth,
                    xs: gridWidth,
                  }} // Fix max columns to 2
                  isResizable={true} // Enable resizing
                  isDraggable={true} // Disable dragging in the grid (handled by react-beautiful-dnd)
                  onLayoutChange={onLayoutChange}
                  draggableCancel=".draggable-not"
                >
                  {gridItems.map((item) => (
                    <div
                      key={item.id}
                      className="grid-item"
                      data-grid={{
                        i: item.id,
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                      }}
                    >
                      {item.type === "image" ? (
                        <img
                          src={item.src}
                          alt="Grid item"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : item.type === "video" ? (
                        <video
                          controls
                          style={{ width: "100%", height: "100%" }}
                        >
                          <source src={item.src} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <div>Unsupported item type</div>
                      )}
                    </div>
                  ))}
                </ResponsiveGridLayout>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </div>
  );
};

export default DroppableGrid;
