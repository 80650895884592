import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const WebSocketContext = createContext();

export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children }) => {
  const [isWebSocketConnected, setWebSocketConnected] = useState(false);
  const [message, setMessage] = useState(null); 
  const ws = useRef(null);
  const reconnectIntervalRef = useRef(1000);
  const url = 'https://digiflyer.in:8091';
  const userId = sessionStorage.getItem('userid');
  
  const handleOnMessage = (event) => {
    const messageData = event.data;
    try {
      
      const parsedMessage = JSON.parse(messageData);

      
      setMessage(parsedMessage);
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  const handleOnError = (error) => {
    
    console.log('WebSocket error:', error);
    
  };

  const handleOnClose = (event) => {
    setWebSocketConnected(false);
    console.log('WebSocket closed:', event);
    
    setTimeout(() => {
      reconnectIntervalRef.current = Math.min(
        reconnectIntervalRef.current * 2,
        30000 
      );
      connectWebSocket();
    }, reconnectIntervalRef.current);
  };

  const sendMessage = (message) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));  
    } else {
      console.error('WebSocket is not connected');
    }
  };

  const connectWebSocket = () => {
    try {
      ws.current = new WebSocket(url);

      ws.current.onopen = () => {
        setWebSocketConnected(true);
        reconnectIntervalRef.current = 1000; 
      };

      ws.current.onmessage = handleOnMessage;
      ws.current.onerror = handleOnError;
      ws.current.onclose = handleOnClose;

    } catch (error) {
      console.error('Error connecting WebSocket:', error);
    }
  };

  useEffect(() => {
    connectWebSocket(); 
    return () => {
      if (ws.current) {
        ws.current.close(); 
      }
    };
  }, [url]); 


  
  useEffect(() => {
    if (isWebSocketConnected &&  userId) {
      sendMessage({ command: 'register', userId: userId });
      console.log('sent from websocket');
    }
  }, [isWebSocketConnected])
  
  
  return (
    <WebSocketContext.Provider
      value={{
        isWebSocketConnected,
        sendMessage,
        message, 
        handleOnError,
        handleOnClose,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
