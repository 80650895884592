import { Routes, Route } from "react-router-dom";
import Dashboard from "../User/section/pages/Dashboard";
import Screens from "../User/section/pages/components/Screens";
import ScreenDetail from "../User/section/pages/components/ScreenDetail";
import Shop from "../User/section/pages/components/Shop";
import Playlist from "../User/section/pages/components/Playlist";
import FileManager from "../User/section/pages/components/FileManager";
import EditPlaylist from "../User/section/pages/components/EditPlaylist";
import Schedule from "../User/section/pages/components/Schedule";
import Analytics from "../User/section/pages/components/Analytics";
import Widgets from "../User/section/pages/components/Widgets";
import LayoutSettings from "../User/section/pages/components/LayoutSettings";
import WidgetGrid from "../User/section/pages/components/widgets/WidgetGrid";
import TryComponent from "../User/section/pages/TryComponent";
import Addimage from "../User/section/pages/components/widgets/Addimage";
import CloneScreens from "../User/section/pages/components/CloneScreens";

const UserRoutes = () => (
  <Routes>
    <Route path="/" element={<Dashboard />} />
    <Route path="/try" element={<TryComponent />} />
    <Route path="/try1" element={<Addimage />} />

    <Route path="/screens" element={<Screens />} />
    <Route path="/clone_screens" element={<CloneScreens />} />
    
    <Route path="/shop" element={<Shop />} />
    <Route path="/playlist" element={<Playlist />} />
    <Route path="/playlist/:id" element={<EditPlaylist />} />

    <Route path="/schedule" element={<Schedule />} />
    <Route path="/layout" element={<LayoutSettings />} />
    <Route path="/advance_layout" element={<WidgetGrid />} />

    <Route path="/analytics" element={<Analytics />} />
    <Route path="/widgets" element={<Widgets />} />
    <Route path="/files" element={<FileManager />} />
    <Route path="/screens/:screenId" element={<ScreenDetail />} />
  </Routes>
);

export default UserRoutes;
