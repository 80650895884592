// src/Accountant/section/AccountantLayout.js
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext } from "../../theme";
import AccountantHeader from "./Header"; // Accountant-specific header
import AccountantSidebar from "./Sidebar"; // Accountant-specific sidebar
import AccontantRoutes from "../../Routes/accontantRoutes";
const AccountantLayout = ({ mode, setMode }) => {
  return (
    <ColorModeContext.Provider value={setMode}>
      <ThemeProvider theme={mode}>
        <CssBaseline />
        <div className="app">
          <AccountantSidebar />
          <main className="content">
            <AccountantHeader />
            <AccontantRoutes />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AccountantLayout;
