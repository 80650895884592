import React from 'react';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="copyright">
      <p>&#169; Copyright DIGIFLYER ADVERTISEMENT SERVICES PRIVATE LIMITED      </p>
      <p>&copy; {currentYear} All Rights Reserved</p>

    </div>
  );
}

export default Footer;
