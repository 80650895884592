// src/pages/TrainingManagement.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const TrainingManagement = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Training Management</Typography>
      {/* Add training management logic here */}
      <Typography variant="body1">
        This page will manage employee training programs and schedules.
      </Typography>
    </Box>
  );
};

export default TrainingManagement;
