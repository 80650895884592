import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Container, IconButton, InputAdornment, Typography, Paper, CircularProgress } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { styled } from "@mui/system";
import { useAuth } from "./AuthContext/AuthContext";
import toast, { Toaster } from "react-hot-toast";

const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto',
  gridTemplateAreas: '"centerLeft centerRight"',
  minHeight: '100vh',
  backgroundColor: '#2d2d2d',
});

const Brand = styled('div')({
  gridArea: 'centerLeft',
  backgroundColor: '#2d2d2d',
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '2rem',
  padding: '1rem',
});

const SvgLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <circle cx="50" cy="50" r="40" fill="#ff6347" />
    <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="16" fontWeight="bold" dy=".3em">
      Digi Signage
    </text>
  </svg>
);

const LoginBox = styled('div')({
  gridArea: 'centerRight',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#fff',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  maxWidth: 400,
  boxShadow: 'none',
}));

const StyledButton = styled(Button)({
  margin: '16px 0 24px',
});

const StyledTextField = styled(TextField)({
  marginBottom: '16px',
});

const StyledForm = styled('form')({
  width: '100%',
});

const userCredentials = [
  { role: "User", email: "user1@gmail.com", password: "12345678" }
];

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);  

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Please enter your email.");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return false;
    }
    setEmailError("");
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Please enter your password.");
      return false;
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const { login } = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      setLoading(true);  
      const data = { email, password };
      axios
        .post("https://digiflyer.in/cloudapi/api/v1/auth/login", data)
        .then((res) => {
          sessionStorage.setItem("userid", res.data.user.id);
          sessionStorage.setItem("Token", res.data.access_token);

          const roleMap = {
            is_admin: "admin",
            is_user: "user",
            is_usbuser:"usbuser"
          };

          for (const [key, role] of Object.entries(roleMap)) {
            if (res.data.user[key]) {
              login(role);
              sessionStorage.setItem(`user_${role}`, 1);
              navigate("/");
              return;
            }
          }
        })
        .catch((error) => {
          toast.error("Invalid Username or Password", {
            position: "bottom-left",
            duration: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleDirectLogin = (role, email, password) => {
    setEmail(email);
    setPassword(password);
    handleSubmit({ preventDefault: () => { } }); 
  };

  const handleGoBack = () => {
    navigate("/"); 
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Wrapper>
        <Brand>
          <SvgLogo />
        </Brand>
        <LoginBox>
          <StyledPaper elevation={6}>
            <IconButton onClick={handleGoBack} sx={{ position: 'absolute', top: 16, left: 16, fontSize: '2rem', color: 'white' }}>
              <ArrowBackIcon />
            </IconButton>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <StyledForm onSubmit={handleSubmit}>
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!emailError}
                helperText={emailError}
              />
              <StyledTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <StyledButton type="submit" fullWidth variant="contained" color="primary" disabled={loading}>
                {loading ? <CircularProgress size={24} color="secondary" /> : "Sign In"}
              </StyledButton>
            </StyledForm>

            {userCredentials.map(({ role, email, password }) => (
              <StyledButton
                key={role}
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={() => handleDirectLogin(role, email, password)}
                disabled={loading}
              >
                Login as {role}
              </StyledButton>
            ))}
          </StyledPaper>
        </LoginBox>
      </Wrapper>
    </>
  );
}

export default Login;


// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { TextField, Button, Container, IconButton, InputAdornment, Typography, Paper } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";  // Import the arrow icon
// import axios from "axios";
// import { styled } from "@mui/system";
// import { useAuth } from "./AuthContext/AuthContext";
// import toast, { Toaster } from "react-hot-toast";

// // Styled components
// const Wrapper = styled('div')({
//   display: 'grid',
//   gridTemplateColumns: '1fr 1fr',
//   gridTemplateRows: 'auto',
//   gridTemplateAreas: '"centerLeft centerRight"',
//   minHeight: '100vh',
//   backgroundColor: '#2d2d2d',
// });

// const Brand = styled('div')({
//   gridArea: 'centerLeft',
//   backgroundColor: '#2d2d2d',
//   color: '#fff',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   fontSize: '2rem',
//   padding: '1rem',
// });

// // A sample SVG logo (you can replace this with your actual SVG file or SVG component)
// const SvgLogo = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//     <circle cx="50" cy="50" r="40" fill="#ff6347" />
//     <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="16" fontWeight="bold" dy=".3em">
//       Digi Signage
//     </text>
//   </svg>
// );

// const LoginBox = styled('div')({
//   gridArea: 'centerRight',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: '#fff',
// });

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   width: '100%',
//   maxWidth: 400,
//   boxShadow: 'none',
// }));

// const StyledButton = styled(Button)({
//   margin: '16px 0 24px',
// });

// const StyledTextField = styled(TextField)({
//   marginBottom: '16px',
// });

// const StyledForm = styled('form')({
//   width: '100%',
// });

// const userCredentials = [
//   { role: "User", email: "user1@gmail.com", password: "12345678" }
// ];

// function Login() {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [emailError, setEmailError] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const validateEmail = () => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!email) {
//       setEmailError("Please enter your email.");
//       return false;
//     } else if (!emailRegex.test(email)) {
//       setEmailError("Invalid email format.");
//       return false;
//     }
//     setEmailError("");
//     return true;
//   };

//   const validatePassword = () => {
//     if (!password) {
//       setPasswordError("Please enter your password.");
//       return false;
//     } else if (password.length < 8) {
//       setPasswordError("Password must be at least 8 characters long.");
//       return false;
//     }
//     setPasswordError("");
//     return true;
//   };

//   const { login } = useAuth();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const isEmailValid = validateEmail();
//     const isPasswordValid = validatePassword();

//     if (isEmailValid && isPasswordValid) {
//       const data = { email, password };
//       axios
//         .post("https://digiflyer.in/cloudapi/api/v1/auth/login", data)
//         .then((res) => {
//           sessionStorage.setItem("userid", res.data.user.id);
//           sessionStorage.setItem("Token", res.data.access_token);

//           const roleMap = {
//             is_admin: "admin",
//             is_user: "user",
//           };

//           for (const [key, role] of Object.entries(roleMap)) {
//             if (res.data.user[key]) {
//               login(role);
//               sessionStorage.setItem(`user_${role}`, 1);
//               navigate("/");
//               return;
//             }
//           }
//         })
//         .catch((error) => {
//           toast.error("Invalid Username or Password", {
//             position: "bottom-left",
//             duration: 3000,
//           });
//         });
//     }
//   };

//   const handleTogglePassword = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   const handleDirectLogin = (role, email, password) => {
//     setEmail(email);
//     setPassword(password);
//     handleSubmit({ preventDefault: () => { } }); // Mock event
//   };

//   const handleGoBack = () => {
//     navigate("/"); // Navigate to home when the back button is clicked
//   };

//   return (
//     <>
//       <Toaster position="top-center" reverseOrder={false} />
//       <Wrapper>
//         <Brand>
//           <SvgLogo />
//         </Brand>
//         <LoginBox>
//           <StyledPaper elevation={6}>
//           <IconButton onClick={handleGoBack} sx={{ position: 'absolute', top: 16, left: 16, fontSize: '2rem', color: 'white' }}>
//           <ArrowBackIcon />
//         </IconButton>
//          <Typography component="h1" variant="h5">
//               Login
//             </Typography>
//             <StyledForm onSubmit={handleSubmit}>
//               <StyledTextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 error={!!emailError}
//                 helperText={emailError}
//               />
//               <StyledTextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type={showPassword ? "text" : "password"}
//                 id="password"
//                 autoComplete="current-password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 error={!!passwordError}
//                 helperText={passwordError}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton onClick={handleTogglePassword}>
//                         {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <StyledButton type="submit" fullWidth variant="contained" color="primary">
//                 Sign In
//               </StyledButton>
//             </StyledForm>

//             {userCredentials.map(({ role, email, password }) => (
//               <StyledButton
//                 key={role}
//                 variant="outlined"
//                 color="secondary"
//                 fullWidth
//                 onClick={() => handleDirectLogin(role, email, password)}
//               >
//                 Login as {role}
//               </StyledButton>
//             ))}
//           </StyledPaper>
//         </LoginBox>
//       </Wrapper>
//     </>
//   );
// }

// export default Login;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { TextField, Button, Container, IconButton, InputAdornment, Typography, Paper } from "@mui/material";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import axios from "axios";
// import { styled } from "@mui/system";
// import { useAuth } from "./AuthContext/AuthContext";
// import toast, { Toaster } from "react-hot-toast";

// // Add the styled components
// const Wrapper = styled('div')({
//   display: 'grid',
//   gridTemplateColumns: '1fr 1fr',
//   gridTemplateRows: 'auto',
//   gridTemplateAreas: '"centerLeft centerRight"',
//   minHeight: '100vh',
//   backgroundColor: '#2d2d2d',
// });

// const Brand = styled('div')({
//   gridArea: 'centerLeft',
//   backgroundColor: '#2d2d2d',
//   color: '#fff',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   fontSize: '2rem',
//   padding: '1rem',
//   animation: 'colorAnimate 30s infinite',
//   '& svg': {
//     width: '200px', // Set a size for the logo
//     height: 'auto',
//     fill: '#fff', // Set the color of the logo (white for now)
//     transition: 'all 0.3s ease-in-out', // Optional transition for hover effects
//   },
// });

// // A sample SVG logo (you can replace this with your actual SVG file or SVG component)
// const SvgLogo = () => (
//   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//     <circle cx="50" cy="50" r="40" fill="#ff6347" />
//     <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="16" fontWeight="bold" dy=".3em">
//       Digi Signage
//     </text>
//   </svg>
// );

// const LoginBox = styled('div')({
//   gridArea: 'centerRight',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   backgroundColor: '#fff',
 
// });

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(3),
//   width: '100%',
//   maxWidth: 400,
//   boxShadow: 'none',
// }));

// const StyledButton = styled(Button)({
//   margin: '16px 0 24px',
// });

// const StyledTextField = styled(TextField)({
//   marginBottom: '16px',
// });

// const StyledForm = styled('form')({
//   width: '100%',
// });

// const userCredentials = [
//   { role: "User", email: "user1@gmail.com", password: "12345678" },
// ];

// function Login() {
//   const navigate = useNavigate();
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [emailError, setEmailError] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const validateEmail = () => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!email) {
//       setEmailError("Please enter your email.");
//       return false;
//     } else if (!emailRegex.test(email)) {
//       setEmailError("Invalid email format.");
//       return false;
//     }
//     setEmailError("");
//     return true;
//   };

//   const validatePassword = () => {
//     if (!password) {
//       setPasswordError("Please enter your password.");
//       return false;
//     } else if (password.length < 8) {
//       setPasswordError("Password must be at least 8 characters long.");
//       return false;
//     }
//     setPasswordError("");
//     return true;
//   };

//   const { login } = useAuth();

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const isEmailValid = validateEmail();
//     const isPasswordValid = validatePassword();

//     if (isEmailValid && isPasswordValid) {
//       const data = { email, password };
//       axios
//         .post("https://digiflyer.in/cloudapi/api/v1/auth/login", data)
//         .then((res) => {
//           sessionStorage.setItem("userid", res.data.user.id);
//           sessionStorage.setItem("Token", res.data.access_token);

//           const roleMap = {
//             is_admin: "admin",
//             is_user: "user",
//           };

//           for (const [key, role] of Object.entries(roleMap)) {
//             if (res.data.user[key]) {
//               login(role);
//               sessionStorage.setItem(`user_${role}`, 1);
//               navigate("/");
//               return;
//             }
//           }
//         })
//         .catch((error) => {
//           toast.error("Invalid Username or Password", {
//             position: "bottom-left",
//             duration: 3000,
//           });
//         });
//     }
//   };

//   const handleTogglePassword = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };
//     const handleDirectLogin = (role, email, password) => {
//     setEmail(email);
//     setPassword(password);
//     handleSubmit({ preventDefault: () => { } }); // Mock event
//   };


//   return (
//     <>
//       <Toaster position="top-center" reverseOrder={false} />
//       <Wrapper>
//         <Brand>
//           <SvgLogo /> 
//                   </Brand>
//         <LoginBox>
          
//           <StyledPaper elevation={6}>
//             <Typography component="h1" variant="h5">
//               Login
//             </Typography>
//             <StyledForm onSubmit={handleSubmit}>
//               <StyledTextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 error={!!emailError}
//                 helperText={emailError}
//               />
//               <StyledTextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type={showPassword ? "text" : "password"}
//                 id="password"
//                 autoComplete="current-password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 error={!!passwordError}
//                 helperText={passwordError}
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">
//                       <IconButton onClick={handleTogglePassword}>
//                         {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
//                       </IconButton>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//               <StyledButton type="submit" fullWidth variant="contained" color="primary">
//                 Sign In
//               </StyledButton>
//             </StyledForm>

//             {userCredentials.map(({ role, email, password }) => (
//               <StyledButton
//                 key={role}
//                 variant="outlined"
//                 color="secondary"
//                 fullWidth
//                 onClick={() => handleDirectLogin(role, email, password)}
//               >
//                 Login as {role}
//               </StyledButton>
//             ))}
//           </StyledPaper>
//         </LoginBox>
//       </Wrapper>
//     </>
//   );
// }

// export default Login;

// import { React, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import TextField from "@mui/material/TextField";
// import Button from "@mui/material/Button";
// import Container from "@mui/material/Container";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";
// import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import toast, { Toaster } from "react-hot-toast";
// import { styled } from "@mui/system";
// import axios from "axios";
// import { useAuth } from "./AuthContext/AuthContext";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   marginTop: theme.spacing(8),
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   padding: theme.spacing(3),
// }));

// const StyledForm = styled("form")({
//   width: "100%",
//   marginTop: 1,
// });

// const StyledSubmitButton = styled(Button)({
//   margin: "16px 0 24px",
// });

// // All user credentials
// const userCredentials = [
//   { role: "User", email: "user1@gmail.com", password: "12345678" },
// ];

// function Login() {
//   const navigate = useNavigate();
//   const [email, setemail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [emailError, setEmailError] = useState("");
//   const [passwordError, setPasswordError] = useState("");

//   const validateEmail = () => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     if (!email) {
//       setEmailError("Please enter your email.");
//       return false;
//     } else if (!emailRegex.test(email)) {
//       setEmailError("Invalid email format.");
//       return false;
//     }
//     setEmailError("");
//     return true;
//   };

//   const validatePassword = () => {
//     if (!password) {
//       setPasswordError("Please enter your password.");
//       return false;
//     } else if (password.length < 8) {
//       setPasswordError("Password must be at least 8 characters long.");
//       return false;
//     }
//     setPasswordError("");
//     return true;
//   };

//   const { login } = useAuth();

//   const handleSubmit = (e) => {

    
//     e.preventDefault();

//     const isEmailValid = validateEmail();
//     const isPasswordValid = validatePassword();

//     if (isEmailValid && isPasswordValid) {
//       const data = { email, password };
    
//       axios
//         .post("https://digiflyer.in/cloudapi/api/v1/auth/login", data)
//         .then((res) => {
//           sessionStorage.setItem("userid", res.data.user.id);
//           sessionStorage.setItem("Token", res.data.access_token);
    
//           const roleMap = {
//             is_admin: "admin",
//             is_user: "user",
//           };
    
//           for (const [key, role] of Object.entries(roleMap)) {
//             if (res.data.user[key]) {
//               login(role);
//               sessionStorage.setItem(`user_${role}`, 1);
//               navigate("/");
//               return; 
//             }
//           }
//         })
//         .catch((error) => {
//           toast.error(`Invalid Username or Password`, {
//             position: "bottom-left",
//             duration: 3000,
//           });
//         });
//     }
    
//   };

//   const handleTogglePassword = () => {
//     setShowPassword((prevShowPassword) => !prevShowPassword);
//   };

//   const handleDirectLogin = (role, email, password) => {
//     setemail(email);
//     setPassword(password);
//     handleSubmit({ preventDefault: () => { } }); // Mock event
//   };

//   return (
//     <>
//       <Toaster position="top-center" reverseOrder={false} />
//       <StyledPaper>
//         <Typography variant="h4">Welcome to The Digital Signage Software Dashboard</Typography>
//       </StyledPaper>

//       <Container component="main" maxWidth="xs">
//         <StyledPaper elevation={6}>
//           <Typography component="h1" variant="h5">Login</Typography>
//           <StyledForm onSubmit={handleSubmit}>
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               id="email"
//               label="Email Address"
//               name="email"
//               autoComplete="email"
//               autoFocus
//               value={email}
//               onChange={(e) => setemail(e.target.value)}
//               error={!!emailError}
//               helperText={emailError}
//             />
//             <TextField
//               variant="outlined"
//               margin="normal"
//               required
//               fullWidth
//               name="password"
//               label="Password"
//               type={showPassword ? "text" : "password"}
//               id="password"
//               autoComplete="current-password"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               error={!!passwordError}
//               helperText={passwordError}
//               InputProps={{
//                 endAdornment: (
//                   <InputAdornment position="end">
//                     <IconButton onClick={handleTogglePassword}>
//                       {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
//                     </IconButton>
//                   </InputAdornment>
//                 ),
//               }}
//             />
//             <StyledSubmitButton type="submit" fullWidth variant="contained" color="primary">
//               Sign In
//             </StyledSubmitButton>
//           </StyledForm>

//           {/* Render direct login buttons */}
//           {userCredentials.map(({ role, email, password }) => (
//             <Button
//               key={role}
//               variant="outlined"
//               color="secondary"
//               fullWidth
//               onClick={() => handleDirectLogin(role, email, password)}
//             >
//               Login as {role}
//             </Button>
//           ))}
//         </StyledPaper>
//       </Container>
//     </>
//   );
// }

// export default Login;
