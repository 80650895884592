import axios from 'axios';

const axiosClient = axios.create({
  baseURL: 'https://digiflyer.in/cloudapi/api/v1/',  
});

axiosClient.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('Token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;  
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosClient;
