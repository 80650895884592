// src/pages/EmployeeDirectory.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const EmployeeDirectory = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Employee Directory</Typography>
      {/* Add employee directory logic here */}
      <Typography variant="body1">
        This page will list all employees in the organization.
      </Typography>
    </Box>
  );
};

export default EmployeeDirectory;
