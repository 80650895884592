import { Route, Routes } from "react-router-dom";
import DashBoard from "../usbuser/Pages/DashBoard";
import Screens from "../usbuser/Pages/Screens";

const UsbUserRoutes = () => (
  <Routes>
    <Route path="/" element={<DashBoard/>} />
    <Route path="/screens" element={<Screens/>} />
    
  </Routes>
);
export default UsbUserRoutes;
