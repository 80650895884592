import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, Divider, CircularProgress, Tabs, Tab } from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';

const fakeProductData = {
  variants: [
    {
      name: "24-inch Horizontal",
      products: [
        {
          price: 599,
          description: "24-inch Full HD digital signage display, ideal for horizontal placement.",
          imageUrl: "https://placehold.co/200x100.png?text=24+inch+Horizontal",
          stock: 20,
          size: "24-inch",
          orientation: "Horizontal",
        },
        {
          price: 650,
          description: "24-inch Full HD digital signage display with enhanced brightness for better visibility.",
          imageUrl: "https://placehold.co/200x100.png?text=24+inch+Horizontal+Enhanced",
          stock: 15,
          size: "24-inch",
          orientation: "Horizontal",
        }
      ]
    },
    {
      name: "24-inch Vertical",
      products: [
        {
          price: 629,
          description: "24-inch Full HD digital signage display, perfect for vertical installations.",
          imageUrl: "https://placehold.co/300x100.png?text=24+inch+Vertical",
          stock: 15,
          size: "24-inch",
          orientation: "Vertical",
        },
        {
          price: 680,
          description: "24-inch Full HD vertical signage display with a slim design for modern spaces.",
          imageUrl: "https://placehold.co/300x100.png?text=24+inch+Vertical+Slim",
          stock: 10,
          size: "24-inch",
          orientation: "Vertical",
        }
      ]
    },
    {
      name: "32-inch Horizontal",
      products: [
        {
          price: 799,
          description: "32-inch 4K digital signage display, designed for horizontal placement.",
          imageUrl: "https://placehold.co/400x300.png?text=32+inch+Horizontal",
          stock: 10,
          size: "32-inch",
          orientation: "Horizontal",
        },
        {
          price: 850,
          description: "32-inch 4K signage display with anti-glare technology, perfect for outdoor use.",
          imageUrl: "https://placehold.co/400x300.png?text=32+inch+Horizontal+Anti-Glare",
          stock: 8,
          size: "32-inch",
          orientation: "Horizontal",
        }
      ]
    },
    {
      name: "32-inch Vertical",
      products: [
        {
          price: 829,
          description: "32-inch 4K digital signage display, ideal for vertical installations.",
          imageUrl: "https://placehold.co/400x300.png?text=32+inch+Vertical",
          stock: 5,
          size: "32-inch",
          orientation: "Vertical",
        },
        {
          price: 880,
          description: "32-inch 4K vertical display with motion sensing, perfect for interactive installations.",
          imageUrl: "https://placehold.co/400x300.png?text=32+inch+Vertical+Motion",
          stock: 3,
          size: "32-inch",
          orientation: "Vertical",
        }
      ]
    }
  ],
};

const Shop = () => {
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState('24-inch Horizontal');

  useEffect(() => {
    setTimeout(() => {
      setProductDetails(fakeProductData); 
      setLoading(false);
    }, 2000); 
  }, []);

  const handleAddToCart = () => {
    alert('Added to cart!');
  };

  const handleVariantChange = (event, newValue) => {
    setSelectedVariant(newValue);
  };

  const selectedVariantDetails = productDetails?.variants.find(variant => variant.name === selectedVariant);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Digital Signage Product
      </Typography>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4} justifyContent="left">
          <Grid item xs={12} sm={3}>
            <Tabs
              orientation="vertical"
              value={selectedVariant}
              onChange={handleVariantChange}
              aria-label="product variants"
              sx={{ borderRight: 1, borderColor: 'divider' }}
            >
              {productDetails.variants.map((variant) => (
                <Tab key={variant.name} label={variant.name} value={variant.name} />
              ))}
            </Tabs>
          </Grid>

          <Grid item xs={12} sm={9}>
            <Grid container spacing={4}>
              {selectedVariantDetails?.products.map((product, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card
                    sx={{
                      boxShadow: '0px 0px 8px 4px rgba(0, 123, 255, 0.7)', 
                      borderRadius: '8px',
                      transition: 'box-shadow 0.3s ease-in-out', 
                      '&:hover': {
                        boxShadow: '0px 0px 16px 8px rgba(0, 123, 255, 1)', 
                      },
                    }}
                  >
                    <CardContent>
                      <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
                        <img src={product.imageUrl} alt="Product" width="100%" height="auto" />
                      </Box>
                      <Typography variant="h5" align="center" gutterBottom>
                        {product.size} {product.orientation} Display
                      </Typography>
                      <Typography variant="h6" color="primary" align="center" gutterBottom>
                        ₹ {product.price}
                      </Typography>
                      <Typography variant="body2" align="center" paragraph>
                        {product.description}
                      </Typography>

                      <Typography variant="body2" align="center" color="textSecondary" sx={{ mb: 2 }}>
                        {product.stock} items in stock
                      </Typography>

                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        startIcon={<AddShoppingCart />}
                        onClick={handleAddToCart}
                        disabled={product.stock === 0}
                      >
                        Add to Cart
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Divider sx={{ my: 4 }} />
      <Typography variant="body2" color="textSecondary" align="center">
        All products are backed by our quality guarantee.
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Rent and purchase both available, contact +91 88713-36877
      </Typography>
    </Box>
  );
};

export default Shop;
