import React from 'react';

function Home() {
  return (
    <section id="home">
      <div className="home-text">
        <h2>Revolutionize Your Business with Digital Signage</h2>
        <h1>Flexible Solutions for Every Need: <br /> USB & Cloud-Based Digital Signage</h1>
        <p>
          Manage and deliver dynamic content in real time. Choose from USB or cloud-based solutions
          to suit your business needs, whether you're a small shop or a large enterprise.
        </p>

        <div className="email-box">
          <input type="email" placeholder="Enter your email to get started..." autoComplete="off" />
          <a href="#"><i className='bx bxs-fast-forward-circle'></i></a>
        </div>
      </div>

      <div className="home-img">
        {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="250" height="250">
          <g>
            <rect x="10" y="20" width="80" height="60" rx="10" fill="#2D3A3A" />
            <rect x="20" y="30" width="60" height="40" rx="5" fill="#FFFFFF" />
            <line x1="20" y1="30" x2="80" y2="30" stroke="#FF6F61" strokeWidth="2" />
            <circle cx="50" cy="50" r="6" fill="#FF6F61" />
            <path d="M 50,50 Q 60,40 70,50 T 90,50" fill="none" stroke="#FF6F61" strokeWidth="2" />
            <path d="M 50,50 Q 40,60 30,50 T 10,50" fill="none" stroke="#FF6F61" strokeWidth="2" />
            <path d="M 50,50 L 50,30" stroke="#FF6F61" strokeWidth="2" />
            <path d="M 50,50 L 50,70" stroke="#FF6F61" strokeWidth="2" />
          </g>
        </svg> */}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
          <circle cx="50" cy="50" r="50" fill="#ff6347" />
        
          <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="15" fontWeight="bold" dy=".3em">
            Digi Signage
          </text>
          {/* <line x1="20" y1="70" x2="80" y2="70" stroke="#f5f5f5" strokeWidth="2" /> */}
           
        </svg>
      </div>

      <a href="#services" className="scroll-down">
        <i className='bx bx-mouse'></i>
        Scroll Down
        <i className='bx bx-down-arrow-alt'></i>
      </a>
    </section>
  );
}

export default Home;
