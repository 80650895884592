import {
  TextFields,
  Videocam,
  PlaylistAdd,
  RssFeed,
  CalendarMonth,
  LockClock,
  Slideshow,
  Web,
  WifiTetheringErrorTwoTone,
  MusicNote,
  Newspaper,
  Map,
  CheckBox,
  Poll,
  Chat,
  Cloud,
  Image,
  BrandingWatermark,
} from "@mui/icons-material";

export const WIDGET_TYPES = {
  image: {
    label: "Image",
    icon: <Image />,
    color: "#f44336",
    available: "yes",
  },
 video: {
    label: "Video",
    icon: <Videocam />,
    color: "#2196f3",
    available: "yes",
  },
  playlist: {
    label: "Playlist",
    icon: <PlaylistAdd />,
    color: "#dd9800",
    available: "yes",
  },
  // banner: {
  //   label: "Banner",
  //   icon: <BrandingWatermark />,
  //   color: "#ff9800",
  //   available: "no",
  // },
  text: {
    label: "Text",
    icon: <TextFields />,
    color: "#4caf50",
    available: "no",
  },
   rss: {
    label: "RSS Feed",
    icon: <RssFeed />,
    color: "#ff9800",
    available: "no",
  },
  calendar: {
    label: "Calendar",
    icon: <CalendarMonth />,
    color: "#ff9800",
    available: "no",
  },
  clock: {
    label: "Clock",
    icon: <LockClock />,
    color: "#ff9800",
    available: "no",
  },
  slideshow: {
    label: "Slideshow",
    icon: <Slideshow />,
    color: "#ff9800",
    available: "no",
  },
  web: { label: "Web", icon: <Web />, color: "#ff9800", available: "no" },
  weather: {
    label: "Weather",
    icon: <WifiTetheringErrorTwoTone />,
    color: "#03a9f4",
    available: "no",
  },
  news: {
    label: "News",
    icon: <Newspaper />,
    color: "#ff5722",
    available: "no",
  },
  audio: {
    label: "Audio",
    icon: <MusicNote />,
    color: "#9c27b0",
    available: "no",
  },
  map: { label: "Map", icon: <Map />, color: "#4caf50", available: "no" },
  todo: {
    label: "Todo List",
    icon: <CheckBox />,
    color: "#3f51b5",
    available: "no",
  },
  poll: { label: "Poll", icon: <Poll />, color: "#607d8b", available: "no" },
  chat: { label: "Chat", icon: <Chat />, color: "#8bc34a", available: "no" },
  weatherForecast: {
    label: "Weather Forecast",
    icon: <Cloud />,
    color: "#00bcd4",
    available: "no",
  },
};
