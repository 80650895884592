import React from 'react';
import { Typography } from '@mui/material';

const CornerText = ({ sectionContent }) => {
  const corners = [
    { position: 'absolute', top: 0, left: 0, textAlign: 'left' },
    { position: 'absolute', top: 0, right: 0, textAlign: 'right' },
    { position: 'absolute', bottom: 0, left: 0, textAlign: 'left' },
    { position: 'absolute', bottom: 0, right: 0, textAlign: 'right' },
  ];

  return (
    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
      {corners.map((corner) => (
        <Typography
          key={corner.position}
          variant="h6"
          sx={{
            position: 'absolute',
            ...corner,
            padding: '8px',
            fontWeight: 'bold',
            zIndex: 10,
          }}
        >
          {sectionContent}
        </Typography>
      ))}
    </div>
  );
};

export default CornerText;
