// 6x6
//  this layout belongs to advance layout settings

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import SnapshotGrid from "./SnapshotGrid";

const MyLayouts = ({ layouts, setcounter  }) => {
  const [previewDialog, setPreviewDialog] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  const handleTemplateSelect = (template) => {
    console.log(`Template selected: ${template.title}`);
  };

  const openPreviewDialog = (data) => {
    setPreviewDialog(true);
    setPreviewData(JSON.parse(data));
  };

  const closePreviewDialog = () => {
    setPreviewDialog(false);
  };

  const calculateWidgetSpan = (cells) => {
    const rows = cells.map((cell) => Math.floor(cell / 6)); // Updated for 6 columns
    const cols = cells.map((cell) => cell % 6); // Updated for 6 columns

    const rowSpan = Math.max(...rows) - Math.min(...rows) + 1;
    const colSpan = Math.max(...cols) - Math.min(...cols) + 1;

    return {
      rowSpan,
      colSpan,
      startRow: Math.min(...rows),
      startCol: Math.min(...cols),
    };
  };

  const renderColor = (widgetType) => {
    switch (widgetType) {
      case "image":
        return "#f9a19a";
      case "video":
        return "#90caf9";
      case "banner":
        return "#ffcb7f";
      case "text":
        return "#a5d7a7";
      default:
        return "#f8f8f8";
    }
  };

  const token = sessionStorage.getItem("Token");

  const deleteLayout = (id) => {
    axios
      .delete(`https://digiflyer.in/cloudapi/api/v1/user/dynamic_layouts/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        setcounter((prev) => prev + 1);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "1400px", margin: "auto", padding: 3 }}>
      <Typography variant="h4" sx={{ fontWeight: 600, textAlign: "center", mb: 2 }}>
        My Layouts
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ textAlign: "center", mb: 3 }}>
        Choose an existing layout below or create a new one to fit your display needs.
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {layouts.map((template) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={template.id}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                boxShadow: 3,
                borderRadius: 2,
                minHeight: 420,
                width: "100%",
                maxWidth: "100%",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                "&:hover": {
                  transform: "scale(1.03)",
                  boxShadow: 6,
                },
              }}
            >
              {/* Grid Layout Preview */}
              <Grid
                container
                spacing={0.5}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(6, 1fr)", // Updated to 6 columns
                  gap: 0.5,
                  width: "100%",
                  padding: 1,
                  mt: 2,
                }}
              >
                {JSON.parse(template.config).map((widget, index) => {
                  const { widgetType, cells } = widget;
                  const { rowSpan, colSpan, startRow, startCol } = calculateWidgetSpan(cells);

                  return (
                    <Box
                      key={index}
                      sx={{
                        gridRow: `${startRow + 1} / span ${rowSpan}`,
                        gridColumn: `${startCol + 1} / span ${colSpan}`,
                        minHeight: `${rowSpan * 35}px`,
                        minWidth: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "2px solid #ddd",
                        borderRadius: 1,
                        backgroundColor: renderColor(widgetType),
                        fontSize: "0.9rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {widgetType}
                    </Box>
                  );
                })}
              </Grid>

              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  flexGrow: 1,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {template.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                  {template.description}
                </Typography>

                <Box sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title="Delete" arrow>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{ borderRadius: 1 }}
                      onClick={() => deleteLayout(template.id)}
                    >
                      Delete
                    </Button>
                  </Tooltip>
                  {/* <Tooltip title="Apply Layout" arrow>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ borderRadius: 1 }}
                      onClick={() => handleTemplateSelect(template)}
                    >
                      Apply
                    </Button>
                  </Tooltip> */}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Preview Dialog */}
      <Dialog
        fullScreen
        open={previewDialog}
        onClose={closePreviewDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {previewData && <SnapshotGrid snapshotdata={previewData} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePreviewDialog}>Cancel</Button>
          <Button onClick={closePreviewDialog} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MyLayouts;
