import React from "react";
import {
  TextField,
  Box,
  Typography,
  Divider,
  Avatar,
  Paper,
} from "@mui/material";
import { WIDGET_TYPES } from "./constants";

const WidgetSelector = ({
  searchQuery,
  handleSearchChange,
  selectedWidget,
  setSelectedWidget,
}) => {
  const filteredWidgets = Object.entries(WIDGET_TYPES).filter(
    ([key, { label }]) =>
      label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Paper
      sx={{
        padding: 2,
        width: "20%",
        boxShadow: 3,
        maxHeight: "85vh",
        overflowY: "auto",
      }}
    >
      <Typography variant="h6" gutterBottom>
        Select Widget
      </Typography>
      <TextField
        label="Search Widgets"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{ marginBottom: 2 }}
      />
      <Divider sx={{ marginBottom: 2 }} />
      {filteredWidgets.map(([key, { label, icon, color, available }]) => (
        <Box
          key={key}
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 1,
            borderRadius: 1,
            cursor: available === "no" ? "not-allowed" : "pointer",
            backgroundColor:
              selectedWidget === key ? `${color}33` : "transparent",
            "&:hover": {
              backgroundColor:
                available === "no" ? "transparent" : `${color}20`,
            },
            marginBottom: 1,
            opacity: available === "no" ? 0.5 : 1,
          }}
          onClick={() => available === "yes" && setSelectedWidget(key)}
        >
          <Avatar
            sx={{
              backgroundColor: "transparent",
              marginRight: 1,
              color: color,
            }}
          >
            {icon}
          </Avatar>
          <Typography>{label}</Typography>
        </Box>
      ))}
    </Paper>
  );
};

export default WidgetSelector;
