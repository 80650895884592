import React, { useState } from "react";
import { Box, Typography, Drawer, IconButton, Tabs, Tab } from "@mui/material";
import {
  Visibility as PreviewIcon,
  ViewQuilt as Layout,
  Description as ContentIcon,
  Folder as FileManagerIcon,
  PlaylistPlay as PlaylistIcon,
  CalendarToday as ScheduleIcon,
  Analytics as AnalyticsIcon,
  Widgets as WidgetsIcon,
} from "@mui/icons-material";
import LayoutSettings from "./LayoutSettings";
import ContentSettings from "./ContentSettings";
import FileManager from "./FileManager";
import Playlist from "./Playlist";
import Schedule from "./Schedule";
import Analytics from "./Analytics";
import Widgets from "./Widgets";
import Preview from "./Preview";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import PreviewGrid from "./reusablecomponent/PreviewGrid";
import axios from "axios";
import PreviewGridHistory from "./reusablecomponent/PreviewGridHistory";

const ScreenDetail = () => {
  const { screenId } = useParams();

  const [tabValue, setTabValue] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePreviewToggle = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };
  const [layouts, setlayouts] = useState([]);
  const [layoutsHistory, setlayoutsHistory] = useState([]);
  const token = sessionStorage.getItem("Token");

  const getlayout = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://digiflyer.in/cloudapi/api/v1/user/dynamic_layouts",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setlayouts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  
  const getlayoutHistory = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://digiflyer.in/cloudapi/api/v1/user/getHistory",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log('history data : ', response.data.data);
        setlayoutsHistory(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
 
  useEffect(() => {
    getlayout()
    getlayoutHistory()
  }, [])
  
  return (
    <Box
      sx={{ display: "flex", width: "100%", fontFamily: "Poppins, sans-serif" }}
    >
      <Box
        sx={{
          width: 130,
          borderRight: 2,
          borderColor: "divider",
          // backgroundColor: '#fff',
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Tabs
          orientation="vertical"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Screen Settings"
          sx={{
            borderRight: 1,
            borderColor: "divider",
            ".MuiTab-root": {
              textTransform: "none",
              fontWeight: 500,
              fontSize: "14px",
              // color: '#333',
              letterSpacing: "0.5px",
              paddingY: 2,
              "&:hover": {
                backgroundColor: "#f0f0f0",
                color: "#FFC107",
              },
            },
            ".Mui-selected": {
              // backgroundColor: '#fff',
              // color: '#000',
              fontWeight: "600",
            },
          }}
        >
          <Tab
            icon={<ContentIcon />}
            label={<Typography variant="h6">Advanced Settings</Typography>}
          />
          <Tab
            icon={<ContentIcon />}
            label={<Typography variant="h6">History</Typography>}
          />
   </Tabs>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          padding: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {tabValue === 0 &&<PreviewGrid layouts={layouts} setlayouts={setlayouts}/>}
        {tabValue === 1 &&<PreviewGridHistory layouts={layoutsHistory} setlayouts={setlayoutsHistory}/>}
        </Box>

      <Box
        sx={{
          position: "fixed",
          top: 100,
          right: 20,
          zIndex: 1001,
          backgroundColor: "rgba(0, 123, 255, 0.8)",
          color: "white",
          padding: "8px 16px",
          borderRadius: "5px",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ marginRight: "8px", fontSize: "16px" }}>
          Preview Screen
        </Typography>
        <IconButton
          sx={{
            backgroundColor: "#007BFF",
            color: "white",
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "#0056b3",
            },
          }}
          onClick={handlePreviewToggle}
        >
          <PreviewIcon />
        </IconButton>
      </Box>

      <Drawer
        anchor="right"
        open={isPreviewOpen}
        onClose={handlePreviewToggle}
        sx={{
          width: "90%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "90%",
            height: "100%",
            padding: 3,
            backgroundColor: "#f5f5f5",
            borderLeft: 1,
            borderColor: "divider",
          },
        }}
      >
        <Typography
          variant="h6"
          sx={{ mb: 2, fontWeight: 600, fontSize: "24px" }}
        >
          Screen Preview
        </Typography>
        <Preview screenId={screenId} />
      </Drawer>
    </Box>
  );
};

export default ScreenDetail;
