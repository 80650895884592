import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { AccessTime, Cancel, CheckCircle, Star } from "@mui/icons-material";
import axios from "axios";

const Screens = () => {
  const [rows, setRows] = useState([]);


  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "pairing_code", headerName: "Pairing Code", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        return (
          <Chip
            label={
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                {params.value}
              </span>
            }
            color={params.value === "paired" ? "success" : "warning"}
          />
        );
      },
    },
  ];
  const [openDialog, setOpenDialog] = useState(false);

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);
  const getScreendata = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://digiflyer.in/cloudapi/api/v1/get-usb-screens",
      headers: {
        "Content-Type": "application/json",
        //   'Authorization': `Bearer ${}`
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.screens);
        setRows(response.data.screens);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getScreendata();
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {/* <Button
          onClick={handleDialogOpen}
          sx={{ mr: 1, mb: 2 }}
          variant="contained"
        >
          Purchase
        </Button> */}
      </div>
      <div style={{ height: 400, width: "100%", padding: "10px" }}>
        <DataGrid rows={rows} columns={columns} pageSize={5} />
      </div>

      {/* <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Choose a Subscription Plan</DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, p: 0.5 }}>
          {[1, 1, 1].map((item, i) => (
            <Card
              sx={{
                width: 800,
                height: 450,
                borderRadius: 2,
                boxShadow: 3,
                "&:hover": {
                  boxShadow: 6,
                  transform: "scale(1.01)",
                  transition: "0.3s ease-in-out",
                },
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "bold" }}
                >
                  Basic Plan
                </Typography>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  sx={{ textDecoration: "underline" }}
                >
                  $9.99/month
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ marginTop: 2 }}
                >
                  Ideal for individual use. Includes essential features to get
                  started.
                </Typography>

                <List sx={{ marginTop: 2 }}>
                  <ListItem>
                    <CheckCircle color="success" sx={{ marginRight: 1 }} />
                    <ListItemText primary="Basic Features" />
                  </ListItem>
                  <ListItem>
                    <Cancel color="error" sx={{ marginRight: 1 }} />
                    <ListItemText primary="Priority Support" />
                  </ListItem>
                  <ListItem>
                    <Cancel color="error" sx={{ marginRight: 1 }} />
                    <ListItemText primary="Analytics" />
                  </ListItem>
                </List>
              </CardContent>
        
            </Card>
          ))}
        </Box>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default Screens;
