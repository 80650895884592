// 6x6
import React, { useState, useEffect } from "react";
import {
  Box,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import WidgetSelector from "./WidgetSelector";
import GridComponent from "./GridComponent";
import SnapshotsList from "./SnapshotsList";
import { isRectangle } from "./utils";
import LayoutTemplates from "./LayoutTemplates";
import MyLayouts from "./MyLayouts";
import SnapshotGrid from "./SnapshotGrid";
import axios from "axios";

const WidgetGrid = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [cellWidgets, setCellWidgets] = useState(
    JSON.parse(localStorage.getItem("cellWidgets")) || Array(36).fill(null) // 36 cells for a 6x6 grid
  );
  const [selectedCells, setSelectedCells] = useState([]);
  const [selectedWidget, setSelectedWidget] = useState(
    localStorage.getItem("selectedWidget") || "image"
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snapshots, setSnapshots] = useState(
    JSON.parse(localStorage.getItem("snapshots")) || []
  );
  const [lockedCells, setLockedCells] = useState(
    JSON.parse(localStorage.getItem("lockedCells")) || []
  );
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [previewdailog, setPreviewDialog] = useState(false);
  const [name, setName] = useState("");

  const token = sessionStorage.getItem("Token");
  const [layouts, setLayouts] = useState([]);
  const [counter, setCounter] = useState(0);

  const totalNullWidgetCells = cellWidgets.filter((cell) => cell === null).length;

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    localStorage.setItem("cellWidgets", JSON.stringify(cellWidgets));
  }, [cellWidgets]);

  useEffect(() => {
    localStorage.setItem("snapshots", JSON.stringify(snapshots));
  }, [snapshots]);

  useEffect(() => {
    localStorage.setItem("lockedCells", JSON.stringify(lockedCells));
  }, [lockedCells]);

  useEffect(() => {
    localStorage.setItem("selectedWidget", selectedWidget);
  }, [selectedWidget]);

  useEffect(() => {
    console.log('totalNullWidgetCells = ', totalNullWidgetCells);
  }, [totalNullWidgetCells]);

  const handleMouseDown = (index) => {
    if (lockedCells.includes(index)) return;
    setIsDragging(true);
    setDragStartIndex(index);
    setSelectedCells([index]);
  };

  const handleMouseMove = (index) => {
    if (!isDragging || lockedCells.includes(index)) return;
    const minRow = Math.min(
      Math.floor(dragStartIndex / 6), // 6 columns
      Math.floor(index / 6)
    );
    const maxRow = Math.max(
      Math.floor(dragStartIndex / 6),
      Math.floor(index / 6)
    );
    const minCol = Math.min(dragStartIndex % 6, index % 6);
    const maxCol = Math.max(dragStartIndex % 6, index % 6);

    const newSelectedCells = [];
    for (let row = minRow; row <= maxRow; row++) {
      for (let col = minCol; col <= maxCol; col++) {
        const cellIndex = row * 6 + col; // 6 columns
        if (!lockedCells.includes(cellIndex)) {
          newSelectedCells.push(cellIndex);
        }
      }
    }

    setSelectedCells(newSelectedCells);
  };

  const handleMouseUp = () => {
    setIsDragging(false);

    if (!isRectangle(selectedCells)) {
      setSnackbarMessage("Selected cells do not form a valid rectangle.");
      setSnackbarOpen(true);
      setSelectedCells([]);
      return;
    }

    const newSnapshot = {
      id: Date.now(),
      widgetType: selectedWidget,
      cells: selectedCells,
    };

    setSnapshots([...snapshots, newSnapshot]);

    const newCellWidgets = [...cellWidgets];
    selectedCells.forEach((cellIndex) => {
      newCellWidgets[cellIndex] = selectedWidget;
    });
    setCellWidgets(newCellWidgets);

    setLockedCells([...lockedCells, ...selectedCells]);
    setSelectedCells([]);
  };

  const handleEditSnapshot = (id) => {
    const snapshot = snapshots.find((s) => s.id === id);
    if (snapshot) {
      setLockedCells(
        lockedCells.filter((cell) => !snapshot.cells.includes(cell))
      );
      const newCellWidgets = [...cellWidgets];
      snapshot.cells.forEach((cellIndex) => {
        newCellWidgets[cellIndex] = null;
      });
      setCellWidgets(newCellWidgets);
      setSelectedWidget(snapshot.widgetType);
      setSelectedCells(snapshot.cells);
      setSnapshots(snapshots.filter((s) => s.id !== id));
    }
  };

  const handleDeleteSnapshot = (id) => {
    const snapshotToDelete = snapshots.find((s) => s.id === id);
    if (snapshotToDelete) {
      const newCellWidgets = [...cellWidgets];
      snapshotToDelete.cells.forEach((cellIndex) => {
        newCellWidgets[cellIndex] = null;
      });
      setCellWidgets(newCellWidgets);
      setSnapshots(snapshots.filter((s) => s.id !== id));
      setLockedCells(
        lockedCells.filter((cell) => !snapshotToDelete.cells.includes(cell))
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleReset = () => {
    localStorage.removeItem("cellWidgets");
    localStorage.removeItem("snapshots");
    localStorage.removeItem("lockedCells");
    localStorage.removeItem("selectedWidget");

    setCellWidgets(Array(36).fill(null)); // 36 cells for a 6x6 grid
    setSelectedCells([]);
    setSelectedWidget("image");
    setSnapshots([]);
    setLockedCells([]);
    setSearchQuery("");
    setSnackbarMessage("All data has been reset.");
    setSnackbarOpen(true);
  };

  const openPreviewDialog = () => {
    setPreviewDialog(true);
  };

  const closePreviewDialog = () => {
    setPreviewDialog(false);
    setName("");
  };

  const submitPreviewData = () => {
    let data = {
      name: name,
      config: JSON.stringify(snapshots), // Snapshot data here
    };
    let config = {
      method: "post",
      url: "https://digiflyer.in/cloudapi/api/v1/user/dynamic_layouts",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        closePreviewDialog();
        handleReset();
        setName("");
      })
      .catch((error) => {
        console.log(error);
        closePreviewDialog();
      });
  };

  const getLayout = () => {
    let config = {
      method: "get",
      url: "https://digiflyer.in/cloudapi/api/v1/user/dynamic_layouts",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setLayouts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getLayout();
  }, [activeTab, counter]);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, padding: 3 }}>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Widget Tabs">
        <Tab label="My Layouts" />
        <Tab label="Create New Layout" />
        <Tab label="Layout Templates" />
      </Tabs>

      {activeTab === 0 && (
        <Box sx={{ display: "flex", gap: 1 }}>
          <MyLayouts key={layouts.length} layouts={layouts} setCounter={setCounter} />
        </Box>
      )}

      {activeTab === 1 && (
        <>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button variant="outlined" color="error" sx={{ marginTop: 2 }} onClick={handleReset}>
            Reset All Data
          </Button>
          <Button
            disabled={cellWidgets.filter(cell => cell !== null).length === 0}
            variant="contained"
            onClick={openPreviewDialog}
          >
            Preview
          </Button>
        </div>

          <Box sx={{ display: "flex", gap: 3, padding: 1 }}>
            <WidgetSelector
              searchQuery={searchQuery}
              handleSearchChange={handleSearchChange}
              selectedWidget={selectedWidget}
              setSelectedWidget={setSelectedWidget}
            />
            <GridComponent
              cellWidgets={cellWidgets}
              selectedCells={selectedCells}
              lockedCells={lockedCells}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
            />
            <SnapshotsList
              snapshots={snapshots}
              handleEditSnapshot={handleEditSnapshot}
              handleDeleteSnapshot={handleDeleteSnapshot}
            />
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
              <Alert severity="warning" onClose={handleCloseSnackbar}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </Box>
          <Dialog fullScreen open={previewdailog} onClose={closePreviewDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px" }}>
                  <TextField value={name} placeholder="Layout Name" label="Layout Name" onChange={handleNameChange} fullWidth />
                </div>
                <SnapshotGrid snapshotdata={snapshots} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closePreviewDialog} color="primary">Cancel</Button>
              <Button onClick={submitPreviewData} color="primary" autoFocus>Submit</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
      {activeTab === 2 && <LayoutTemplates />}
    </Box>
  );
};

export default WidgetGrid;
