import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, CardActionArea, CardActions, Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import PaidIcon from '@mui/icons-material/Paid';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentsIcon from '@mui/icons-material/Payments';
import CustomerGender from '../CustomerGender';
import PopularOrder from '../PopularOrder';

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  transition: '0.3s',
  '&:hover': {
    boxShadow: theme.shadows[8],
  },
}));

const CardMediaContainer = styled(CardMedia)(({ theme }) => ({
  height: 140,
  backgroundSize: 'contain',
  backgroundColor: theme.palette.grey[200],
}));

const Dashboard = () => {
  return (
    <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea>
              <CardMediaContainer
                image="https://via.placeholder.com/345x140"
                title="Total Revenue"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Total Revenue
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  $ 124,200
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                View Details
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea>
              <CardMediaContainer
                image="https://via.placeholder.com/345x140"
                title="Total Orders"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Total Orders
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  4,655
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                View Details
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea>
              <CardMediaContainer
                image="https://via.placeholder.com/345x140"
                title="Total Customers"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Total Customers
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  1,985
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                View Details
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StyledCard>
            <CardActionArea>
              <CardMediaContainer
                image="https://via.placeholder.com/345x140"
                title="Total Expenses"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Total Expenses
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  $ 18,650
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button size="small" color="primary">
                View Details
              </Button>
            </CardActions>
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomerGender />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Popular Orders
              </Typography>
              <Box>
                <PopularOrder title="Product X" date="2023-1-16" />
                <PopularOrder title="Product Y" date="2022-11-17" />
                <PopularOrder title="Product P" date="2023-3-10" />
                <PopularOrder title="Product V" date="2023-5-19" />
                <PopularOrder title="Product F" date="2023-5-23" />
                <PopularOrder title="Product O" date="2023-7-11" />
                <PopularOrder title="Product A" date="2023-8-1" />
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
