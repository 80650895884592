// SnapshotsList.js
import React from 'react';
import { Box, Typography, Divider, IconButton, Paper } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { WIDGET_TYPES } from './constants';

const SnapshotsList = ({ snapshots, handleEditSnapshot, handleDeleteSnapshot }) => {
  return (
    <Paper sx={{ padding: 2, width: '20%', boxShadow: 3, maxHeight: '85vh', overflowY: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Saved Snapshots
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      {snapshots.map(({ id, widgetType, cells }) => (
        <Box
          key={id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 1,
            borderRadius: 1,
            backgroundColor: '#f5f5f5',
            marginBottom: 1,
            '&:hover': {
              boxShadow: 1,
            },
          }}
        >
          <Typography>{WIDGET_TYPES[widgetType].label}</Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton onClick={() => handleEditSnapshot(id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDeleteSnapshot(id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
    </Paper>
  );
};

export default SnapshotsList;