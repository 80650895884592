import axios from 'axios';

const API_URL = 'https://digiflyer.in/cloudapi/api/v1/user'; 

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json', 
  },
});

const apiFormdataClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data', 
  },
});

apiClient.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('Token'); 
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;  
  }
  return config; 
}, (error) => {
  return Promise.reject(error);
});

apiFormdataClient.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('Token'); 
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;  
  }
  return config; 
}, (error) => {
  return Promise.reject(error);
});

export const postRequest = async (endpoint, data) => {
  try {
    const response = await apiClient.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error in POST request:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const postFormdataRequest = async (endpoint, formData) => {
  try {
    const response = await apiFormdataClient.post(endpoint, formData);
    return response.data;
  } catch (error) {
    console.error('Error in POST request:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const getRequest = async (endpoint) => {
  try {
    const response = await apiClient.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error in GET request:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const putRequest = async (endpoint, data) => {
  try {
    const response = await apiClient.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error('Error in PUT request:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};

export const deleteRequest = async (endpoint) => {
  try {
    const response = await apiClient.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error in DELETE request:', error.response ? error.response.data : error.message);
    throw error.response ? error.response.data : error.message;
  }
};
