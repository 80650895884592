import React from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const generateBreadcrumbs = () => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    // console.log(location.pathname.split("/"))
    const breadcrumbs = pathnames.map((value, index) => {
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;
      return {
        name: value.charAt(0).toUpperCase() + value.slice(1),
        link: to,
      };
    });
    return breadcrumbs;
  };

  const breadcrumbs = generateBreadcrumbs();

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "8px" }}>
      <Link
        color="inherit"
        onClick={() => navigate("/")}
        sx={{ cursor: "pointer" }}
      >
        Home
      </Link>
      {breadcrumbs.map((breadcrumb, index) => (
        <Link
          key={index}
          color="inherit"
          onClick={() => navigate(breadcrumb.link)}
          sx={{ cursor: "pointer" }}
        >
          {breadcrumb.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default BreadcrumbsComponent;
