import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  TextField,
  Skeleton,
  InputAdornment,
} from "@mui/material";
import { WIDGET_TYPES } from "./constants";
import SnapshotGrid from "./SnapshotGrid";
import axios from "axios";
import { Add, Attachment } from "@mui/icons-material";
import { getRequest, postRequest } from "../api";


const MyLayouts = () => {
  const handleTemplateSelect = (template) => {
    console.log(`Template selected: ${template.id}`);
  };
  const [previewdailog, setpreviewdailog] = useState(false);
  const [Previewdata, setpreviewdata] = useState("");
  const [layouts, setlayouts] = useState([]);
  const [counter, setcounter] = useState(0);
  const getlayout = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://digiflyer.in/cloudapi/api/v1/user/dynamic_layouts",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setlayouts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getlayout();
  }, [counter]);
  const openpreviewdailog = (data) => {
    setpreviewdailog(true);
    setpreviewdata(JSON.parse(data));
  };
  const closepreviewdailog = () => {
    setpreviewdailog(false);
  };
  const calculateWidgetSpan = (cells) => {
    const rows = cells.map((cell) => Math.floor(cell / 10));
    const cols = cells.map((cell) => cell % 10);

    const rowSpan = Math.max(...rows) - Math.min(...rows) + 1;
    const colSpan = Math.max(...cols) - Math.min(...cols) + 1;

    return {
      rowSpan,
      colSpan,
      startRow: Math.min(...rows),
      startCol: Math.min(...cols),
    };
  };
  const rendercolor = (widgetType) => {
    switch (widgetType) {
      case "image":
        return "#f9a19a";
      case "video":
        return "#90caf9";
      case "banner":
        return "#ffcb7f";
      case "text":
        return "#a5d7a7";
      default:
        return "#f8f8f8";
    }
  };
  const token = sessionStorage.getItem("Token");
  const deleteLayout = (id) => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://digiflyer.in/cloudapi/api/v1/user/dynamic_layouts/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setcounter(Math.floor(Math.random() * 10) + 1);
        // setlayouts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlemedia = (event, layoutId, widgetType) => {
    const file = event.target.value; // Get the uploaded file

    // Create a URL for the file (assuming the file is an image or video)
    const fileURL = URL.createObjectURL(file);

    // Find the layout to update based on its id
    const updatedLayouts = layouts.map((layout) => {
      if (layout.id === layoutId) {
        const config = JSON.parse(layout.config); // Parse the config array

        // Find the widget to update based on its type (video, image, or banner)
        const updatedConfig = config.map((widget) => {
          if (widget.widgetType === widgetType) {
            // Update the mediaURL for the relevant widget type (video, image, banner)
            return { ...widget, mediaURL: file };
          }
          return widget;
        });

        // Update the layout config with the new mediaURL
        return { ...layout, config: JSON.stringify(updatedConfig) };
      }
      //   console.log(layout);
      return layout;
    });

    setlayouts(updatedLayouts); // Update the state with the new layouts
  };

  const userId = sessionStorage.getItem("userid");

  const [openFileManager, setOpenFileManager] = useState(false);
  const [fileManagerFiles, setFileManagerFiles] = useState([]);
  const [mediatype, setmediatype] = useState("");
  const [medialoading, setmedialoading] = useState(false);

  const handleOpenFileManager = (type) => {
    setOpenFileManager(true);
    fetchFileManagerItems(type);
    setmediatype(type);
  };

  const fetchFileManagerItems = async (type) => {
    setmedialoading(true);
    try {
      const response = await getRequest(
        `/files?type=${type}&user_id=${userId}`
      );

      setFileManagerFiles(response.files);
      setmedialoading(false);
    } catch (error) {
      console.log(error);
      setmedialoading(false);
    }
  };

  const handleFileManagerClose = () => {
    setOpenFileManager(false);
    setmediatype("");
  };

  const [selectedLayoutId, setSelectedLayoutId] = useState(null);
  const [selectedWidgetType, setSelectedWidgetType] = useState(null);
  const [textInput, setTextInput] = useState(""); // Store input text (custom link)
  const [dialogOpen, setDialogOpen] = useState(false); // Dialog visibility

  const handleAddButtonClick = (layoutId, widgetType) => {
    setSelectedLayoutId(layoutId); // Set the selected layout
    setSelectedWidgetType(widgetType); // Set the selected widget type
    setDialogOpen(true); // Open the dialog to input the link
  };

  const handleTextInput = (event) => {
    setTextInput(event.target.value); // Update the input field state
  };

  const handleSaveTextInput = () => {
    const updatedLayouts = layouts.map((layout) => {
      if (layout.id === selectedLayoutId) {
        const config = JSON.parse(layout.config); // Parse the config

        const updatedConfig = config.map((widget) => {
          if (widget.widgetType === selectedWidgetType) {
            return { ...widget, mediaURL: textInput }; // Update the widget
          }
          return widget;
        });

        return { ...layout, config: JSON.stringify(updatedConfig) };
      }
      return layout;
    });

    setlayouts(updatedLayouts);
    setTextInput("");
    // Update layouts with new config
    setDialogOpen(false); // Close the dialog
  };
  const onselect = (url) => {
    setTextInput(url);
    handleFileManagerClose();
  };
  const dailogclose = () => {
    setSelectedLayoutId(null);
    setDialogOpen(false); // Close the dialog
  };
  const handleclear = () => {
    setSelectedLayoutId(null);
    setcounter(counter + 1);
  };
  const handleApplyLayout = async (id) => {
    let data = {
      // screen_code: screenId, // we have to update
      screen_code: 1,
      user_id: userId,
        layout_id: id,
      };
      console.log(data)
    try {
      const response = await postRequest("/screen-settings",data);
      //   setSnackbarMessage("Layout Applied successfully!");
      console.log(response);
      // }
      // fetchLayouts();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(layouts);
  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 3, padding: 3 }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          My Layouts
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ marginBottom: 2 }}
        >
          Choose an existing layout below or create a new one to fit your
          display needs.
        </Typography>

        {/* Template Grid */}
        <Grid container spacing={3}>
          {layouts.map((template) => (
            <Grid item xs={12} sm={6} md={4} key={template.id}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: 3,
                  borderRadius: 2,
                  minHeight: 400,
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: 6,
                  },
                }}
              >
                <Grid
                  container
                  spacing={0}
                  sx={{
                    display: "grid",
                    width: "100%",
                    p: 1,
                    mt: 3,
                  }}
                >
                  {JSON.parse(template.config).map((widget, index) => {
                    const { widgetType, cells } = widget;
                    const { rowSpan, colSpan, startRow, startCol } =
                      calculateWidgetSpan(cells);
                    // console.log(widgetType);
                    return (
                      <Grid
                        item
                        key={widget.id}
                        xs={false}
                        sx={{
                          gridRow: `${startRow + 0.2} / span ${rowSpan}`,
                          gridColumn: `${startCol + 1} / span ${colSpan}`,
                          minHeight: `${rowSpan * 30}px`,
                          minWidth: `${colSpan * 20}px`,
                          // height: 80,
                          // p: 1,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px solid #ddd",
                          borderRadius: 1,
                          backgroundColor: rendercolor(widgetType),
                        }}
                      >
                        {widgetType == "video" && (
                          <>
                            {widget.mediaURL ? (
                              <div style={{ height: "150px", width: "100%" }}>
                                <video
                                  width={"100%"}
                                  height={"100%"}
                                  autoPlay
                                  controls
                                >
                                  <source
                                    src={widget.mediaURL}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                            ) : (
                              <IconButton
                                onClick={() =>
                                  handleAddButtonClick(
                                    template.id,
                                    widget.widgetType
                                  )
                                }
                              >
                                <Add />
                              </IconButton>
                            )}
                          </>
                        )}
                        {widgetType == "image" && (
                          <>
                            {widget.mediaURL ? (
                              <img
                                width={"100%"}
                                height={"100%"}
                                src={widget.mediaURL}
                              />
                            ) : (
                              <IconButton
                                onClick={() =>
                                  handleAddButtonClick(
                                    template.id,
                                    widget.widgetType
                                  )
                                }
                              >
                                <Add />
                              </IconButton>
                            )}
                          </>
                        )}
                        {widgetType == "banner" && (
                          <IconButton
                            onClick={() =>
                              handleAddButtonClick(
                                template.id,
                                widget.widgetType
                              )
                            }
                          >
                            <Add />
                          </IconButton>
                        )}
                        {widgetType == "text" && (
                          <IconButton
                            onClick={() =>
                              handleAddButtonClick(
                                template.id,
                                widget.widgetType
                              )
                            }
                          >
                            <Add />
                          </IconButton>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {template.name}
                    </Typography>
                    {selectedLayoutId == template.id && (
                      <Button onClick={() => handleclear()}>Clear</Button>
                    )}
                  </div>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ marginBottom: 2 }}
                  >
                    {template.description}
                  </Typography>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Tooltip title="Delete" arrow>
                      <Button
                        variant="text"
                        color="error"
                        fullWidth
                        sx={{ borderRadius: 1 }}
                        onClick={() => deleteLayout(template.id)}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                    <Tooltip title="Apply Layout" arrow>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ borderRadius: 1 }}
                        onClick={() => handleTemplateSelect(template)}
                      >
                        Apply
                      </Button>
                    </Tooltip>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Dialog
          fullScreen
          open={previewdailog}
          onClose={closepreviewdailog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Preview"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <SnapshotGrid snapshotdata={Previewdata} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closepreviewdailog}>Cancel</Button>
            <Button onClick={closepreviewdailog} autoFocus>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Dialog open={openFileManager} onClose={handleFileManagerClose}>
        <DialogTitle>Select File</DialogTitle>
        <DialogContent>
          {medialoading ? (
            <Skeleton variant="rectangular" width={200} height={100} />
          ) : (
            <Grid container spacing={2}>
              {fileManagerFiles.map((file) => (
                <Grid item xs={4} key={file.id}>
                  <Box
                    sx={{
                      padding: 2,
                      border: "1px solid #ddd",
                      borderRadius: 2,
                    }}
                  >
                    {mediatype == "image" ? (
                      <div style={{ height: "200px", width: "100%" }}>
                        <img
                          src={file.url}
                          alt={file.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "fill",
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ height: "100px", width: "100%" }}>
                        <video width={"100%"} height={"100%"} autoPlay controls>
                          <source src={file.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}

                    <Button
                      onClick={() => onselect(file.url)}
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Select
                    </Button>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileManagerClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} onClose={dailogclose}>
        <DialogTitle>Enter Custom Link</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <TextField
            label="Enter Custom Link"
            variant="outlined"
            fullWidth
            value={textInput}
            onChange={handleTextInput} // Handle text input change
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Attachment">
                    <IconButton
                      onClick={() => handleOpenFileManager(selectedWidgetType)}
                      edge="end"
                    >
                      <Attachment />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        {/* <Button onClick={() => handleOpenFileManager(selectedWidgetType)}>
          Open File manager
        </Button> */}
        <DialogActions>
          <Button onClick={dailogclose}>Cancel</Button>
          <Button onClick={handleSaveTextInput}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyLayouts;
