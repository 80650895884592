import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function UsbUserDetails() {
  const { id } = useParams();
  const [view, setview] = useState(false);
  const token = sessionStorage.getItem("Token");
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "pairing_code", headerName: "Pairing Code", width: 120 },
    { field: "plan", headerName: "Plan (in Hours)", width: 250 },
    { field: "status", headerName: "status", width: 250 },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 150,
    //   renderCell: (params) => (
    //     <Link
    //       to={`/usbusers/${params.row.id}`}
    //       style={{ textDecoration: "none" }}
    //     >
    //       View Details
    //     </Link>
    //   ),
    // },
  ];
  const togglebutton = () => {
    setview(!view);
  };
  const [value, setvalue] = useState(0);
  const handlechange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setvalue(inputValue);
    }
  };
  const handlesubmit = () => {
    if (value > 0) {
      let data = {
        usb_screens: value,
        user_id: id,
        plan: plan,
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://digiflyer.in/cloudapi/api/v1/admin/add-usb-screens",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          togglebutton();
          setvalue(0);
          getallscreen();

          // setrows(response.data.users);
          // setloading(false)
        })
        .catch((error) => {
          console.log(error);
          // setloading(false)
        });
    } else {
      alert("please enter Number");
    }
  };
  const [allscreen, setallscreen] = useState([]);
  const getallscreen = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://digiflyer.in/cloudapi/api/v1/admin/getAllScreensUser/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setallscreen(response.data.screens);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getallscreen();
  }, []);
  const [plan, setplan] = useState(3);
  const handleplan = (e) => {
    setplan(e.target.value);
  };
  return (
    <>
      {/* <div>UsbUserDetails of {id}</div> */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={togglebutton}>{view ? "Close" : "Add Screen"}</Button>
      </div>
      {view && (
        <div
          style={{
            width: "100%",
            padding: "0px 20px  20px",
            // transition: "all 1 ease",
          }}
        >
          <Typography sx={{ mb: 1 }} variant="h5" fontWeight={600}>
            Add Screen
          </Typography>
          <TextField
            fullWidth
            onChange={handlechange}
            value={value}
            type="number"
            placeholder="Number Of Screen"
            label="Number Of Screen"
            inputProps={{
              inputMode: "numeric", // Hint for mobile devices
              pattern: "[0-9]*", // Allow only numbers
            }}
          />

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Additional Field</InputLabel>
            <Select
              label="Additional Field"
              name="additional_field"
              value={plan}
              onChange={handleplan}
            >
              <MenuItem value={1}>1 Month</MenuItem>
              <MenuItem value={3}>3 Months</MenuItem>
              <MenuItem value={6}>6 Months</MenuItem>
              <MenuItem value={12}>1 Year</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
          >
            <Button variant="contained" onClick={handlesubmit}>
              Save
            </Button>
          </div>
        </div>
      )}
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          columns={columns}
          rows={allscreen}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </div>
    </>
  );
}

export default UsbUserDetails;
