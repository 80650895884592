// src/Hr/section/HrLayout.js
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext } from "../../theme";
import Header from "./Header";
import Sidebar from "./Sidebar";
import HrRoutes from "../../Routes/hrRoutes";
const HrLayout = ({ mode, setMode }) => {
  return (
    <ColorModeContext.Provider value={setMode}>
      <ThemeProvider theme={mode}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Header />
            <HrRoutes />
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default HrLayout;
