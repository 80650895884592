import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Widgets() {
  const widgetList = [
    {
      name: 'Clock',
      url: 'https://digiflyer.in/cloudapi/api/v1/clock-widget',
      settings: {
        timeFormat: '24-hour',  
        showSeconds: true,      
        theme: 'default',       
      },
    },
    {
      name: 'Weather',
      url: 'https://digiflyer.in/cloudapi/api/v1/weather-widget',
      settings: {
        location: 'London',     
        unit: 'metric',         
        display: ['temperature', 'humidity', 'wind'], 
      },
    },
    {
      name: 'Banner',
      url: 'https://digiflyer.in/cloudapi/api/v1/banner-widget',
      settings: {
        bannerText: 'Welcome to our website!',  
        backgroundColor: '#4CAF50',            
        textColor: '#ffffff',                  
        animation: 'scroll',                    
      },
    },
    {
      name: 'Sand Clock',
      url: 'https://digiflyer.in/cloudapi/api/v1/sandclock-widget',
      settings: {
        textContent: 'This is a customizable text widget.',
        fontSize: '1.5em',     
        textColor: '#333',     
        backgroundColor: '#f4f4f4', 
      },
    },
    {
      name: 'Text',
      url: 'https://digiflyer.in/cloudapi/api/v1/text-widget',
      settings: {
        textContent: 'This is a customizable text widget.',
        fontSize: '1.5em',     
        textColor: '#333',     
        backgroundColor: '#f4f4f4', 
      },
    },
    {
      name: 'Congratulations',
      url: 'https://digiflyer.in/cloudapi/api/v1/congratulations-widget',
      settings: {
        message: 'Congratulations on your achievement!',
        fontSize: '2em',      
        backgroundColor: '#ffeb3b', 
        textColor: '#fff',     
      },
    },
    {
      name: 'News',
      url: 'https://digiflyer.in/cloudapi/api/v1/news-widget',
      settings: {
        newsSource: 'BBC',        
        displayCount: 5,          
        category: 'Technology',   
      },
    },
    {
      name: 'Calendar',
      url: 'https://digiflyer.in/cloudapi/api/v1/calendar-widget',
      settings: {
        showEvents: true,         
        theme: 'light',           
        startOfWeek: 'Monday',    
      },
    },
  
    
    {
      name: 'Countdown',
      url: 'https://digiflyer.in/cloudapi/api/v1/countdown-widget',
      settings: {
        targetDate: '2025-12-31T23:59:59', 
        countdownStyle: 'days',  
        showMilliseconds: false, 
      },
    },
    {
      name: 'Weather Forecast',
      url: 'https://digiflyer.in/cloudapi/api/v1/weather-forecast-widget',
      settings: {
        location: 'London',
        forecastDays: 7,         
        showIcons: true,         
      },
    },
    {
      name: 'Quote of the Day',
      url: 'https://digiflyer.in/cloudapi/api/v1/quote-widget',
      settings: {
        quoteSource: 'Motivational', 
        fontSize: '1.2em',            
      },
    },
    {
      name: 'Recent Posts',
      url: 'https://digiflyer.in/cloudapi/api/v1/recent-posts-widget',
      settings: {
        postCount: 5,            
        showAuthor: true,        
        showDate: true,          
      },
    },
    {
      name: 'To-Do List',
      url: 'https://digiflyer.in/cloudapi/api/v1/todo-list-widget',
      settings: {
        tasks: ['Buy groceries', 'Complete project'], 
        allowTaskCreation: true, 
        showCompletedTasks: true, 
      },
    },
    {
      name: 'Poll',
      url: 'https://digiflyer.in/cloudapi/api/v1/poll-widget',
      settings: {
        question: 'What is your favorite color?',
        options: ['Red', 'Blue', 'Green', 'Yellow'],
        allowMultipleVotes: false,  
      },
    },
  
    
    {
      name: 'Stock Market',
      url: 'https://digiflyer.in/cloudapi/api/v1/stock-market-widget',
      settings: {
        stockSymbol: 'AAPL',       
        refreshInterval: 60000,    
        showPriceChange: true,     
      },
    },
    {
      name: 'Social Media Feed',
      url: 'https://digiflyer.in/cloudapi/api/v1/social-media-widget',
      settings: {
        platform: 'Twitter',       
        showPostsCount: 5,         
        showImages: true,          
      },
    },
    {
      name: 'Chatbox',
      url: 'https://digiflyer.in/cloudapi/api/v1/chatbox-widget',
      settings: {
        welcomeMessage: 'Hello, how can I assist you today?', 
        showAvatar: true,         
        supportAgentName: 'Agent Smith',  
      },
    },
    {
      name: 'Task Progress',
      url: 'https://digiflyer.in/cloudapi/api/v1/task-progress-widget',
      settings: {
        taskName: 'Project XYZ',
        progress: 50,              
        theme: 'blue',             
      },
    },
    {
      name: 'Image Gallery',
      url: 'https://digiflyer.in/cloudapi/api/v1/image-gallery-widget',
      settings: {
        imageUrls: [
          'http://example.com/image1.jpg',
          'http://example.com/image2.jpg',
        ], 
        displayStyle: 'grid',       
        imageSize: 'medium',        
      },
    },
    {
      name: 'Form Submission',
      url: 'https://digiflyer.in/cloudapi/api/v1/form-widget',
      settings: {
        formFields: [
          { name: 'Name', type: 'text', placeholder: 'Enter your name' },
          { name: 'Email', type: 'email', placeholder: 'Enter your email' },
          { name: 'Message', type: 'textarea', placeholder: 'Enter your message' },
        ],
        submitButtonText: 'Send Message',
      },
    },
  ];
  
  const [selectedWidget, setSelectedWidget] = useState(widgetList[0].url); 
  const [widgetHtml, setWidgetHtml] = useState('');
  const [error, setError] = useState(null);

  
  useEffect(() => {
    axios.get(selectedWidget)
      .then(response => {
        setWidgetHtml(response.data);
        setError(null); 
      })
      .catch(err => {
        setWidgetHtml('');
        setError('Could not fetch the widget.');
      });
  }, [selectedWidget]);
// console.log(widgetHtml)
  return (
    <div style={styles.container}>
      <div style={styles.tabsContainer}>
        <h2>Widgets</h2>
        <ul style={styles.tabs}>
          {widgetList.map((widget, index) => (
            <li
              key={index}
              style={selectedWidget === widget.url ? styles.activeTab : styles.tab}
              onClick={() => setSelectedWidget(widget.url)}
            >
              {widget.name}
            </li>
          ))}
        </ul>
      </div>
      <div style={styles.widgetContainer}>
        <h3>Widget Preview</h3>
        {error && <p>{error}</p>}
        <div dangerouslySetInnerHTML={{ __html: widgetHtml }} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    padding: '20px',
    height: '100vh',
  },
  tabsContainer: {
    width: '200px',
    marginRight: '20px',
    borderRight: '2px solid #ccc',
  },
  tabs: {
    listStyle: 'none',
    padding: 0,
  },
  tab: {
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: '1px solid #ddd',
  },
  activeTab: {
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    borderBottom: '1px solid #007bff',
  },
  widgetContainer: {
    flex: 1,
    padding: '20px',
    borderLeft: '2px solid #ccc',
  },
};

export default Widgets;
