import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid Email Address"
    )
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
  user_type: Yup.string().required("User Type is required"),
});

const AddUser = () => {
  const token = sessionStorage.getItem("Token");

  // console.log(plan)
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      mobile: "",
      user_type: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = {
        fullname: values.name,
        email: values.email,
        password: values.password,
        mobile: values.mobile,
        user_type: values.user_type,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://digiflyer.in/cloudapi/api/v1/admin/addUser",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          // resetForm();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return (
    <Box sx={{ maxWidth: 500, margin: "auto", mt: 3, padding: 2 }}>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          sx={{ marginBottom: 2 }}
        />
        <TextField
          fullWidth
          label="Mobile"
          name="mobile"
          type="number"
          value={formik.values.mobile}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.mobile && Boolean(formik.errors.mobile)}
          helperText={formik.touched.mobile && formik.errors.mobile}
          sx={{ marginBottom: 2 }}
        />
        <FormControl
          fullWidth
          error={formik.touched.user_type && Boolean(formik.errors.user_type)}
          sx={{ marginBottom: 2 }}
        >
          <InputLabel>User Type</InputLabel>
          <Select
            label="User Type"
            name="user_type"
            value={formik.values.user_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <MenuItem value={1}>UsbUser</MenuItem>
            <MenuItem value={0}>CloudUser</MenuItem>
          </Select>
          {formik.touched.user_type && formik.errors.user_type && (
            <FormHelperText>{formik.errors.user_type}</FormHelperText>
          )}
        </FormControl>
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default AddUser;
