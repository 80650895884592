// LayoutTemplates.js
import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardMedia } from '@mui/material';
import { WIDGET_TYPES } from './constants'; 

const templates = 
[
    {
      "id": 1,
      "title": "Template 1 - Simple Layout",
      "image": "https://placehold.co/150",
      "description": "A basic layout with an image and text.",
      "cells": [
        { "x": 0, "y": 0, "width": 2, "height": 1, "widget": "image" },
        { "x": 0, "y": 1, "width": 2, "height": 1, "widget": "text" }
      ]
    },
    {
      "id": 2,
      "title": "Template 2 - Video & Text",
      "image": "https://placehold.co/150",
      "description": "A layout with video on top and text below.",
      "cells": [
        { "x": 0, "y": 0, "width": 2, "height": 1, "widget": "video" },
        { "x": 0, "y": 1, "width": 2, "height": 1, "widget": "text" }
      ]
    },
    {
      "id": 3,
      "title": "Template 3 - News & Weather",
      "image": "https://placehold.co/150",
      "description": "News widget on top, followed by a weather forecast below.",
      "cells": [
        { "x": 0, "y": 0, "width": 1, "height": 1, "widget": "news" },
        { "x": 1, "y": 0, "width": 1, "height": 1, "widget": "weather" }
      ]
    },
    {
      "id": 4,
      "title": "Template 4 - Calendar & Slideshow",
      "image": "https://placehold.co/150",
      "description": "A calendar at the top and a slideshow of images below.",
      "cells": [
        { "x": 0, "y": 0, "width": 2, "height": 1, "widget": "calendar" },
        { "x": 0, "y": 1, "width": 2, "height": 1, "widget": "slideshow" }
      ]
    },
    {
      "id": 5,
      "title": "Template 5 - Clock & Todo List",
      "image": "https://placehold.co/150",
      "description": "A digital clock with a todo list below it.",
      "cells": [
        { "x": 0, "y": 0, "width": 1, "height": 1, "widget": "clock" },
        { "x": 1, "y": 0, "width": 1, "height": 1, "widget": "todo" }
      ]
    },
    {
      "id": 6,
      "title": "Template 6 - Chat & Poll",
      "image": "https://placehold.co/150",
      "description": "A chat widget with a poll below.",
      "cells": [
        { "x": 0, "y": 0, "width": 1, "height": 1, "widget": "chat" },
        { "x": 1, "y": 0, "width": 1, "height": 1, "widget": "poll" }
      ]
    },
    {
      "id": 7,
      "title": "Template 7 - Playlist & Audio",
      "image": "https://placehold.co/150",
      "description": "A playlist widget on top and an audio player below.",
      "cells": [
        { "x": 0, "y": 0, "width": 2, "height": 1, "widget": "playlist" },
        { "x": 0, "y": 1, "width": 2, "height": 1, "widget": "audio" }
      ]
    },
    {
      "id": 8,
      "title": "Template 8 - Banner & Map",
      "image": "https://placehold.co/150",
      "description": "A banner at the top and a map below.",
      "cells": [
        { "x": 0, "y": 0, "width": 2, "height": 1, "widget": "banner" },
        { "x": 0, "y": 1, "width": 2, "height": 1, "widget": "map" }
      ]
    }
  ]
  ;

const LayoutTemplates = () => {
  const handleTemplateSelect = (template) => {
    console.log(`Template selected: ${template.title}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Typography variant="h6">Layout Templates</Typography>
      <Grid container spacing={3}>
        {templates.map((template) => (
          <Grid item xs={6} sm={3} key={template.id}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                alt={template.title}
                height="140"
                image={template.image}
                title={template.title}
              />
              <CardContent>
                <Typography variant="subtitle1" component="div">
                  {template.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {template.description}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: 1 }}
                  onClick={() => handleTemplateSelect(template)}
                >
                  Select Template
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LayoutTemplates;
