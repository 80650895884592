// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { BrowserRouter } from 'react-router-dom';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <BrowserRouter>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </BrowserRouter>
// );


import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "./AuthContext/AuthContext";
import "./index.css";
import { WebSocketProvider } from "./WebSocketContext";
ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
    <WebSocketProvider> 
      <CssBaseline />
      <App />
      </WebSocketProvider>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
