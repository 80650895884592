import { Box, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function UsbUsers() {
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "fullname", headerName: "Name", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 120 },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Link
          to={`/usbusers/${params.row.id}`}
          style={{ textDecoration: "none",color:'inherit' }}
        >
          View Details
        </Link>
      ),
    },
  ];
  // const rows = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     age: 28,
  //     email: "john.doe@example.com",
  //     userType: "Admin",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     age: 34,
  //     email: "jane.smith@example.com",
  //     userType: "User",
  //   },
  //   {
  //     id: 3,
  //     name: "Sam Wilson",
  //     age: 45,
  //     email: "sam.wilson@example.com",
  //     userType: "Moderator",
  //   },
  //   {
  //     id: 4,
  //     name: "Sara Connor",
  //     age: 29,
  //     email: "sara.connor@example.com",
  //     userType: "User",
  //   },
  //   {
  //     id: 5,
  //     name: "Peter Parker",
  //     age: 22,
  //     email: "peter.parker@example.com",
  //     userType: "Admin",
  //   },
  // ];
  const [rows, setrows] = useState([]);
  const token = sessionStorage.getItem("Token");
  const [loading, setloading] = useState(false);
  const getUsbUsers = () => {
    setloading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://digiflyer.in/cloudapi/api/v1/admin/getUsbUsers",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.users);
        setrows(response.data.users);
        setloading(false);
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  };
  useEffect(() => {
    getUsbUsers();
  }, []);

  return (
    <div>
      <Box sx={{ height: 400, width: "100%", p: 1 }}>
        <Typography variant="h6" gutterBottom>
          Custom DataGrid Example
        </Typography>
        <DataGrid
          loading={loading}
          rows={rows}
          columns={columns}
          pageSize={5} // Set the number of rows per page
          rowsPerPageOptions={[5]} // Allow 5 rows per page
          disableSelectionOnClick // Disable row selection on click
        />
      </Box>
    </div>
  );
}

export default UsbUsers;
