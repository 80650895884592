// // GridComponent.js
// GridComponent.js
import React from 'react';
import { Grid, Box, Avatar, Paper, Typography, Divider } from '@mui/material';
import { WIDGET_TYPES } from './constants';

const GridComponent = ({
  cellWidgets,
  selectedCells,
  lockedCells,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
}) => {
  return (
    <Paper sx={{ padding: 2, flex: 1, boxShadow: 3 }}>
      <Typography variant="h6" gutterBottom>
        Display
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Grid container spacing={1}>
        {/* Create a 6x6 grid (12-column system, so each item takes 2 columns) */}
        {Array.from({ length: 36 }).map((_, index) => (
          <Grid item xs={2} key={index}> {/* 6 columns, each takes 2 out of 12 (12/6 = 2) */}
            <Box
              sx={{
                border: '1px solid #ccc',
                padding: 1,
                height: 70, // Adjusted height for better visibility
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 2,
                alignItems: 'center',
                backgroundColor: cellWidgets[index] ? WIDGET_TYPES[cellWidgets[index]].color : 'white',
                cursor: lockedCells.includes(index) ? 'not-allowed' : 'pointer',
                opacity: lockedCells.includes(index) ? 0.5 : 1,
                border: selectedCells.includes(index) ? '2px solid #1976d2' : '1px solid #ccc',
                '&:hover': {
                  boxShadow: 3,
                  color: '#1976d2',
                },
              }}
              onMouseDown={() => handleMouseDown(index)}
              onMouseEnter={() => handleMouseMove(index)}
              onMouseUp={handleMouseUp}
            >
              {/* Display widget icon if there is a widget for this cell */}
              {cellWidgets[index] && (
                <Avatar sx={{ backgroundColor: 'transparent' }}>
                  {WIDGET_TYPES[cellWidgets[index]].icon}
                </Avatar>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ marginBottom: 2 }} />

      <Typography variant="h6" gutterBottom>
          Note : You can add banner later, when publishing to the screen.
      </Typography>
    </Paper>
  );
};

export default GridComponent;
