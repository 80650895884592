import axiosClient from './axiosClient';

export const fetchScreens = (id) => {
  return axiosClient.get(`/screens/${id}`);
};

export const createScreen = (screenData) => {
  return axiosClient.post('/screens/store', screenData);
};

export const updateScreen = (id, screenData) => {
  return axiosClient.put(`/screens/${id}`, screenData);
};

export const deleteScreen = (id) => {
  return axiosClient.delete(`/screens/${id}`);
};
