import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import {
  fetchScreens,
  createScreen,
  updateScreen,
  deleteScreen,
} from "./screenApi";
import { useWebSocketContext } from "../../../../WebSocketContext";
import axios from "axios";
const Screens = () => {
  const [screenId, setScreenId] = useState("");
  const [screenName, setScreenName] = useState("");
  const [screens, setScreens] = useState([]);
  const [screenCount, setScreenCount] = useState(0);
  const [open, setOpen] = useState(false);

  const [selectedScreen, setSelectedScreen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message1, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userid");
  const { isWebSocketConnected, sendMessage, message } = useWebSocketContext();
  const [cityloading, setcityLoading] = useState(false);
  const [states, setstates] = useState([]);
  const [selectedstate, setselectedstate] = useState("");
  const [selectedcity, setselectedcity] = useState("");
  const [city, setcity] = useState([]);
  const token = sessionStorage.getItem("Token");

  const [status, setStatus] = useState(null); // To store the screen's status

  const getstates = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://digiflyer.in/cloudapi/api/v1/user/getStates",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setstates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  useEffect(() => {
    getstates();
  }, []);

  useEffect(() => {
    console.log('recevied message ', message)
    setStatus(message?.message?.status)
  }, [message]);

  const getcity = () => {
    setcityLoading(true);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://digiflyer.in/cloudapi/api/v1/user/getCities/${selectedstate}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        setcity(response.data);
        setcityLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setcityLoading(false);
      });
  };
  useEffect(() => {
    getcity();
  }, [selectedstate]);

  const handlestateChange = (event) => {
    setselectedstate(event.target.value);
  };
  const handlecityChange = (event) => {
    setselectedcity(event.target.value);
  };
  useEffect(() => {
    if (userId) {
      setLoading(true);
      fetchScreens(userId)
        .then((response) => {
          setScreens(response.data.data);
          setScreenCount(response.data.count);
          setLoading(false);

          console.log(screens.length, screenCount)
        })
        .catch((error) => {
          console.error("Error fetching screens:", error);
          setLoading(false);
          setMessage("Error fetching screens");
          setSeverity("error");
        });
    }
  }, [userId]);

  const handleScreenIdChange = (e) => setScreenId(e.target.value);
  const handleScreenNameChange = (e) => setScreenName(e.target.value);

  const handleAddScreen = () => {
    if (screenId && screenName && userId) {
      const newScreen = {
        user_id: userId,
        code: screenId,
        name: screenName,
        state: selectedstate,
        city: selectedcity,
      };

      setLoading(true);
      createScreen(newScreen)
        .then((response) => {
          const pairingCode = screenId;

          // if (ws && isWebSocketConnected) {
          if (isWebSocketConnected && pairingCode) {
            const messageToSend = {
              to: pairingCode,
              command: "message",
              from: userId,
              message: {
                command: "pairingStatus",
                message: "activated",
                pairingCode: pairingCode,
              },
            };
            sendMessage(messageToSend);
          }

          setScreenId("");
          setScreenName("");
          setMessage("Screen added successfully");
          setSeverity("success");

          setLoading(true);
          fetchScreens(userId)
            .then((response) => {
              setScreens(response.data);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error fetching screens:", error);
              setLoading(false);
              setMessage("Error fetching screens");
              setSeverity("error");
            });
        })
        .catch((error) => {
          console.error("Error creating screen:", error);
          setLoading(false);
          setMessage("Error adding screen");
          setSeverity("error");
        });
    } else {
      alert(
        "Please enter both Screen ID and Screen Name, and ensure the user is logged in"
      );
    }
  };

  const handleEditScreen = (screen) => {
    setSelectedScreen(screen);
    setScreenName(screen.name);
    setOpenModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedScreen) {
      const updatedScreen = { ...selectedScreen, name: screenName };

      setLoading(true);
      updateScreen(selectedScreen.id, updatedScreen)
        .then(() => {
          setScreens(
            screens.map((screen) =>
              screen.id === selectedScreen.id ? updatedScreen : screen
            )
          );
          setSelectedScreen(null);
          setScreenName("");
          setOpenModal(false);
          setMessage("Screen updated successfully");
          setSeverity("success");
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error updating screen:", error);
          setLoading(false);
          setMessage("Error updating screen");
          setSeverity("error");
        });
    }
  };


  const getScreenStatus = async (screenCode) => {
    if (isWebSocketConnected) {
      const messageToSend = {
        to: screenCode,
        command: "message",
        from: userId,
        message: {
          command: "status",
          to: screenCode,
          from: userId
        },
      };
      console.log('sending data for getting status ', messageToSend)

      sendMessage(messageToSend);
    }

  };
  const handleGetStatus = async (pairingCode) => {
    try {
      const screenStatus = await getScreenStatus(pairingCode);
      setStatus(screenStatus); // Update state with the fetched status
      setOpen(true); // Open modal after fetching status
    } catch (error) {
      setStatus('Error fetching status');
      setOpen(true);
    }
  };

  const columns = [
    { field: "sno", headerName: "S.No.", width: 90 },
    { field: "name", headerName: "Name", width: 180 },
    { field: "city_name", headerName: "City", width: 180 },
    { field: "state_name", headerName: "State", width: 180 },

    { field: "status", headerName: "Status", width: 160 },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={() => handleEditScreen(params.row)}
            color="primary"
          >
            <EditIcon />
          </IconButton>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigate(`/screens/${params.row.screen_code}`)}
          >
            Go to Screen Detail
          </Button>
          <Button
            variant="contained"
            color="primary"

            onClick={async () => {
              handleGetStatus(params.row.pairing_code);
              // alert(`Status of Screen: ${status}`);
            }}
          >
            Get Status
          </Button>
        </Box>
      ),
    },

  ];


  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h6" gutterBottom>
        Add a New Screen
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Screen ID"
            value={screenId}
            onChange={handleScreenIdChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Screen Name"
            value={screenName}
            onChange={handleScreenNameChange}
            fullWidth
            required
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">State</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedstate}
              label="States"
              onChange={handlestateChange}
            >
              {states.map((item, i) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {cityloading ? (
            <CircularProgress />
          ) : (
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedcity}
                label="City"
                disabled={!selectedstate}
                onChange={handlecityChange}
              >
                {city.map((item, i) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.city}
                  </MenuItem>
                ))}

              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>



      <Button
        variant="contained"
        color="primary"
        onClick={handleAddScreen}
        fullWidth
        sx={{ mt: 2 }}
        disabled={screens.length >= screenCount}  // Disable if loading or if screens.length exceeds screenCount
      >
        {loading ? <CircularProgress size={24} /> : "Add Screen"}
      </Button>

      <Typography variant="h6" sx={{ mt: 3 }}>
        Added Screens:
      </Typography>

      <div style={{ height: 500, width: "100%", marginTop: 10 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <DataGrid
            rows={screens}
            columns={columns}
            pageSize={10}
            getRowId={(row) => row.sno}
            components={{
              Toolbar: GridToolbar,
            }}
            density="compact"
          />
        )}
      </div>

      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Edit Screen (ID: {selectedScreen?.id})</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Screen Name"
              value={screenName}
              onChange={handleScreenNameChange}
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <DialogActions>
              <Button onClick={() => setOpenModal(false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      {/* Modal/Dialog to show status */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Screen Status</DialogTitle>
        <DialogContent>
          <Typography>{status ? `Status: ${status}` : 'Fetching...'}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">Close</Button>
        </DialogActions>
      </Dialog>     
      
       <Snackbar
        open={message1 !== ""}
        autoHideDuration={6000}
        onClose={() => setMessage("")}
      >
        <Alert
          onClose={() => setMessage("")}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message1}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Screens;
