import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Tabs,
  Tab,
  Button,
  Modal,
  Skeleton,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Pagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import { useDropzone } from "react-dropzone";
import { postFormdataRequest, getRequest, deleteRequest } from "./api";

const FileManager = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 8;

  const userId = sessionStorage.getItem("userid");

  useEffect(() => {
    fetchFiles();
  }, [selectedTab]);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await getRequest(
        `/files?type=${selectedTab === 0 ? "image" : "video"}&user_id=${userId}`
      );
      setFiles(response.files);
    } catch (error) {
      console.error("Error fetching files:", error);
      setSnackbarMessage("Error fetching files!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFileDelete = async (fileId) => {
    try {
      await deleteRequest(`/files/${fileId}`);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
      setSnackbarMessage("File deleted successfully!");
      setSnackbarSeverity("info");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting file:", error);
      setSnackbarMessage("Error deleting file!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(1); // Reset pagination on tab change
  };

  const handleAddFile = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Validate file type based on selected tab
    if (
      (selectedTab === 0 && !file.type.startsWith("image")) ||
      (selectedTab === 1 && !file.type.startsWith("video"))
    ) {
      setSnackbarMessage(`Please select a ${selectedTab === 0 ? "image" : "video"} file.`);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userId);

    try {
      setLoading(true);
      const response = await postFormdataRequest("/files/upload", formData);
      console.log("File uploaded successfully:", response);
      setSnackbarMessage("File uploaded successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      fetchFiles();
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackbarMessage("Error uploading file!");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        // Validate file type based on selected tab
        if (
          (selectedTab === 0 && !file.type.startsWith("image")) ||
          (selectedTab === 1 && !file.type.startsWith("video"))
        ) {
          setSnackbarMessage(`Please select a ${selectedTab === 0 ? "image" : "video"} file.`);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }
        handleAddFile({ target: { files: [file] } });
      }
    },
    accept: selectedTab === 0 ? "image/*" : "video/*",
  });

  const handleFilePreview = (file) => {
    setCurrentFile(file);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setCurrentFile(null);
  };

  const handleDeleteClick = (fileId) => {
    setFileToDelete(fileId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (fileToDelete) {
      await handleFileDelete(fileToDelete);
      setDeleteDialogOpen(false);
      setFileToDelete(null);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedFiles = files.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  // Helper function to format the date
// const formatDate = (dateString) => {
//   const date = new Date(dateString);
//   return date.toLocaleString(); // Adjust options as needed
// };
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
};
  const images = files.filter((file) => file.type.startsWith("image"));
  const videos = files.filter((file) => file.type.startsWith("video"));

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        File Manager
      </Typography>

      <Tabs value={selectedTab} onChange={handleTabChange} aria-label="file categories">
        <Tab label="Images" />
        <Tab label="Videos" />
      </Tabs>

      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #ccc",
          borderRadius: 2,
          padding: 2,
          textAlign: "center",
          marginTop: 2,
          marginBottom: 2,
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="body1">
          Drag and drop {selectedTab === 0 ? "an image" : "a video"} here, or click to select
        </Typography>
      </Box>

      {loading ? (
        <Grid container spacing={2}>
          {[...Array(itemsPerPage)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Skeleton variant="rectangular" width="100%" height={150} />
              <Skeleton variant="text" width="80%" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {paginatedFiles.length > 0 ? (
            paginatedFiles.map((file) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    padding: 2,
                    borderRadius: 1,
                    position: "relative",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: 3,
                    },
                  }}
                >
                  {file.type.startsWith("image") ? (
                    <img
                      src={file.url}
                      alt={file.name}
                      style={{ width: "100%", height: "150px", objectFit: "cover" }}
                    />
                  ) : (
                    <video
                      src={file.url}
                      style={{ width: "100%", height: "150px", objectFit: "cover" }}
                      controls
                    />
                  )}
                  <Typography variant="body2" sx={{ marginTop: 1, textAlign: "center" }}>
                    {file.name}
                  </Typography>
                  <Typography variant="caption" sx={{ textAlign: "center" }}>
                    {`${(file.size / 1024).toFixed(2)} KB`}
                  </Typography>
                  <Typography variant="caption" sx={{ textAlign: "center" }}>
                    {/* {new Date(file.uploadedAt).toLocaleDateString()} */}
                    {formatDate(file.created_at)}
                    
                  </Typography>

                  <IconButton
                    onClick={() => handleFilePreview(file)}
                    sx={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>

                  <IconButton
                    onClick={() => handleDeleteClick(file.id)}
                    sx={{ position: "absolute", bottom: 5, right: 5, zIndex: 1 }}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              No files available.
            </Typography>
          )}
        </Grid>
      )}

      {files.length > itemsPerPage && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={Math.ceil(files.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: 2,
              boxShadow: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "500px",
              maxWidth: "90%",
              maxHeight: "90vh",
              overflowY: "auto",
              position: "relative",
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: 10, right: 10, zIndex: 1 }}
            >
              <CloseIcon color="error" />
            </IconButton>

            {currentFile?.type.startsWith("image") ? (
              <img
                src={currentFile?.url}
                alt={currentFile?.name}
                style={{ width: "100%", height: "auto", maxHeight: "400px", objectFit: "contain" }}
              />
            ) : (
              <video
                src={currentFile?.url}
                style={{ width: "100%", height: "auto", maxHeight: "400px", objectFit: "contain" }}
                controls
              />
            )}

            <Typography variant="h6" sx={{ marginTop: 2 }}>
              {currentFile?.name}
            </Typography>
            <Box sx={{ marginTop: 2 }}>
              <IconButton
                onClick={() => handleDeleteClick(currentFile?.id)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this file?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default FileManager;
