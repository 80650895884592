import React, { useState } from "react";
import { CssBaseline, ThemeProvider, Box } from "@mui/material";
// import { ColorModeContext } from ".../theme";
import UsbUserSidebar from "./UsbUserSidebar";
import UsbUserHeader from "./UsbUserHeader";
// import UserHeader from "./Header";
// import UserSidebar from "./Sidebar";
// import UserRoutes from "../../Routes/userRoutes";
import BreadcrumbsComponent from "./BreadcrumbsComponent";
import UsbUserRoutes from "../Routes/usbuserRoutes";
import { ColorModeContext } from "../theme";

const UsbUserLayout = ({ mode, setMode }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // console.log(isSidebarCollapsed);

  return (
    <ColorModeContext.Provider value={setMode}>
      <ThemeProvider theme={mode}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            transition: "all 0.3s ease",
          }}
        >
          <UsbUserSidebar
            isCollapsed={isSidebarCollapsed}
            setIsCollapsed={setIsSidebarCollapsed}
          />

          <Box
            className="content"
            sx={{
              flexGrow: 1,
              marginLeft: isSidebarCollapsed ? "65px" : "260px",
              transition: "margin-left 0.3s ease",
              overflowY: "auto",
              height: "100vh",
            }}
          >
            <UsbUserHeader />
            <br />
            <BreadcrumbsComponent />

            <UsbUserRoutes />
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default UsbUserLayout;
