import React, { useContext, useState, useEffect, useRef } from "react";
import {
  IconButton,
  Box,
  useTheme,
  InputBase,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  styled,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme"; 
import { useNavigate } from "react-router-dom"; 
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import TelegramIcon from "@mui/icons-material/Telegram";
import ModeNightIcon from "@mui/icons-material/ModeNight";
import LightModeIcon from "@mui/icons-material/LightMode";
import SearchIcon from "@mui/icons-material/Search";


const StyledTypography = styled(Typography)({
  fontFamily: "Poppins, sans-serif",  
  fontSize: "1.5rem",                  
  fontWeight: "bold",
  background: "linear-gradient(to right,rgb(239, 236, 242),rgb(123, 132, 123))",  
  WebkitBackgroundClip: "text",  
  color: "transparent", 
  textShadow: "4px 4px 8px rgba(0, 0, 0, 0.6)", 
  paddingTop: "5px",
  transition: "all 0.3s ease",  
  "&:hover": {
    transform: "scale(1.1)", 
    textShadow: "4px 4px 12px rgba(0, 0, 0, 0.8)", 
  },
});

const Header = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode); 
  const colorMode = useContext(ColorModeContext); 
  const navigate = useNavigate(); 

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [anchorSearch, setAnchorSearch] = useState(null); 

  const searchTimeout = useRef(null); 

  const routes = [
    { name: "Screens", path: "/screens" },
    { name: "Dashboard", path: "/dashboard" },
    { name: "Analytics", path: "/analytics" },
    { name: "Settings", path: "/settings" },
  ];

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    clearTimeout(searchTimeout.current);

    searchTimeout.current = setTimeout(() => {
      const filteredResults = routes.filter((route) =>
        route.name.toLowerCase().includes(query.toLowerCase())
      );
      setSearchResults(filteredResults); 
    }, 300); 
  };

  const handleSearchSelect = (route) => {
    navigate(route.path); 
    setSearchQuery(""); 
    setSearchResults([]); 
    setAnchorSearch(null); 
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.clear(); 
    navigate("/login"); 
  };

  
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingX: "10px",
        paddingTop: "5px",
        background: `linear-gradient(135deg, ${colors.primary[500]} 0%, ${colors.indigo[400]} 100%)`, 
        boxShadow: `0px 0px 15px 5px rgba(0, 123, 255, 0.7)`, 
        borderRadius: "8px",
        transition: "box-shadow 0.3s ease-in-out",
        '&:hover': {
          boxShadow: '0px 0px 25px 8px rgba(0, 123, 255, 1)', 
        },
        position: 'sticky', 
        top: 0, 
        zIndex: 1000, 
      }}
    >

      <Box>
        <Typography variant="h3" paddingTop="5px" color={colors.textColor[600]}>
          Hi User!👋
        </Typography>
      </Box>
      {/* <Box>
        <Typography variant="h2" paddingTop="5px" color={colors.textColor[600]}>
          Digi Signage
        </Typography>
      </Box> */}
      
      <Box >
      <StyledTypography variant="h2">
        Digi Signage
      </StyledTypography>
    </Box>
      <Box display="flex" alignItems="center">
        <Box
          width="80%"
          m={1}
          p={1}
          borderRadius="5px"
          display="flex"
          alignItems="center"
          sx={{ backgroundColor: colors.primary[400] }} 
        >
          <InputBase
            value={searchQuery}
            onChange={handleSearchChange}
            size="20px"
            placeholder="Search here..."
            onClick={(e) => setAnchorSearch(e.currentTarget)} 
          />
          <IconButton>
            <SearchIcon />
          </IconButton>
        </Box>
        <IconButton>
          <TelegramIcon />
        </IconButton>
        <IconButton>
          <NotificationsActiveIcon />
        </IconButton>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? <ModeNightIcon /> : <LightModeIcon />}
        </IconButton>
        <Avatar
          sx={{ marginLeft: "10px" }}
          onClick={handleClick}
          alt="User Avatar"
          src="https://www.svgrepo.com/show/388246/people-safe.svg"
        />
      </Box>

      <Popover
        open={Boolean(anchorSearch)}
        anchorEl={anchorSearch}
        onClose={() => setAnchorSearch(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ maxHeight: 300, overflow: "auto" }}
      >
        <List>
          {searchResults.map((route) => (

            <ListItem button onClick={() => handleSearchSelect(route)} key={route.path}>
              <ListItemText primary={route.name} />
            </ListItem>
          ))}
          {searchResults.length > 0 && (
            <ListItem>
              {/* <ListItemText primary="No results found" /> */}
              <ListItemText primary=" " />
            </ListItem>
          )}
        </List>
      </Popover>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: theme.palette.mode === "dark" ? colors.primary[500] : colors.primary[100], 
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar src="https://www.svgrepo.com/show/388246/people-safe.svg" /> Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Header;
