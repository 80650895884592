import React, { useState } from 'react';
import { Box, Typography, Slider, Grid, Card, CardContent, Button, Tooltip } from '@mui/material';
import { PlayCircleFilled, GridView, AccountBalance } from '@mui/icons-material';

const Pricing = () => {
  const [numScreens, setNumScreens] = useState(1);

  const getPricePerScreen = (screens) => {
    if (screens >= 800) return 100;   
    if (screens >= 500) return 150;   
    if (screens >= 300) return 200;    
    if (screens >= 200) return 300;    
    if (screens >= 100) return 350;    
    return 500;  
  };

  const calculateTotalPrice = (screens) => {
    const pricePerScreen = getPricePerScreen(screens);
    return pricePerScreen * screens;
  };

  const handleSliderChange = (event, newValue) => {
    setNumScreens(newValue);
  };

  
  const priceMarks = [
    { value: 1, label: '₹500' },
    { value: 100, label: '₹350' },
    { value: 200, label: '₹300' },
    { value: 300, label: '₹200' },
    { value: 500, label: '₹150' },
    { value: 800, label: '₹100' },
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Choose Your Plan
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <Typography variant="h6" gutterBottom>
            Number of Screens: {numScreens}
          </Typography>
          <Slider
            value={numScreens}
            onChange={handleSliderChange}
            min={1}
            max={1000}
            step={1}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => `${value} Screens`}
            marks={priceMarks} 
            sx={{
              mb: 4,
              '& .MuiSlider-rail': {
                backgroundColor: '#ddd',
              },
              '& .MuiSlider-track': {
                backgroundColor: '#1976d2',
              },
              '& .MuiSlider-thumb': {
                backgroundColor: '#1976d2',
              },
            }}
          />
          <Card
            variant="outlined"
            sx={{
              boxShadow: 3,
              borderRadius: 3, 
              padding: 2,
              borderColor: '#1976d2',
              backgroundColor: '#f5f5f5', 
            }}
          >
            <CardContent>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                Pricing Plan
              </Typography>
              <Typography variant="h6" sx={{ my: 2 }}>
                {numScreens} screens at ₹{getPricePerScreen(numScreens)} per screen
              </Typography>

              <Box sx={{ mb: 2 }}>
                <Tooltip title="Best for high-scale deployments" arrow>
                  <Typography variant="body2" color="textSecondary">
                    <GridView sx={{ fontSize: 20, mr: 1, verticalAlign: 'middle' }} /> Scalable for large teams
                  </Typography>
                </Tooltip>
                <Tooltip title="Perfect for media-heavy apps" arrow>
                  <Typography variant="body2" color="textSecondary" sx={{ my: 1 }}>
                    <PlayCircleFilled sx={{ fontSize: 20, mr: 1, verticalAlign: 'middle' }} /> Ideal for content-heavy displays
                  </Typography>
                </Tooltip>
                <Tooltip title="Flexible for businesses" arrow>
                  <Typography variant="body2" color="textSecondary">
                    <AccountBalance sx={{ fontSize: 20, mr: 1, verticalAlign: 'middle' }} /> Suitable for enterprises
                  </Typography>
                </Tooltip>
              </Box>

              <Button
                variant="contained"
                fullWidth
                sx={{
                  borderRadius: 2, 
                  backgroundColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                  },
                }}
              >
                Choose Plan
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pricing;
